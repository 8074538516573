import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { LINK_WIDTH, VIRTUAL_LINK_HEIGHT, VIRTUAL_LINK_LENGTH, VIRTUAL_LINK_SOURCE_OFFSET, VIRTUAL_LINK_Y } from '../common/constants';
import { TBaseSymbol } from '../Mesh';
import { ExtensionBatchedVirtualLinkDrawProgress } from '../utils/materials/extensions/ExtensionBatchedVirtualLinkDrawProgress';

export class LinkVirtualShape extends BaseObject{

    interactionLayer = 1;

    private updateCount = 0;

    constructor(){
        super();

        this.three.castShadow = false;
    }

    override getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionBatchedVirtualLinkDrawProgress],
            {
				drawProgress: 0,
                color: 0xffffff,
				transparent: true,
				alphaToCoverage: true, // This is super important so transparent geometries don't clip the ones behind even when they are fully transparent
				side: THREE.DoubleSide
            },
            { debug: false }
        );

		return material;
    }

    updateGeometry(source: TBaseSymbol, target: TBaseSymbol){
		// Define the start and end points
		const pointSource = new THREE.Vector3(0, 0, 0);
	
		// Calculate the direction vector from source to target
		const directionVector = target.three.position.clone().sub(source.three.position);
	
		// Normalize the direction vector
		directionVector.normalize();
	
		// Offset the start point slightly in the direction of the target
		pointSource.add(directionVector.clone().multiplyScalar(VIRTUAL_LINK_SOURCE_OFFSET));
	
		// Scale the direction vector to the fixed length
		const pointTarget = directionVector.multiplyScalar(VIRTUAL_LINK_LENGTH);
		pointTarget.y = pointTarget.y + VIRTUAL_LINK_Y;
	
		// Calculate a midpoint for the Y direction
		const midY = Math.min(pointSource.y, pointTarget.y) + (Math.abs(pointSource.y - pointTarget.y) / 2);
	
		// Set a control point height adjustment (this can be adjusted based on desired curvature)
		const controlHeight = Math.abs(pointSource.y - pointTarget.y) / 2;
	
		// Ensure the control points are positioned to create a consistent curve shape
		const controlPoint1 = new THREE.Vector3(
			pointSource.x + (pointTarget.x - pointSource.x) / 4,
			midY + controlHeight,
			pointSource.z + (pointTarget.z - pointSource.z) / 4
		);
	
		const controlPoint2 = new THREE.Vector3(
			pointTarget.x - (pointTarget.x - pointSource.x) / 4,
			midY + controlHeight,
			pointTarget.z - (pointTarget.z - pointSource.z) / 4
		);
	
		// Create a Bezier curve
		const curve = new THREE.CubicBezierCurve3(
			pointSource,
			controlPoint1,
			controlPoint2,
			pointTarget
		);
	
		// Generate points along the curve
		const points = curve.getPoints(20);
	
		// Define the width and height of the plane
		const maxPlaneWidth = LINK_WIDTH;
		const maxPlaneHeight = VIRTUAL_LINK_HEIGHT; // Add this for the thickness in the Y axis
	
		// Arrays to hold the geometry data
		const vertices = [];
		const indices = [];
		const uvs = [];
	
		// Generate vertices and UVs
		for (let i = 0; i < points.length; i++) {
			const point = points[i];
			const direction = (i < points.length - 1) 
				? new THREE.Vector3().subVectors(points[i + 1], point).normalize()
				: new THREE.Vector3().subVectors(point, points[i - 1]).normalize();
	
			const normal = new THREE.Vector3().crossVectors(direction, new THREE.Vector3(0, 1, 0)).normalize();
			const binormal = new THREE.Vector3().crossVectors(normal, direction).normalize();
	
			// Calculate the width scaling factor
			const scale = 1 - (i / (points.length - 1)); // Scale from 1 to 0
	
			// Adjust the width and height based on the scale
			const planeWidth = maxPlaneWidth * scale;
			const planeHeight = maxPlaneHeight * scale;
	
			const left = new THREE.Vector3().addVectors(point, normal.clone().multiplyScalar(-planeWidth / 2));
			const right = new THREE.Vector3().addVectors(point, normal.clone().multiplyScalar(planeWidth / 2));
			const topLeft = new THREE.Vector3().addVectors(left, binormal.clone().multiplyScalar(planeHeight / 2));
			const bottomLeft = new THREE.Vector3().addVectors(left, binormal.clone().multiplyScalar(-planeHeight / 2));
			const topRight = new THREE.Vector3().addVectors(right, binormal.clone().multiplyScalar(planeHeight / 2));
			const bottomRight = new THREE.Vector3().addVectors(right, binormal.clone().multiplyScalar(-planeHeight / 2));
	
			vertices.push(topLeft.x, topLeft.y, topLeft.z);
			vertices.push(bottomLeft.x, bottomLeft.y, bottomLeft.z);
			vertices.push(topRight.x, topRight.y, topRight.z);
			vertices.push(bottomRight.x, bottomRight.y, bottomRight.z);
	
			const uvY = i / (points.length - 1);
			uvs.push(0, uvY);
			uvs.push(0, uvY);
			uvs.push(1, uvY);
			uvs.push(1, uvY);
	
			if (i < points.length - 1) {
				const baseIndex = i * 4; // Adjust base index for four vertices per point
				indices.push(baseIndex, baseIndex + 1, baseIndex + 4);
				indices.push(baseIndex + 1, baseIndex + 5, baseIndex + 4);
				indices.push(baseIndex + 2, baseIndex + 3, baseIndex + 6);
				indices.push(baseIndex + 3, baseIndex + 7, baseIndex + 6);
				indices.push(baseIndex, baseIndex + 2, baseIndex + 4);
				indices.push(baseIndex + 2, baseIndex + 6, baseIndex + 4);
				indices.push(baseIndex + 1, baseIndex + 3, baseIndex + 5);
				indices.push(baseIndex + 3, baseIndex + 7, baseIndex + 5);
			}
		}
	
		// Create the geometry
		const geometry = new THREE.BufferGeometry();
		geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
		geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
		geometry.setIndex(indices);
		geometry.computeVertexNormals(); // Ensure normals are computed correctly
	
		if(this.instancedOrBatchedMesh && (this.instancedOrBatchedMesh as THREE.BatchedMesh).isBatchedMesh){
			if(this.updateCount === 0){
				const geometryId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addGeometry( geometry );
				(this.instancedOrBatchedMesh as THREE.BatchedMesh).addInstance(geometryId);
			}else{
				(this.instancedOrBatchedMesh as THREE.BatchedMesh).setGeometryAt( this.instanceId, geometry );
			}
		}
	
		this.updateCount++;
	}	

}