import * as THREE from 'three';
import { folderTitleCharset } from '../CosmosThree';
import { TBaseSymbol } from '../Mesh';

export const rndColor = () => new THREE.Color().setHex(Math.random() * 0xffffff);

export const toggleClass = (elm: HTMLElement, name: string) => {
  if (elm.classList.contains(name)) {
    elm.classList.remove(name);
  } else {
    elm.classList.add(name);
  }
}

export const getMakeIconUrl = (slug: string) => {
  const path = /^app#/.test(slug) ? `apps/${slug.substr(4)}` : `packages/${slug}`;
  return `https://eu1.make.com/static/img/${path}_32.png`;
}

export const sleep = (duration: number) => {
  return new Promise(resolve => setTimeout(resolve, duration));
}

/**
 * Creates a throttled function that only invokes the provided function (`func`) at most once
 * given number of milliseconds (`wait`)
 * @param fn - A function to be to be throttled.
 * @param wait - Wait time between each execution.
 * @returns The throttle function.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function throttle(fn: Function, wait = 20): any {
  let lastTime: number;

  return function throttled(this: any) {
    const now = Date.now();
    if (!lastTime || now - lastTime >= wait) {
      lastTime = now;
      // eslint-disable-next-line prefer-rest-params
      fn.apply(this, arguments);
    }
  };
}

export const getBoundsRectBySymbols = (symbols: TBaseSymbol[], offset = 0): THREE.Vector4 => {
  let minX = undefined;
  let minZ = undefined;
  let maxX = undefined;
  let maxZ = undefined;

  const unfilteredSymbols = [];

  for(let i = 0 ; i < symbols.length; i++){
    if(!symbols[i].filtered){
      unfilteredSymbols.push(symbols[i]);
    }
  }
  
  // Handle the special case where there is only one unfiltered symbol belonging to the group
  if(unfilteredSymbols.length === 1){
    minX = unfilteredSymbols[0].three.position.x;
    minZ = unfilteredSymbols[0].three.position.z;
    maxX = unfilteredSymbols[0].three.position.x;
    maxZ = unfilteredSymbols[0].three.position.z;
  }else{
    for(let i = 0 ; i < unfilteredSymbols.length; i++){
      const unfilteredSymbol = unfilteredSymbols[i];

      if (minX === undefined || unfilteredSymbol.three.position.x < minX) {
        minX = unfilteredSymbol.three.position.x;
      }
      if (minZ === undefined || unfilteredSymbol.three.position.z < minZ) {
        minZ = unfilteredSymbol.three.position.z;
      }
      if (maxX === undefined || unfilteredSymbol.three.position.x > maxX) {
        maxX = unfilteredSymbol.three.position.x;
      }
      if (maxZ === undefined || unfilteredSymbol.three.position.z > maxZ) {
        maxZ = unfilteredSymbol.three.position.z;
      }
    }
  }
  return new THREE.Vector4(minX! - offset, minZ! - offset, maxX! - minX! + offset*2, maxZ! - minZ! + offset*2);
}

export function cleanString(input: string): string {
  const regex = new RegExp(`[^${folderTitleCharset.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')}]`, 'g');
  return input.replace(regex, '');
}

export function findCentroid(symbols: TBaseSymbol[]): THREE.Vector2 {
  symbols = symbols.filter(symbol => !symbol.filtered);

  const points: THREE.Vector2[] = symbols.map(symbol => new THREE.Vector2(symbol.three.position.x, symbol.three.position.z));
  
  if (points.length === 0) {
    return new THREE.Vector2(0, 0); // Return origin if no points
  }

  if (points.length === 1) {
    return points[0]; // Return the only point
  }

  if (points.length === 2) {
    // Return the midpoint for two points
    return new THREE.Vector2((points[0].x + points[1].x) / 2, (points[0].y + points[1].y) / 2);
  }

  // Calculate the arithmetic mean for all points
  let sumX = 0;
  let sumY = 0;

  for (const point of points) {
    sumX += point.x;
    sumY += point.y;
  }

  const centroidX = sumX / points.length;
  const centroidY = sumY / points.length;

  return new THREE.Vector2(centroidX, centroidY);
}
