import type {Space, SpaceSlice} from './Space.ts'
import type {CosmosSlice} from './Cosmos.ts'
import type {ModalSlice} from './Modal.ts'
import type {NotificationSlice} from './Notification.ts'
import type {ConnectionSlice} from './Connection.ts'
import type {FilterSlice} from './Filter.ts'
import type {SettingsSlice} from './Settings.ts';
import type {FeaturesSlice} from './Features.ts';
import type {NodeDetailSlice} from './NodeDetail.ts'
import type {UserSlice} from './User.ts';
import type {UiPanelSlice} from './UiPanel.ts';
import type {ThemeSlice} from './Theme.ts';
import {create} from 'zustand'

import {SupabaseRealtimeListener} from '../utils/supabase-realtime.ts'
import {createSpaceSlice} from './Space.ts'
import {createCosmosSlice} from './Cosmos.ts'
import {createModalSlice} from './Modal.ts'
import {createNotificationSlice} from './Notification.ts'
import {createConnectionSlice} from './Connection.ts'
import {createFilterSlice} from './Filter.ts'
import {createSettingsSlice} from './Settings.ts';
import {createFeaturesSlice} from './Features.ts';
import {createNodeDetailSlice} from './NodeDetail.ts';
import {createUserSlice} from './User.ts';
import {createUiPanelsSlice} from './UiPanel.ts';
import {createThemeSlice} from './Theme.ts';

export const useAppStore = create<
    FeaturesSlice &
    ThemeSlice &
    SpaceSlice &
    ConnectionSlice &
    CosmosSlice &
    ModalSlice &
    NotificationSlice &
    FilterSlice &
    SettingsSlice &
    NodeDetailSlice &
    UserSlice &
    UiPanelSlice
>()(
    (...a) => ({
        ...createFeaturesSlice(...a),
        ...createThemeSlice(...a),
        ...createSpaceSlice(...a),
        ...createCosmosSlice(...a),
        ...createModalSlice(...a),
        ...createNotificationSlice(...a),
        ...createConnectionSlice(...a),
        ...createFilterSlice(...a),
        ...createSettingsSlice(...a),
        ...createNodeDetailSlice(...a),
        ...createUserSlice(...a),
        ...createUiPanelsSlice(...a)
    }));

const spaceListener = new SupabaseRealtimeListener<Space>(
    'spaces', {
        insert: useAppStore.getState().insertSpaceToStore,
        update: useAppStore.getState().updateSpaceInStore,
        delete: useAppStore.getState().removeSpaceFromStore
    });
spaceListener.subscribe();
