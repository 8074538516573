import * as THREE from 'three';
import { CosmosThree } from '../../CosmosThree';
import { Repository } from '../../common/Repository';
import { BaseInstancedMesh } from '../../common/BaseInstancedMesh';
import { throttle } from '../utils';
import { BaseSymbol } from '../../symbols/BaseSymbol';
import { BaseBatchedMesh } from '../../common/BaseBatchedMesh';
import { BaseLink } from '../../links/BaseLink';

export class InputManager{

    cosmos: CosmosThree;

    private _raycaster: THREE.Raycaster;
    private _raycasterVector = new THREE.Vector3();
    private _raycasterDirection = new THREE.Vector3();
    private _pointer = new THREE.Vector2( -100000, -100000 ); // We set the initial value super far so it never intersects anything before the pointer is updated in the pointermove event

    private _enabled = true;

    private _pointerDown: THREE.Vector2 | null = null;

    private _pointerTarget: BaseSymbol | BaseLink | undefined;
    private _pointerClickLast: BaseSymbol | BaseLink | undefined;
    private _pointerClickTime: number | undefined;
    private _doubleclickTolerance = 400; // in milliseconds
    private _pointerMoved = false;
    private _pointerMoveTolerance = 0.01; // in screen pixels

    private _pointerDragging = false;

    private _hovered: BaseSymbol | BaseLink | undefined;

    keyShiftDown = false;
    keyControlCommandDown = false;

    /**
     * The current value of the CSS mouse cursor.
     */
    private _mouseCursor = "default";

    constructor(cosmos: CosmosThree){
        this.cosmos = cosmos;

        // Raycaster
        this._raycaster = new THREE.Raycaster();
        this._raycaster.layers.set( 1 );

        // DOM Events
        this.cosmos.renderer.domElement.addEventListener( 'pointerleave', this.onPointerLeave.bind(this) );

        this.cosmos.renderer.domElement.addEventListener( 'pointerdown', this.onPointerDown.bind(this) );
        this.cosmos.renderer.domElement.addEventListener( 'pointermove', throttle(this.onPointerMove.bind(this), 10) );
        this.cosmos.renderer.domElement.addEventListener( 'pointerup', this.onPointerUp.bind(this) );

        this.cosmos.renderer.domElement.addEventListener( 'contextmenu', this.onContextMenu.bind(this) );

        this.cosmos.renderer.domElement.addEventListener( 'keydown', this.onKeyDown.bind(this) );
        this.cosmos.renderer.domElement.addEventListener( 'keyup', this.onKeyUp.bind(this) );
    }

    /**
     * Indicates whether the InputManager is enabled.
     */
    get enabled() {
        return this._enabled;
    }

    /**
     * Enables/disables the InputManager. Disabled InputManager doesn't react to user events.
     */
    set enabled(value) {
        if (value === this._enabled) return;
        this._enabled = value;

        if(!this._enabled){
            if (this._mouseCursor !== "default") {
                this._mouseCursor = "default";
                this.cosmos.container.style.cursor = this._mouseCursor;
            }
        }
    }

    /**
     * Object that the cursor is hovering over.
     */
    get hovered() {
        return this._hovered;
    }

    /**
     * Object that the cursor is hovering over.
     */
    set hovered(value: BaseSymbol | BaseLink | undefined) {
        if (this._hovered === value) return;

        if (this._hovered) {
            this._hovered.hover = false;
            if (this._mouseCursor !== "default") {
                this._mouseCursor = "default";
                this.cosmos.container.style.cursor = this._mouseCursor;
            }
        }

        this._hovered = value;

        if (this._hovered) {
            this._hovered.hover = true;
            if (this._mouseCursor !== "pointer") {
                this._mouseCursor = "pointer";
                this.cosmos.container.style.cursor = this._mouseCursor;
            }
        }
    }

    private interactiveAt(pointer: THREE.Vector2): BaseSymbol | BaseLink | undefined {
        // Find intersections
        // Important: this.raycaster.setFromCamera( this.pointer, this.camera ) doesn't work because the OrthographicCamera near plane can be negative, the following lines are super important.
        this._raycasterVector.set( pointer.x, pointer.y, -1 ); // z = -1 important!
        this._raycasterVector.unproject( this.cosmos.graphCamera );
        this._raycasterDirection.set( 0, 0, -1 ).transformDirection( this.cosmos.graphCamera.matrixWorld );
        this._raycaster.set( this._raycasterVector, this._raycasterDirection );

        if(Repository.mesh){
            const intersects = this._raycaster.intersectObject( Repository.mesh );
            
            if ( intersects.length > 0 ) {
                if((intersects[0].object as BaseInstancedMesh).isInstancedMesh){
                    const instersectedElem = (intersects[0].object as BaseInstancedMesh).elems[intersects[0].instanceId!];
                    if(instersectedElem){
                        let symbol: BaseSymbol;
                        if(intersects[0].object.name === "symbolShapesIM"){
                            symbol = Repository.mesh?.symbols[instersectedElem.globalInstanceId];

                            if(!symbol.filtered && symbol.visible){
                                // Returns a symbol
                                return symbol;
                            }
                        }
                    }
                }else if((intersects[0].object as BaseBatchedMesh).isBatchedMesh){
                    const instersectedElem = (intersects[0].object as BaseBatchedMesh).elems[intersects[0].batchId!];
                    if(instersectedElem){
                        let link: BaseLink;
                        if(intersects[0].object.name === "linksShapesBM"){
                            link = Repository.mesh?.links[instersectedElem.instanceId];

                            if(!link.filtered && link.visible && link.opacity === 1){
                                // Returns a link
                                return link;
                            }
                        }
                    }
                }/* else{
                    // Returns whatever other Three object
                    return intersects[0].object;
                } */
            }
        }

        // In any other case returns null
        return;
    }

    private onPointerLeave() {
        if (!this.enabled) return;
    
        this.hovered = undefined;
        
        this._pointerDown = null;
        this._pointerDragging = false;
        this._pointerTarget = undefined;
        this._pointerMoved = false;
    }

    private onPointerDown( event: PointerEvent ) {
        if (!this.enabled || event.button !== 0) return;

        event.preventDefault();

        // The canvas has to be focused or otherwise it won't fire keyboard events
        this.cosmos.renderer.domElement.focus();

        this._pointer.x = ( event.clientX / this.cosmos.canvasWidth ) * 2 - 1;
        this._pointer.y = - ( event.clientY / this.cosmos.canvasHeight ) * 2 + 1;

        this._pointerDown = this._pointer.clone();

        this._pointerDragging = true;

        this._pointerTarget = this.hovered;
    }

    private onPointerMove( event: PointerEvent ) {
        if (!this.enabled || ![0, -1].includes(event.button)) return;

        event.preventDefault();

        this._pointer.x = ( event.clientX / this.cosmos.canvasWidth ) * 2 - 1;
        this._pointer.y = - ( event.clientY / this.cosmos.canvasHeight ) * 2 + 1;

        // Check if the pointer moved after a pointer down
        if (this._pointerDown && !this._pointerMoved){
            if(this._pointerDown.distanceTo(this._pointer) > this._pointerMoveTolerance){
                this._pointerMoved = true;
            }
        }

        if(!this._pointerDragging){
            this.hovered = this.interactiveAt(this._pointer);
        }
    }

    private onPointerUp( event: PointerEvent ) {
        if (!this.enabled) return;

        if (event.button === 2) return; // Right mouse button was used, prevent the handler from executing further

        event.preventDefault();

        this._pointer.x = ( event.clientX / this.cosmos.canvasWidth ) * 2 - 1;
        this._pointer.y = - ( event.clientY / this.cosmos.canvasHeight ) * 2 + 1;

        if(!this._pointerMoved){
            if(this._pointerTarget) {
                if (this._pointerClickTime && this._pointerTarget === this._pointerClickLast && Date.now() - this._pointerClickTime < this._doubleclickTolerance) {
                    this._pointerClickTime = undefined;
                    this._pointerClickLast = undefined;
                    
                    this._pointerTarget.doubleclick = true;
                } else {
                    this._pointerClickTime = Date.now();
                    this._pointerClickLast = this._pointerTarget;

                    this._pointerTarget.click = true;
                }
            }else{
                // There was a click outside any element
                this.unselect();
            }
        }

        this._pointerDown = null;
        this._pointerDragging = false;
        this._pointerTarget = undefined;

        this._pointerMoved = false;
    }

    private onContextMenu( event: MouseEvent ) {
        if (!this.enabled) return;
        // 
        event.preventDefault();

        this.keyControlCommandDown = false;
        this._pointerDragging = false;

        this.hovered?.contextmenued.dispatch(new THREE.Vector2(event.clientX, event.clientY));
    }

    private onKeyDown( event: KeyboardEvent ) {
        if (!this.enabled) return;
        // 

        const target = event.target as Element;

        if (target?.matches('input, textarea, select, [contenteditable="true"]')) return;

        if(event.metaKey){
            this.keyControlCommandDown = true;
            return;
        }

        switch (event.key) {
            case "+": // +
                //
                break;
            case "-":
                //
                break;
            case "Control":
                this.keyControlCommandDown = true;
                break;
            case "Shift":
                this.keyShiftDown = true;
                break;
            default:
                break;
        }
    }

    private onKeyUp( event: KeyboardEvent ) {
        if (!this.enabled) return;
        // 
        const target = event.target as Element;

        if (target?.matches('input, textarea, select, [contenteditable="true"]')) return;

        if(this.keyControlCommandDown && !event.metaKey){
            this.keyControlCommandDown = false;
            return;
        }

        switch (event.key) {
            case "Control":
                this.keyControlCommandDown = false;
                break;
            case "Shift":
                this.keyShiftDown = false;
                break;
            case "Escape":
                this.unselect();
                break;
            default:
                break;
        }
    }

    unselect(){
        this._mouseCursor = "default";
        this.cosmos.container.style.cursor = this._mouseCursor;
        Repository.mesh?.unselectAll();
    }

    dispose(){
        this.cosmos.renderer.domElement.removeEventListener( 'pointerleave', this.onPointerLeave.bind(this) );

        this.cosmos.renderer.domElement.removeEventListener( 'pointerdown', this.onPointerDown.bind(this) );
        this.cosmos.renderer.domElement.removeEventListener( 'pointermove', throttle(this.onPointerMove.bind(this), 10) );
        this.cosmos.renderer.domElement.removeEventListener( 'pointerup', this.onPointerUp.bind(this) );

        this.cosmos.renderer.domElement.removeEventListener( 'contextmenu', this.onContextMenu.bind(this) );

        this.cosmos.renderer.domElement.removeEventListener( 'keydown', this.onKeyDown.bind(this) );
        this.cosmos.renderer.domElement.removeEventListener( 'keyup', this.onKeyUp.bind(this) );
    }

}