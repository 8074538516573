import * as THREE from 'three';

import { Repository } from '../../../common/Repository';

import { BaseSymbol, SerializedSymbol } from '../../BaseSymbol';
import { FilterDefinition, FilterGroupDefinition, FilterMappingType } from '../../../../store/Filter';
import { SYMBOL_HEIGHT, SYMBOL_ROUNDED_TRIANGLE_RADIUS, SYMBOL_SIZE } from '../../../common/constants';

export interface SerializedWebhook extends SerializedSymbol {
	attributes: {
		url: string;
		limit: number;
		queue: number;
		enabled?: boolean
	}
}

export class WebhookSymbol extends BaseSymbol {

	originalData: SerializedWebhook | null = null;

    constructor () {
        super();
    }

	get detailDisplayProps() {
		return [
			{
				key: 'attributes.limit',
				label: 'Limit'
			},
			{
				key: 'attributes.queue',
				label: 'Queue'
			},
			{
				key: 'attributes.url',
				label: 'Webhook'
			},...this.detailBaseDisplayProps];
	}

	override getGeometry() {
		const r = SYMBOL_SIZE / 1.25;
		const ha = THREE.MathUtils.degToRad(30);
		const h = SYMBOL_ROUNDED_TRIANGLE_RADIUS * (Math.sin(ha) + Math.cos(ha) * Math.sqrt(3));
		const center = r - Math.min(r, h);

		const base = new THREE.Vector2(0, center);
		const c = new THREE.Vector2();
		const v = new THREE.Vector2();

		const angleStep = Math.PI / 6;

		const shape = new THREE.Shape();
		for (let i = 0; i < 3; i++) {
			const a = angleStep * 4 * i;
			v.copy(base).rotateAround(c, a);
			shape.absarc(v.x, v.y, SYMBOL_ROUNDED_TRIANGLE_RADIUS, angleStep + a, angleStep * 5 + a);
		}

		const geometry = new THREE.ExtrudeGeometry(shape, {
			depth: SYMBOL_HEIGHT,
			bevelEnabled: false,
			bevelThickness: 0,
			bevelSize: 0,
			bevelSegments: 0,
			curveSegments: 12
		}).rotateX(-Math.PI/2);

        return geometry;
    }

	override get type() {
		return super.type;
	}

	override set type(value) {
		super.type = value;

		this.shapeType = "tri";

		this.iconSlug = "gateway";
	}

	override fromJSON(object: SerializedSymbol): void {
		object.typeLabel = 'Webhook';
		super.fromJSON(object)
	}

	static override getFilterGroupDefinition(): FilterGroupDefinition[] {
		return [
			{
				id: 'webhooks',
				label: 'Webhooks'
			}
		]
	}

	static override getFilterDefinition(): FilterDefinition[] {
		return [
			{
				groupId: 'webhooks',
				label: 'Enabled webhooks',
				id: 'enabledWebhooks',
				inputType: 'checkbox',
				filterType: 'boolean',
				value: true,
				filterMapping: 'attributes.enabled',
				filterMappingType: 'boolean' as FilterMappingType,
				priority: 2
			},
			{
				groupId: 'webhooks',
				label: 'Disabled webhooks',
				id: 'disabledWebhooks',
				inputType: 'checkbox',
				filterType: 'boolean',
				value: false,
				filterMapping: 'attributes.enabled',
				filterMappingType: 'boolean' as FilterMappingType
			}
		]
	}

}

Repository.install('make:webhook', WebhookSymbol);