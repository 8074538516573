import type {LayoutOptions} from './LayoutManager.ts';
import {YFilesLayout} from './yfiles/YFilesLayout.ts';

export interface WorkerSymbol {
  id: string,
  x: number,
  y: number,
  width?: number,
  height?: number,
  groupId?: string,
}

export interface WorkerGroup {
  id: string,
  parentId: string | null,
}

export interface WorkerLink {
  id: string,
  source: {
    id: string
  },
  target: {
    id: string
  }
}

export enum REQUEST_MESSAGE_TYPE {
  LAYOUT = 'layout'
}

export enum RESPONSE_MESSAGE_TYPE {
  LAYOUT_DONE = 'layoutDone'
}

export interface WorkerLayoutRequestMessage {
  message: REQUEST_MESSAGE_TYPE,
  workerId: number,
  promiseId: string,
  symbols: WorkerSymbol[],
  groups: WorkerGroup[],
  links: WorkerLink[],
  symbolsAndLinksIndex: string,
  cacheOnly: boolean,
  options: LayoutOptions
}

export interface WorkerLayoutResponseMessage {
  workerId: number,
  message: RESPONSE_MESSAGE_TYPE,
  promiseId: string,
  symbols: WorkerSymbol[],
  symbolsAndLinksIndex: string,
  cacheOnly: boolean,
  options: LayoutOptions
}

export interface LayoutResult {
  symbols: WorkerSymbol[]
}

export class LayoutWorker {

  public static async layout(
      symbols: WorkerSymbol[],
      groups: WorkerGroup[],
      links: WorkerLink[],
      options: LayoutOptions
  ): Promise<LayoutResult> {
    return await YFilesLayout.applyLayout(symbols, groups, links, options);
  }

}

onmessage = async (e) => {
  const data = e.data as WorkerLayoutRequestMessage;
  switch (data.message) {
    case REQUEST_MESSAGE_TYPE.LAYOUT: {

      const promiseId = data.promiseId;

      const result = await LayoutWorker.layout(
          data.symbols,
          data.groups,
          data.links,
          data.options
      );

      postMessage({
        workerId: data.workerId,
        message: RESPONSE_MESSAGE_TYPE.LAYOUT_DONE,
        promiseId,
        symbols: result.symbols,
        symbolsAndLinksIndex: data.symbolsAndLinksIndex,
        options: data.options,
        cacheOnly: data.cacheOnly
      } as WorkerLayoutResponseMessage);

      break;
    }
  }
}