import type {FuseResult} from 'fuse.js';
import { BaseSymbol, SerializedSymbol } from '../../symbols/BaseSymbol';
import Fuse from 'fuse.js';
import { Repository } from '../../common/Repository';
import { FilterResult } from '../filter/FilterManager';
import { Logger } from '../../../utils/Logger';

export interface SearchResult {
	symbol: BaseSymbol,
	fuseResult: FuseResult<SerializedSymbol>,
	outside?: boolean, // of the search result item is not present in current filtered results
	duplications?: BaseSymbol[]
}

const fuseOptions = {
	useExtendedSearch: true,
	includeScore: true,
	includeMatches: true,
	minMatchCharLength: 3,
	ignoreLocation: true,
	threshold: 0.0,
	keys: [
		{
			name: 'title',
			weight: 5
		}, {
			name: 'url',
			weight: 2
		}, {
			name: 'type',
			weight: 1
		}, {
			// @ TODO other symbols can have different attributes, we have to set keys dynamically
			name: 'attributes.apps.slug',
			weight: 0.5
		}, {
			name: 'attributes.apps.title',
			weight: 0.5
		}, {
			name: 'attributes.folder.name',
			weight: 0.5
		}, {
			name: 'attributes.url',
			weight: 0.5
		}
	]
}

export class SearchManager {

    fuse: Fuse<SerializedSymbol>;

    constructor (serializedSymbols: SerializedSymbol[]) {
        Logger.time('[perf] mesh: load fuse');
        this.fuse = new Fuse(serializedSymbols, fuseOptions);
        Logger.timeEnd('[perf] mesh: load fuse');
    }

    searchFuse(phrase: string, filterResult: FilterResult): SearchResult[] {
		phrase = phrase.trim();
		if (!phrase.length) {
			return [];
		}
		const results = [] as SearchResult[];
		const resultsFilteredOut = [] as SearchResult[];
		const duplicatedResults = new Map<string, SearchResult>();
		// @TODO move this to filterNodes function
		const filteredIds = filterResult.symbols.length ? filterResult.symbols.map(symbol => symbol.id) : [];
		if (this.fuse) {
			const fQuery =  "'" + phrase.split(' ')
				.filter(qItem => qItem.trim().length > 0)
				.join(" '");
			const res = this.fuse.search<SerializedSymbol>(fQuery);
			if (res.length) {
				res.forEach((item: FuseResult<SerializedSymbol>) => {
					const symbol = Repository.symbols.get(item.item.id!);
					if (symbol) {

						const searchResult: SearchResult = {symbol, fuseResult: item};

						if (item.item.isDuplicate && item.item.originalId) {
							const dupResult = duplicatedResults.get(item.item.originalId);
							if (!dupResult) {
								searchResult.duplications = [symbol];
								duplicatedResults.set(item.item.originalId, searchResult);
							} else {
								dupResult.duplications!.push(symbol);
								return;
							}
						}

						if (!filteredIds.length || filteredIds.includes(symbol.id)) {
							results.push(searchResult);
						} else {
							searchResult.outside = true;
							resultsFilteredOut.push(searchResult);
						}
					}
				});
				return results.concat(resultsFilteredOut);
			}
		}
		return [];
	}

}