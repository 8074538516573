import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useAppStore } from '@/store/Store.ts'
import { Close } from '@/assets/icons/Close';

export default function Modal() {

  const { modal, ModalComponent, closeModal } = useAppStore((state) => {
    return {
      modal: state.modal,
      ModalComponent: state.modal.component,
      closeModal: state.closeModal
    }
  });

  // const cancelButtonRef = useRef(null)

  // handle escape
  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        closeModal();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  // initialFocus={cancelButtonRef} // was on Dialog element

  return (
    <>
      <Transition.Root show={modal.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-transparent transition-all" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="ui-panel relative transform overflow-hidden text-left transition-all sm:w-full sm:max-w-lg">
                  <div className="absolute right-0 top-0 hidden pr-5 pt-5 sm:block">
                    <button
                      tabIndex={-1}
                      type="button"
                      className="ui-btn-icon-close"
                      onClick={closeModal}
                    >
                      <Close />
                    </button>
                  </div>
                  {ModalComponent ? (
                    <ModalComponent {...modal.componentProps} />
                  ) : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}