
export const FKey = () => {
    return (
        <svg height="16" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="0.927246" width="11" height="12" rx="2" stroke="currentColor" strokeLinejoin="round"/>
            <path d="M4.29261 10.4272V3.88179H8.21733V4.58492H5.08523V6.79656H7.9233V7.49969H5.08523V10.4272H4.29261Z"
                  fill="currentColor"/>
        </svg>
    )
}
