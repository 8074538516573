import type { StateCreator } from 'zustand'
import type { Nullable } from '../types/common.ts'
import type { ComponentType } from 'react'


export type Modal = {
    open: boolean;
    component: Nullable<ComponentType>;
    componentProps?: any;
}

export interface ModalSlice {
    modal: Modal;
    openModal: (component: any, componentProps?: any) => void;
    closeModal: () => void;
}

export const createModalSlice: StateCreator<ModalSlice> = (set) => ({
    modal: {
        open: false,
        component: null,

    },
    openModal: (component, componentProps?: any) => set(() => (
      { modal: { open: true, component, componentProps } })
    ),
    closeModal: () => {
        set((state) => (
          { modal: { ...state.modal, open: false } }
        ))
        setTimeout(() => {
            set((state) => (
              { modal: { ...state.modal, component: null, componentProps: {} } }
            ))
        }, 300);
    },
})
