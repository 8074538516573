import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionGreyscale } from '../utils/materials/extensions/ExtensionGreyscale';
import { LINK_INDICATOR_HEIGHT, LINK_INDICATOR_SIZE } from '../common/constants';
import { ExtensionOpacity } from '../utils/materials/extensions/ExtensionOpacity';

export class LinkIndicator extends BaseObject{

    constructor(){
        super();
    }

    override getGeometry() {
        const shape = new THREE.Shape();
        const size = LINK_INDICATOR_SIZE;
    
        // Define the vertices of the triangle with one point 1.5 times longer
        shape.moveTo(0, size * 0.75); // Top vertex (1.5 times longer)
        shape.lineTo(-size / 2, -size / 2); // Bottom left vertex
        shape.lineTo(size / 2, -size / 2); // Bottom right vertex
        shape.lineTo(0, size * 0.75); // Close the path
    
        const geometry = new THREE.ExtrudeGeometry(shape, {
            depth: LINK_INDICATOR_HEIGHT,
            bevelEnabled: false,
            bevelThickness: 0,
            bevelSize: 0,
            bevelSegments: 0,
            curveSegments: 0
        }).rotateX(-Math.PI / 2);
    
        return geometry;
    }

    override getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionGreyscale, ExtensionOpacity],
            {
                color: 0xffffff,
                transparent: true,
                alphaToCoverage: true, // This is super important so transparent geometries don't clip the ones behind even when they are fully transparent
                //wireframe: true
            },
            { debug: false }
        );

        return material;
    }
}