import type { StateCreator } from 'zustand';
import type { ComponentType } from 'react';

import {ComponentLoader} from './../utils/component-loader.ts';

export enum UI_PANELS {
    NODE_DETAIL = 'nodeDetail',
    FEATURES = 'features'
}

export enum UI_PANEL_POSITION {
    RIGHT = 'right'
}

export type UiPanel = {
    id: string;
    position: UI_PANEL_POSITION,
    component: ComponentType,
    show:boolean
}

export interface UiPanelSlice {
    uiPanels: Map<string, UiPanel>
    getUiPanel: (id: string) => UiPanel | undefined;
    openUiPanel: (id: string, position: UI_PANEL_POSITION, component: ComponentType | string) => void;
    closeUiPanel: (id: string) => void
}

export const createUiPanelsSlice: StateCreator<UiPanelSlice> = (set, get) => ({
    uiPanels: new Map(),
    getUiPanel: (id) => get().uiPanels.get(id),
    openUiPanel: async (id, position, component) => {

        // @TODO dynamic imports dont work after build now
        if (typeof component === 'string') {
            component = await ComponentLoader.load(component);
        }

        set((state) => (
            {
                uiPanels: new Map(state.uiPanels).set(id, {
                    id,
                    position,
                    component: component as ComponentType,
                    show: false
                })
            })
        )

        // next tick because headless ui transitions
        setTimeout(() => {
            const items = new Map(get().uiPanels);
            items.set(id, {...items.get(id)!, ...{show: true}})
            set(() => (
                {
                    uiPanels: new Map(items)
                })
            )
        }, 0);
    },
    closeUiPanel: (id) => set((state) => {
            const items =  new Map(state.uiPanels);
            items.delete(id);
            return (
                {
                    uiPanels: items
                }
            )
        }
    ),
})
