export const SCALE_FACTOR = 100;

export const PREVENT_Z_FIGHTING = 0.02 / SCALE_FACTOR; // Prevents glitch when two objects are in the same position because of unavoidable rounding errors in the shaders.
export const PREVENT_SHADOW_LEAK = 6 / SCALE_FACTOR; // Even when objects are under the floor a bit of the shadows 'leak' to it if they are not far enough under it.

export const GROUND_OFFSET = 1 / SCALE_FACTOR;

export const GRID_SHORT_SUBDIV = 50 / SCALE_FACTOR;
export const GRID_LARGE_SUBDIV = 500 / SCALE_FACTOR;

export const SYMBOL_SIZE = 64 / SCALE_FACTOR;
export const SYMBOL_HEIGHT = 20 / SCALE_FACTOR;
export const SYMBOL_ROUNDED_RECT_RADIUS = 12 / SCALE_FACTOR;
export const SYMBOL_CIRCLE_RADIUS = 32 / SCALE_FACTOR;
export const SYMBOL_ROUNDED_TRIANGLE_RADIUS = 12 / SCALE_FACTOR;

export const SYMBOL_MAKE_SCENARIO_SIZE = 90 / SCALE_FACTOR;
export const SYMBOL_APP_SIZE = 24 / SCALE_FACTOR;
export const SYMBOL_APP_HEIGHT = 22 / SCALE_FACTOR;
export const SYMBOL_APP_CIRCLE_RADIUS = 12 / SCALE_FACTOR;
export const SYMBOL_APP_POSITIONS = [
    {
        x: - SYMBOL_MAKE_SCENARIO_SIZE / 3,
        z: -SYMBOL_APP_SIZE - SYMBOL_APP_SIZE / 6, rotation: Math.PI / 2
    },
    {
        x: - SYMBOL_MAKE_SCENARIO_SIZE / 3,
        z: 0, rotation: -Math.PI / 2
    },
    {
        x: - SYMBOL_MAKE_SCENARIO_SIZE / 3,
        z: SYMBOL_APP_SIZE + SYMBOL_APP_SIZE / 6, rotation: Math.PI / 2
    },
    {
        x: - SYMBOL_MAKE_SCENARIO_SIZE / 3 + SYMBOL_APP_SIZE * 1.3,
        z: SYMBOL_APP_SIZE + SYMBOL_APP_SIZE / 12, rotation: -Math.PI / 2
    },
];

export const ICON_SIZE = 30 / SCALE_FACTOR;

export const APPS_COUNT_WIDTH = 24;
export const APPS_COUNT_HEIGHT = 14;

export const LEGEND_WIDTH = 154;
export const LEGEND_HEIGHT = 20;
export const LEGEND_MAX_UNTRIMMED_CHARS = 18;
export const LEGEND_POSITION_Y_OFFSET = 60 / SCALE_FACTOR;
export const LEGENDS_INITIAL_ZOOM = 0.4;
export const LEGENDS_FINAL_ZOOM = 0.5;

export const SYMBOL_SHARED_SIZE = 64 / SCALE_FACTOR;
export const SYMBOL_SHARED_HEIGHT = SYMBOL_SHARED_SIZE / 2;
export const SYMBOL_SHARED_Y = 400 / SCALE_FACTOR;

export const LINK_WIDTH = 9 / SCALE_FACTOR;
export const LINK_HEIGHT = 6 / SCALE_FACTOR;
export const LINK_INDICATOR_SIZE = 20 / SCALE_FACTOR;
export const LINK_INDICATOR_HEIGHT = 5 / SCALE_FACTOR;
export const LINK_INDICATOR_VELOCITY = 1.5;

export const VIRTUAL_LINK_LENGTH = 120 / SCALE_FACTOR;
export const VIRTUAL_LINK_Y = SYMBOL_SHARED_Y / 5;
export const VIRTUAL_LINK_HEIGHT = 6 / SCALE_FACTOR;
export const VIRTUAL_LINK_SOURCE_OFFSET = 5 / SCALE_FACTOR;

export const SELECTION_INDICATOR_SIZE_FACTOR = 1.3;
export const SELECTION_INDICATOR_SCALE_ANIM = 1.7;

export const GROUP_ROUNDED_RECT_RADIUS = 48 / SCALE_FACTOR;
export const GROUP_TITLE_HEIGHT = 130 / SCALE_FACTOR;
export const GROUP_TITLE_PADDING = 15 / SCALE_FACTOR;

export const INTERACTION_SPOTLIGHT_DELAY = 0.3; // in seconds
export const INTERACTION_SPOTLIGHT_STAGGERING = 0.15; // in seconds

export const INTERACTION_ANIMATION_SPEED = 0.3; // in seconds

export const CAMERA_FIT_WIDTH_MARGIN = 0.1; // in percentage of the viewport from 0 to 1
export const CAMERA_FIT_HEIGHT_MARGIN = 0.1; // in percentage of the viewport from 0 to 1