import type {BreadcrumbItem} from '../../Breadcrumb.tsx';

import {classNames,/* isMac*/} from '@/utils/helpers.ts';
import {useAppStore} from '@/store/Store.ts'
import {Transition} from '@headlessui/react'
import {BaseSymbol} from '@/three/symbols/BaseSymbol.ts'
import {MakeScenarioSymbol} from '@/three/symbols/types/make/MakeScenarioSymbol.ts';
import {WebhookSymbol} from '@/three/symbols/types/webhook/WebhookSymbol.ts';
import {CheckIcon, XMarkIcon} from '@heroicons/react/20/solid';
import {CodeBracketSquareIcon} from '@heroicons/react/24/outline';
import {useState, useRef, useEffect} from 'react';
import {Close} from '@/assets/icons/Close.tsx';
import {OpenUrl} from '@/assets/icons/OpenUrl.tsx';
/*import {MouseLeft} from '@/assets/icons/MouseLeft.tsx';
import {Escape} from '@/assets/icons/Escape.tsx';*/
import {Folders} from '@/assets/icons/Folders.tsx';
import {Home} from '@/assets/icons/Home.tsx';
import {Links} from '@/assets/icons/Links.tsx';
import {changeHue} from '@/utils/color.ts';
import Panel from './../Panel.tsx';
import Breadcrumb from '../../Breadcrumb.tsx';
import Pill from '../../Pill.tsx';
/*import PlusPill from '../ui/PlusPill.tsx';*/
import Badge from '../../Badge.tsx';
import AppsCircles from '../../AppsCircles.tsx';
import NodeLinks from './NodeLinks.tsx';
import _ from 'lodash';
import {AppEnv} from '@/utils/AppEnv.ts';
/*import {CommandMacKey} from '@/assets/icons/CommandMacKey.tsx';
import {CtrlKey} from '@/assets/icons/CtrlKey.tsx';*/
import Tooltip from '@/components/utils/Tooltip.tsx';

type DetailView = 'node' | 'links';

export default function NodeDetail() {

    const { nodeDetail, currentMeshInstance } = useAppStore((state) => {
        return {
            nodeDetail: state.nodeDetail as BaseSymbol | null,
            currentMeshInstance: state.currentMeshInstance
        }
    });

    const [currentView, setCurrentView] = useState<DetailView>('node');
    const [showRaw, setShowRaw] = useState(false);
    const [innerContainerHeight, setInnerContainerHeight] = useState(0);
    /*const [filterSearch] = useState('');*/
    const nodeContainerRef = useRef<HTMLDivElement>(null);
    const linksContainerRef = useRef<HTMLDivElement>(null);
    const lastNodeDetailId = useRef<string | null>(null);

    const closeDetail = () => {
        if (currentMeshInstance) {
            // close spotlight
            currentMeshInstance.unselectAll();
        }
    }

    const getBreadcrumbItems = () => {
        const items: BreadcrumbItem[] = [];
        if ((nodeDetail as MakeScenarioSymbol)?.originalData?.attributes?.organization?.name) {
            items.push({
                id: (nodeDetail as MakeScenarioSymbol).originalData!.attributes!.organization!.id,
                label: (nodeDetail as MakeScenarioSymbol).originalData!.attributes.organization!.name
            });
        }
        if ((nodeDetail as MakeScenarioSymbol)?.originalData?.attributes?.team?.name) {
            items.push({
                id: (nodeDetail as MakeScenarioSymbol).originalData!.attributes!.team!.id,
                label: (nodeDetail as MakeScenarioSymbol).originalData!.attributes.team!.name
            });
        }
        if ((nodeDetail as MakeScenarioSymbol)?.originalData?.attributes?.folder?.name) {
            items.push({
                id: (nodeDetail as MakeScenarioSymbol).originalData!.attributes!.folder!.id,
                label: (nodeDetail as MakeScenarioSymbol).originalData!.attributes.folder!.name
            });
        }
        return items;
    }

    const isSymbolActive = (nodeDetail: BaseSymbol): boolean => {
        if (nodeDetail instanceof MakeScenarioSymbol) {
            return nodeDetail.originalData!.attributes.active;
        }
        if (nodeDetail instanceof WebhookSymbol) {
            return (nodeDetail as WebhookSymbol).originalData!.attributes.enabled || false;
        }
        return false;
    }

    const symbolHasUrl = (nodeDetail: BaseSymbol): boolean => {
        return !!nodeDetail.originalData?.url && !nodeDetail.preventOpenUrl;
    }

    const showUsages = (nodeDetail: BaseSymbol) => {
        if (currentMeshInstance) {
            currentMeshInstance.showUsages = true;
            nodeDetail.select = true;
        }
    }

    useEffect(() => {
        setTimeout(() => {
            switch (currentView) {
                case 'node': {
                    if (nodeContainerRef.current) {
                        const height = nodeContainerRef.current.clientHeight + 20;
                        setInnerContainerHeight(height!);
                    }
                    break;
                }
                case 'links': {
                    if (linksContainerRef.current) {
                        const height = linksContainerRef.current.clientHeight + 20;
                        setInnerContainerHeight(height!);
                    }
                    break;
                }
            }
        },0);
    }, [currentView, nodeDetail]);

    useEffect(() => {
        if (!nodeDetail?.id) {
            lastNodeDetailId.current = null;
            setCurrentView('node');
        } else {
            if (lastNodeDetailId.current !== nodeDetail.id) {
                setCurrentView('node');
            }
            lastNodeDetailId.current = nodeDetail.id;
        }
    }, [nodeDetail]);

    const setView = (view: DetailView) => {
        setCurrentView(view);
    }

    const onShowAllLinks = () => {
        setView('links');
    }

  return (
      <Transition
          key='nodeDetail'
          show={!!nodeDetail}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 translate-x-80"
          enterTo="translate-y-0 translate-x-0"
          leave="transform transition ease-in duration-300"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-80"
      >
          <div className={'flex flex-row gap-2'}>
              {nodeDetail && nodeDetail instanceof BaseSymbol && (
                  <>
                      {nodeDetail?.links.length > 0 && (
                          <div className={'flex flex-col gap-1.5'}>
                              <div className={'ui-panel ui-pointer-events flex p-1.5'}>
                                  <button type={'button'} onClick={() => setView('node')}
                                          className={classNames(currentView === 'node' ? 'active' : '', 'ui-btn-icon rounded-btn')}>
                                      <Home className={'p-0.5'}/>
                                  </button>
                              </div>
                              <div className={'ui-panel ui-pointer-events flex p-1.5'}>
                                  <button type={'button'} onClick={() => setView('links')}
                                          className={classNames(currentView === 'links' ? 'active' : '', 'ui-btn-icon rounded-btn')}>
                                      <Links className={'p-0.5'}/>
                                  </button>
                              </div>
                          </div>
                      )}
                      <Panel className={'max-h-[80vh]'}>
                          <div className={'flex flex-col w-full gap-3 px-sm'}>
                              <div className={'flex w-full gap-3 justify-between flex-row items-start p-0'}>
                                  <div className={'flex flex-row grow items-top gap-3'}>
                                      <div
                                          className={`rounded-btn ui-shadow-b w-10 h-10 shrink-0 p-2`}
                                          style={{
                                              background: `linear-gradient(${changeHue(`#${nodeDetail.color.getHexString()}`, 15)}, #${nodeDetail.color.getHexString()})`
                                          }}
                                      >
                                          {nodeDetail.iconURL && (
                                              <img alt={nodeDetail.originalData?.title || 'Name unknown'}
                                                   src={nodeDetail.iconURL}/>
                                          )}
                                      </div>
                                      <div className="flex flex-col">
                                          <div className={'text-xl font-semibold leading-5 [word-break:break-word]'}>
                                              <span
                                                  className={classNames('inline', symbolHasUrl(nodeDetail) ? 'cursor-pointer' : '', !nodeDetail.originalData!.title ? 'text-base-content-300' : '')}
                                                  onClick={() => {
                                                      if (symbolHasUrl(nodeDetail)) {
                                                          window.open(nodeDetail.originalData!.url, '_blank');
                                                      }
                                                  }}
                                              >{
                                                  nodeDetail.originalData!.title ? nodeDetail.originalData!.title : 'Name unknown'}
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                                  {symbolHasUrl(nodeDetail) && (
                                      <Tooltip message={"Open URL"} position={'top'}>
                                          <button
                                              tabIndex={-1}
                                              type="button"
                                              className={'cursor-pointer'}
                                              onClick={() => {
                                                  window.open(nodeDetail.originalData!.url, '_blank');
                                              }}
                                          >
                                            <OpenUrl></OpenUrl>
                                          </button>
                                      </Tooltip>
                                  )}
                                  <button
                                      tabIndex={-1}
                                      type="button"
                                      className="ui-btn-icon-close"
                                      onClick={closeDetail}
                                  >
                                      <Close/>
                                  </button>
                              </div>
                              <div>
                                  <Breadcrumb items={getBreadcrumbItems()}></Breadcrumb>
                              </div>
                              <div>
                                  <div className="flex items-start gap-1">
                                      {nodeDetail.originalData?.type && (
                                          <Pill
                                              color={nodeDetail.color.getStyle()}>{nodeDetail.originalData?.type}</Pill>
                                      )}
                                      {/*<PlusPill/>*/}
                                  </div>
                              </div>
                              {currentView === 'node' && !!nodeDetail.virtualLinks?.length && (
                                  <button type="button"
                                          className={'flex flex-row p-3 px-4 gap-x-4 bg-primary-100 text-primary-content-100 rounded-btn items-center justify-center'}
                                          onClick={() => {
                                              showUsages(nodeDetail)
                                          }}
                                  >
                                      <div>
                                          <Folders/>
                                      </div>
                                      <div className={'max-w-44 text-left'}>
                                          This object is part of multiple folders.
                                      </div>
                                      <div className={'font-bold ml-auto'}>
                                          Explore
                                      </div>
                                  </button>
                              )}
                              {/*<div className={'flex flex-col w-full gap-2'}>
                                  <div className={'text-lg'}>Description</div>
                                  <div className={'text-base-content-500'}>
                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                      Suspendisse porttitor neque et risus posuere pharetra.
                                      Mauris congue diam vel lorem ornare tincidunt.
                                      Nulla nec bibendum augue.
                                  </div>
                              </div>*/}
                          </div>
                          <div className="relative px-sm overflow-hidden overflow-y-auto w-full transition-[height] duration-200" style={{
                              height: `${innerContainerHeight}px`,
                          }}>
                              <Transition show={currentView === 'node'}>
                                  <div
                                      ref={nodeContainerRef}
                                      className="absolute p-[inherit] left-0 right-0 flex gap-6 flex-col justify-center items-start w-full transition duration-200 ease-in data-[closed]:opacity-0"
                                  >
                                      <div className={'flex flex-row w-full gap-2 justify-between items-center'}>
                                          {
                                              (
                                                  typeof (nodeDetail as MakeScenarioSymbol)?.originalData!.attributes.active !== 'undefined' ||
                                                  typeof (nodeDetail as WebhookSymbol)?.originalData!.attributes.enabled !== 'undefined'
                                              ) && (
                                                  <Badge
                                                      title={isSymbolActive(nodeDetail) ? 'Active' : 'Inactive'}
                                                      backgroundColor={isSymbolActive(nodeDetail) ? nodeDetail.color.getStyle() : undefined}
                                                      className={!isSymbolActive(nodeDetail) ? 'bg-base-content-100 text-base-content-300' : ''}
                                                      showBorder={false}
                                                      tooltip={'Status'}
                                                  >
                                                      {isSymbolActive(nodeDetail) ?
                                                          <CheckIcon color={'white'}/> :
                                                          <XMarkIcon/>
                                                          /*<XCircleIcon className={'text-error'}/>*/
                                                      }
                                                  </Badge>
                                              )}
                                          {typeof (nodeDetail as MakeScenarioSymbol)?.instant !== 'undefined' && (
                                              <Badge
                                                  title={(nodeDetail as MakeScenarioSymbol).originalData!.attributes.instant ? 'Instant' : 'Scheduled'}
                                                  showBorder={true}
                                                  tooltip={'Scheduling'}
                                              >
                                                  <i className={`fa-regular fa-lg fa-${(nodeDetail as MakeScenarioSymbol).originalData!.attributes.instant ? 'bolt' : 'clock'}`}></i>
                                              </Badge>
                                          )}
                                          {AppEnv.getMode() === 'development' && (
                                              <Badge
                                                  title={'Data'}
                                                  showBorder={true}
                                                  className={'cursor-pointer'}
                                                  onClick={() => {
                                                      setShowRaw(!showRaw)
                                                  }}
                                              >
                                                  <CodeBracketSquareIcon/>
                                              </Badge>
                                          )}
                                      </div>
                                      <pre
                                          className={classNames(showRaw ? 'p-2 max-h-60' : 'opacity-0 max-h-0 hidden', 'rounded-box bg-base-50 max-w-full overflow-auto text-xs transition-all duration-200 ease-in-out')}
                                      >
                                            {JSON.stringify(nodeDetail.originalData, null, 4)}
                                      </pre>
                                      {(nodeDetail as MakeScenarioSymbol).originalData?.attributes?.apps?.length && (
                                          <div className={'flex flex-col w-full gap-2'}>
                                              <div className={'text-lg'}>Apps used</div>
                                              <AppsCircles allowTooltip={true}
                                                           apps={(nodeDetail as MakeScenarioSymbol).originalData!.attributes!.apps!}/>
                                          </div>
                                      )}
                                      {/* Node links */}
                                      {nodeDetail?.links.length > 0 && (
                                          <NodeLinks nodeDetail={nodeDetail} limit={3} onShowAll={onShowAllLinks} />
                                      )}
                                      {/* Node properties */}
                                      <div className={'flex flex-col w-full gap-2'}>
                                          <div className={'text-lg'}>Properties</div>
                                          <div className={'flex flex-col w-full gap-1'}>
                                              {nodeDetail instanceof BaseSymbol && (
                                                  nodeDetail.detailDisplayProps.map((displayProp) => {

                                                      const value = _.get(nodeDetail.originalData, displayProp.key);
                                                      if (typeof value === 'undefined' || value === null) {
                                                          return;
                                                      }
                                                      return (
                                                          <div
                                                              key={displayProp.key}
                                                              className={'flex justify-between'}
                                                          >
                                                              <div
                                                                  className={'text-base-content-500 pr-2 min-w-20'}>{displayProp.label}:
                                                              </div>
                                                              <div
                                                                  className={'[word-break:break-word] truncate text-right overflow-clip'}>
                                                                  <Pill className={'!py-0.5'}>
                                                                    <span
                                                                        className={'text-xs truncate hover:whitespace-normal hover:text-clip'}>{value}</span>
                                                                  </Pill>
                                                              </div>
                                                          </div>
                                                      )
                                                  })
                                              )}
                                          </div>
                                      </div>
                                      {/*<div className={'flex flex-col w-full gap-1'}>
                                          <div className={'flex justify-between'}>
                                              <div className={'pr-2 text-base-content-200'}>
                                                  <MouseLeft/>
                                              </div>
                                              <div
                                                  className={'[word-break:break-word] text-base-content-100 text-right overflow-clip'}>
                                                  Select
                                              </div>
                                          </div>
                                          <div className={'flex justify-between'}>
                                              <div className={'pr-2 text-base-content-100 whitespace-nowrap'}>
                                                  {isMac() ? (<CommandMacKey/>) : (<CtrlKey/>)} + <MouseLeft/>
                                              </div>
                                              <div
                                                  className={'[word-break:break-word] text-base-content-100 text-right overflow-clip'}>
                                                  Extend Selection
                                              </div>
                                          </div>
                                          <div className={'flex justify-between'}>
                                              <div className={'pr-2 text-base-content-100 whitespace-nowrap'}>
                                                  <Escape/>
                                              </div>
                                              <div
                                                  className={'[word-break:break-word] text-base-content-100 text-right overflow-clip'}>
                                                  Exit View
                                              </div>
                                          </div>
                                      </div>*/}
                                  </div>
                              </Transition>
                              <Transition show={currentView === 'links'}>
                                  <div
                                      ref={linksContainerRef}
                                      className="absolute p-[inherit] left-0 right-0 w-full transition duration-200 ease-in data-[closed]:opacity-0"
                                  >
                                      {/* Node links */}
                                      {nodeDetail?.links.length > 0 && (
                                          <div className={'flex flex-col w-full gap-6'}>
                                              <NodeLinks
                                                  nodeDetail={nodeDetail}
                                                  showSearch={true}
                                                  limit={0}
                                                  showCount={true}
                                                  showFiltered={true}
                                                  limitFiltered={5}
                                              />
                                          </div>
                                      )}
                                  </div>
                              </Transition>
                          </div>
                      </Panel>
                  </>
              )}
          </div>
      </Transition>
  )
}