import {useEffect, useRef} from 'react';
import { useLazyEffect } from '../utils/hooks.ts';
import { useAppStore } from '../store/Store';
import { LoadingNotifications, NotificationType } from '../store/Notification.ts';
import FilterManager from '../three/utils/filter/FilterManager.ts';
import { useSearchParams } from 'react-router-dom';
import { decodeFiltersFromQuery, prepareQuery } from '../utils/url-query-parser.ts';
import { FEATURES } from '../store/Features.ts';
import { Logger } from '../utils/Logger';

import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";



// TODO: Temporary place to install symbols
import "../three/symbols/types/BrokenSymbol.ts";
import "../three/symbols/types/SharedSymbol.ts";
import "../three/symbols/types/airtable/AirtableBaseSymbol.ts";
import "../three/symbols/types/airtable/AirtableTableSymbol.ts";
import "../three/symbols/types/clickup/ClickUpListSymbol.ts";
import "../three/symbols/types/email/EmailMailboxSymbol.ts";
import "../three/symbols/types/freshdesk/FreshdeskAgentsSymbol.ts";
import "../three/symbols/types/freshdesk/FreshdeskCannedResponsesSymbol.ts";
import "../three/symbols/types/freshdesk/FreshdeskCompaniesSymbol.ts";
import "../three/symbols/types/freshdesk/FreshdeskContactsSymbol.ts";
import "../three/symbols/types/freshdesk/FreshdeskGroupsSymbol.ts";
import "../three/symbols/types/freshdesk/FreshdeskTicketsSymbol.ts";
import "../three/symbols/types/google/GoogleCalendarSymbol.ts";
import "../three/symbols/types/google/GoogleFileSymbol.ts";
import "../three/symbols/types/google/GoogleFolderSymbol.ts";
import "../three/symbols/types/google/GoogleSpreadsheetSymbol.ts";
import "../three/symbols/types/hubspot/HubSpotCallsSymbol.ts";
import "../three/symbols/types/hubspot/HubSpotCompaniesSymbol.ts";
import "../three/symbols/types/hubspot/HubSpotContactsSymbol.ts";
import "../three/symbols/types/hubspot/HubSpotCustomObjectsSymbol.ts";
import "../three/symbols/types/hubspot/HubSpotDealsSymbol.ts";
import "../three/symbols/types/hubspot/HubSpotProductsSymbol.ts";
import "../three/symbols/types/hubspot/HubSpotTicketsSymbol.ts";
import "../three/symbols/types/jira/JiraProjectSymbol.ts";
import "../three/symbols/types/jotform/JotformFormSymbol.ts";
import "../three/symbols/types/make/MakeDatastoreSymbol.ts";
import "../three/symbols/types/make/MakeModuleSymbol.ts";
import "../three/symbols/types/make/MakeScenarioSymbol.ts";
import "../three/symbols/types/make/MakeWebhookSymbol.ts";
import "../three/symbols/types/monday/MondayBoardSymbol.ts";
import "../three/symbols/types/mysql/MySQLTableSymbol.ts";
import "../three/symbols/types/salesforce/SalesforceRecordsSymbol.ts";
import "../three/symbols/types/slack/SlackChannelSymbol.ts";
import "../three/symbols/types/tally/TallyFormSymbol.ts";
import "../three/symbols/types/webhook/WebhookSymbol.ts";

// TODO: Temporary place to install links
import "../three/links/types/BrokenLink.ts";
import "../three/links/types/Link.ts";
import "../three/links/types/CreateLink.ts";
import "../three/links/types/DeleteLink.ts";
import "../three/links/types/InvokeLink.ts";
import "../three/links/types/ListenLink.ts";
import "../three/links/types/ReadLink.ts";
import "../three/links/types/StreamLink.ts";
import "../three/links/types/UpdateLink.ts";
import "../three/links/types/SharedLink.ts";
import "../three/links/types/VirtualLink.ts";

import { Mesh } from '../three/Mesh.ts';

import UseFiltersState from './utils/filters/FiltersState.ts';
import { CosmosThree } from '../three/CosmosThree.ts';
import { SysInfoResolver } from '@/three/utils/misc/SysInfo.ts';

function Cosmos({spaceId}: {spaceId?: string}) {

	const ref = useRef<HTMLDivElement>(null);
	const cosmosInstance = useRef<CosmosThree | null>(null);
	const [searchParams] = useSearchParams();

	const { activeFilters } = UseFiltersState();

	const {
		cosmosStoreData,
		setCosmosReady,
		userSettings,
		addNotification,
		removeNotification,
		currentMeshInstance,
		setCurrentMeshInstance,
		activeSpace,
		setFilterList,
		features,
		filtersFromUrl,
		setFiltersFromUrl,
		setUserSetting,
		closeSettings,
		activeTheme
	} = useAppStore((state) => {
		return {
			cosmosStoreData: state.cosmos,
			setCosmosReady: state.setCosmosReady,
			userSettings: state.userSettings,
			addNotification: state.addNotification,
			removeNotification: state.removeNotification,
			currentMeshInstance: state.currentMeshInstance,
			setCurrentMeshInstance: state.setCurrentMeshInstance,
			activeSpace: state.activeSpace,
			setFilterList: state.setFilterList,
			features: state.features,
			filtersFromUrl: state.filtersFromUrl,
			setFiltersFromUrl: state.setFiltersFromUrl,
			setUserSetting: state.setUserSetting,
			closeSettings: state.closeSettings,
			activeTheme: state.activeTheme
		}
	});

	const loadMesh = async (spaceId: string, cosmosThree: CosmosThree, ) => {
		if (currentMeshInstance) {
			await currentMeshInstance.hideAll();

			cosmosThree.graphScene.remove(currentMeshInstance);
			currentMeshInstance.dispose();
		}

		// activeFilter listening hooks are now properly cached, if you reload mesh with same filters, it would not trigger layout at all
		setFilterList(null);

		const mesh = new Mesh(spaceId, cosmosThree);
		mesh.setTheme(activeTheme.id as "cosmosLight" | "cosmosDark", false);
		mesh.setCamera(userSettings.cameraMode, false);
		cosmosThree.graphScene.attach(mesh);

		mesh.loadingStarted.add(() => {
			addNotification(NotificationType.LOADING, 'Loading Space', '', LoadingNotifications.MESH_LOADING);
		});

		mesh.loadingEnded.add(() => {
			removeNotification(LoadingNotifications.MESH_LOADING);
			if (!mesh.loaded) {
				return;
			}
			addNotification(NotificationType.SUCCESS, 'Space loaded');

			// The symbols are loaded and instantiated so we can generate the filter list
			// This will trigger the useEffect() with 'activeFilters' dependency below
			setFilterList(FilterManager.collectFiltersFromNodes((mesh.symbols), filtersFromUrl));
		});

		mesh.layoutStarted.add(() => {
			addNotification(NotificationType.LOADING, 'Performing layout', '', LoadingNotifications.MESH_LAYOUTING);
		});

		mesh.layoutEnded.add(() => {
			removeNotification(LoadingNotifications.MESH_LAYOUTING);
			if (!mesh.loaded) {
				return;
			}
			addNotification(NotificationType.SUCCESS, 'Layout completed');
		});

		setCurrentMeshInstance(mesh);

		try {
			await mesh.load();
		} catch (e) {
			addNotification(NotificationType.ERROR, 'Failed to load Space')
			Logger.error(e);
		}
	}

	useEffect(() => {
		// listen for active theme change, you can get current colors and vars from activeTheme.variables
		// colors are store as rgb values separated by comma "255, 255, 255" (because of tailwind config <alpha value> compatibility)
		Logger.log('theme changed', activeTheme);
		currentMeshInstance?.setTheme(activeTheme.id as "cosmosLight" | "cosmosDark");
	}, [activeTheme.id]);

	useLazyEffect(() => {
		currentMeshInstance?.layout();
	}, [
		features[FEATURES.LAYOUT_COMPACTNESS_FACTOR],
		features[FEATURES.LAYOUT_GROUP_COMPACTNESS_FACTOR],
		features[FEATURES.LAYOUT_QUALITY_TIME_RATIO],
		features[FEATURES.LAYOUT_GROUP_SUBSTRUCTURE_SCOPE],
	], 300);


	useEffect(() => {
		if (currentMeshInstance && activeFilters) {
			const urlFiltersObject = activeFilters.reduce((acc: {[key: string]: string[]}, curr) => {
				const itemsIds = curr.items.map(i => i.id);
				if (itemsIds.length) {
					acc[curr.id] = curr.items.map(i => i.id);
				}
				return acc;
			}, {});
			setFiltersFromUrl(urlFiltersObject);
			const query = prepareQuery(activeFilters);
			const currentUrl = window.location.href;
			const url = new URL(currentUrl);
			window.history.replaceState({}, '', query.length ? `${url.pathname}?${query}` : url.pathname);
			currentMeshInstance.filterNodes(activeFilters);
		}
	}, [activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (currentMeshInstance) {
			setUserSetting('cameraMode', userSettings.cameraMode);
			currentMeshInstance.setCamera(userSettings.cameraMode);
		}
	}, [userSettings.cameraMode]); // eslint-disable-line react-hooks/exhaustive-deps

	useLazyEffect(() => {
		if (cosmosInstance.current && cosmosStoreData.ready) {
			if (activeSpace) {
				loadMesh(activeSpace.id, cosmosInstance.current);
			} else {
				cosmosInstance.current?.graphScene.clear();
			}
			closeSettings();
		}
	}, [cosmosStoreData, activeSpace?.id], 100)

	const handleClickOnCanvas = (/*event:  MouseEvent<HTMLElement>*/) => {
		closeSettings();
	};

	useEffect(() => {

		if(typeof Window !== 'undefined'){
			gsap.registerPlugin(MotionPathPlugin);
		}

		// load filters from URL on component init
		const filtersFromQuery = decodeFiltersFromQuery(searchParams);

		if (Object.keys(filtersFromQuery).length) {
			setFiltersFromUrl(filtersFromQuery);
		}

		let cosmosThree: CosmosThree;

		const sysInfoResolver = new SysInfoResolver();
		sysInfoResolver.getSysInfo(() => {
			cosmosThree = new CosmosThree(ref.current!, sysInfoResolver.systemInfo);
			cosmosThree.setCameraMode(userSettings.cameraMode, false);
			cosmosInstance.current = cosmosThree;

			if (spaceId) {
				loadMesh(spaceId, cosmosThree);
			}

			setCosmosReady(true);
		}, 3000); // Sets a timeout of 3 seconds in case the benchmark fetching from the internet stalls

		return () => {
			Logger.log("------------ CLEANUP ----------", );
			setCosmosReady(false);
			setCurrentMeshInstance(null);
			cosmosThree.destroy();
			cosmosInstance.current = null;
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className='fixed left-0 top-0 w-full h-full' ref={ref} onClick={handleClickOnCanvas}>
		</div>
	);
}

export default Cosmos;