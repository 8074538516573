import * as THREE from 'three';
import { BaseObject } from './BaseObject';

export class BaseInstancedMesh extends THREE.InstancedMesh{

    elems: BaseObject[] = [];

    instanceOpacity: THREE.InstancedBufferAttribute | null = null;
    instanceGreyscale: THREE.InstancedBufferAttribute | null = null;
    instanceDrawProgress: THREE.InstancedBufferAttribute | null = null;

    matrixNeedsUpdate = false;
	colorNeedsUpdate = false;
    opacityNeedsUpdate = false;
    greyscaleNeedsUpdate = false;
    drawProgressNeedsUpdate = false;

    protected mtx = new THREE.Matrix4();
    protected vector = new THREE.Vector3();
    protected transform = new THREE.Object3D();

    constructor (geometry : THREE.BufferGeometry, material : THREE.Material, count : number){
        super(geometry, material, count);

        // this.matrixAutoUpdate = false;
        // this.matrixWorldAutoUpdate = false;

        this.instanceOpacity = new THREE.InstancedBufferAttribute( new Float32Array( count ).fill(0.0), 1 ).setUsage(THREE.DynamicDrawUsage);
        this.instanceGreyscale = new THREE.InstancedBufferAttribute( new Float32Array( count ).fill(0.0), 1 ).setUsage(THREE.DynamicDrawUsage);
        this.instanceDrawProgress = new THREE.InstancedBufferAttribute( new Float32Array( count ).fill(0.0), 1 ).setUsage(THREE.DynamicDrawUsage);

        this.geometry.setAttribute('instOpacity', this.instanceOpacity);
        this.geometry.setAttribute('instGreyscale', this.instanceGreyscale);
        this.geometry.setAttribute('instDrawProgress', this.instanceDrawProgress);
    }

    sync(){
        if(this.matrixNeedsUpdate || this.colorNeedsUpdate || this.opacityNeedsUpdate || this.greyscaleNeedsUpdate){
            for(let i = 0 ; i < this.elems.length; i++){
                const elem = this.elems[i];
                if(elem.matrixNeedsUpdate){
                    this.syncMatrix(elem);
                }
                if(elem.colorNeedsUpdate){
                    this.syncColor(elem);
                }
                if(elem.opacityNeedsUpdate){
                    this.syncOpacity(elem);
                }
                if(elem.greyscaleNeedsUpdate){
                    this.syncGreyscale(elem);
                }
                if(elem.drawProgressNeedsUpdate){
                    this.syncDrawProgress(elem);
                }
            }

            this.computeBoundingSphere();
        }
    }

    syncMatrix(elem: BaseObject){
        elem.matrixNeedsUpdate = false;

        if(elem.three.parent){
            elem.three.updateMatrix();
            elem.three.parent.updateMatrix();
            this.setMatrixAt(elem.instanceId, this.mtx.multiplyMatrices(elem.three.parent.matrix, elem.three.matrix));
        }else{
            elem.three.updateMatrix();
            this.setMatrixAt(elem.instanceId, elem.three.matrix);
        }
        this.instanceMatrix.needsUpdate = true;
	}

    syncColor(elem: BaseObject){
        elem.colorNeedsUpdate = false;
        this.setColorAt(elem.instanceId, elem.color);
        this.instanceColor!.needsUpdate = true;
    }

    syncOpacity(elem: BaseObject){
        elem.opacityNeedsUpdate = false;
        this.setOpacityAt(elem.instanceId, elem.opacity);
        this.instanceOpacity!.needsUpdate = true;
    }

    setOpacityAt(instanceId: number, opacity: number){
        this.instanceOpacity!.array[instanceId] = opacity;
    }

    syncGreyscale(elem: BaseObject){
        elem.greyscaleNeedsUpdate = false;
        this.setGreyscaleAt(elem.instanceId, elem.greyscale);
        this.instanceGreyscale!.needsUpdate = true;
    }

    setGreyscaleAt(instanceId: number, greyscale: number){
        this.instanceGreyscale!.array[instanceId] = greyscale;
    }

    syncDrawProgress(elem: BaseObject){
        elem.drawProgressNeedsUpdate = false;
        this.setDrawProgressAt(elem.instanceId, elem.drawProgress);
        this.instanceDrawProgress!.needsUpdate = true;
    }

    setDrawProgressAt(instanceId: number, drawProgress: number){
        this.instanceDrawProgress!.array[instanceId] = drawProgress;
    }

    override dispose() : this{
        this.geometry.dispose();
		(this.material as THREE.Material).dispose();

        this.elems = [];

        return super.dispose();
    }

}