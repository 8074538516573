import * as THREE from 'three';

import * as signals from 'signals';

import { gsap } from "gsap";

import { Repository } from '../common/Repository';
import { BaseObject } from '../common/BaseObject';
import { GROUND_OFFSET, INTERACTION_ANIMATION_SPEED, PREVENT_SHADOW_LEAK, PREVENT_Z_FIGHTING, SYMBOL_SHARED_HEIGHT, SYMBOL_SHARED_Y } from '../common/constants';
import { FilterDefinition, FilterGroupDefinition } from '../../store/Filter';
import { TBaseLink, TBaseSymbol } from '../Mesh';
import { SerializedSymbol, SymbolDisplayableProp } from './BaseSymbol';

export abstract class BaseSharedSymbol extends BaseObject{

	readonly isSymbol = true;
	readonly isShared = true;

	protected _depth = 1;

	interactionLayer = 1;

	private _type = "";

	originalData: SerializedSymbol | null = null;
	groupId: string = "";

	readonly links: TBaseLink[] = [];
	readonly inputLinks: TBaseLink[] = [];
	readonly outputLinks: TBaseLink[] = [];

	private _visible = false;
	private _filtered = false;
	private _muted = false;
	private _spotlight = false;

	spotlightLevel = 0;

	linked: signals.Signal;
	unlinked: signals.Signal;

	private _hideTo: gsap.QuickToFunc;

	public detailBaseDisplayProps: SymbolDisplayableProp[] = [
		{
			key: 'type',
			label: 'Type',
		},
		{
			key: 'url',
			label: 'Url',
		}, {
			key: 'external_id',
			label: 'ID'
		}
	];

    constructor () {
		super();

		this.linked = new signals.Signal();
		this.linked.add(this.onLinked, this);

		this.unlinked = new signals.Signal();
		this.unlinked.add(this.onUnlinked, this);

		// Quick tween functions to boost performance even more
		this._hideTo = gsap.quickTo(this.three.position, "y", { duration: INTERACTION_ANIMATION_SPEED * 2, ease: "power2.inOut", onUpdate: () => { 
			this.matrixNeedsUpdate = true;
			for(let i = 0 ; i < this.links.length; i++){
				this.links[i].update();
			}
		} });
    }

	get detailDisplayProps() {
		return this.detailBaseDisplayProps;
	}

	get type() {
		return this._type;
	}

	set type(value) {
		if(value === this._type){return}
		this._type = value;
	}

	override get globalInstanceId(): number {
		return super.globalInstanceId;
	}
	override set globalInstanceId(value: number) {
		super.globalInstanceId = value;
	}

	override get matrixNeedsUpdate() {
		return this._matrixNeedsUpdate;
	}
	override set matrixNeedsUpdate(value) {
		this._matrixNeedsUpdate = value;
	}

	get visible() {
		return this._visible;
	}
	set visible(value) {
		if(this._visible === value) return;

		this._visible = value;

		if(!this.filtered){
			if(!this._visible){
				this.hideIn();
			}else{
				this.hideOut();
			}
		}
	}

	get filtered() {
		return this._filtered;
	}
	set filtered(value) {
		if(this._filtered === value) return;

		this._filtered = value;

		if(this._filtered){
			this.hideIn();
		}else if(this._visible){
			this.hideOut();
		}
	}

	get muted() {
		return this._muted;
	}

	set muted(value) {
		if(this._filtered || !this.visible){ return }
		if(this._muted === value) return;

		this._muted = value;
	}

	get spotlight() {
		return this._spotlight;
	}
	set spotlight(value) {
		if(this._filtered || !this.visible){ return }

		this._spotlight = value;
	}

	get neighbors(): TBaseSymbol[] {
		// Iterate over all links and find the neighboring symbols.
		return this.links.map(link => link.target === this ? link.source! : link.target!).filter(symbol => !!symbol);
	}

	onLinked(type: string, link: TBaseLink){
		if(type === "input"){
			this.inputLinks.push(link);
		} else if(type === "output"){
			this.outputLinks.push(link);
		}

		this.links.push(link);
	}

	onUnlinked(type: string, link: TBaseLink){
		if(type === "input"){
			this.inputLinks.splice(this.inputLinks.indexOf(link));
		} else if(type === "output"){
			this.outputLinks.splice(this.outputLinks.indexOf(link));
		}

		this.links.splice(this.links.indexOf(link));
	}

	hide(){
		this.three.position.y = -(SYMBOL_SHARED_HEIGHT + GROUND_OFFSET + PREVENT_Z_FIGHTING + PREVENT_SHADOW_LEAK);
		this.matrixNeedsUpdate = true;
	}

	hideIn(){
		this._hideTo(-(SYMBOL_SHARED_HEIGHT + GROUND_OFFSET + PREVENT_Z_FIGHTING + PREVENT_SHADOW_LEAK));
	}

	hideOut(){
		this._hideTo(SYMBOL_SHARED_Y);
	}

    toJSON(): SerializedSymbol {
		return {
			id: this.id,
			external_id: this.originalData!.external_id,
			type: Repository.typeOf(this),
			theme: this.color.getHexString(),
			attributes: {},
            isAutomation: this.originalData!.isAutomation
		}
	}

	fromJSON(object: SerializedSymbol) {
		this.originalData = object;
		if (object.id) this.id = object.id;
		this.color = new THREE.Color(object.theme);
	}

	static getFilterGroupDefinition(): FilterGroupDefinition[] {
		return [];
	}

	static getFilterDefinition(): FilterDefinition[] {
		return [];
	}

	override dispose(){
		this.linked.removeAll();
		this.unlinked.removeAll();

		super.dispose();
	}

}