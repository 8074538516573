import * as THREE from 'three';

import { BaseInstancedMesh } from "../common/BaseInstancedMesh";
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionAtlas } from '../utils/materials/extensions/ExtensionAtlas';
import { AppsCountTextureGenerator } from '../utils/three/AppsCountTextureGenerator';
import { SymbolAppsCount } from './SymbolAppsCount';

export class SymbolsAppsCountIM extends BaseInstancedMesh{

	textureGenerator: AppsCountTextureGenerator;
    texturePositions: THREE.InstancedBufferAttribute;

	textNeedsUpdate = false;

    constructor(size : number){

        const appsCount = new SymbolAppsCount();

		const textureGenerator = new AppsCountTextureGenerator();

        const geometry = appsCount.getGeometry();
        
        const texturePositions = new THREE.InstancedBufferAttribute(new Float32Array(size * 2), 2, true);

		geometry.setAttribute('texOffset', texturePositions);

		const material = new (ExtendedMaterial as any)(
            THREE.MeshBasicMaterial,
            [ExtensionAtlas],
            {
                color: 0xffffff,
                map: textureGenerator.texture,
                atlasSize: new THREE.Vector2(textureGenerator.width, textureGenerator.height),
                texSize: new THREE.Vector2(textureGenerator.appsCountWidth, textureGenerator.appsCountHeight),
                transparent: true,
                //wireframe: true
            },
            { debug: false }
        );

        super(geometry, material, size);

		this.textureGenerator = textureGenerator;
        this.texturePositions = texturePositions;

		this.name = "appsCountInstancedMesh";

        appsCount.dispose();
    }

	override sync (){
        super.sync();

		if(this.textNeedsUpdate){
            for(let i = 0 ; i < this.elems.length; i++){
                const elem = this.elems[i] as SymbolAppsCount;
                if(elem.countNeedsUpdate){
                    this.syncText(elem);
                }
            }
        }
	}

	syncText(elem: SymbolAppsCount){
        elem.countNeedsUpdate = false;
		const appsCountImageData = this.textureGenerator.appsCountsPositions.get(elem.instanceId + "");
        if(appsCountImageData){
			this.setTextAt(elem.instanceId, appsCountImageData.x, appsCountImageData.y);
			this.texturePositions.needsUpdate = true;
		}
    }

	setTextAt(instanceId: number, x: number, y: number){
        this.texturePositions.array [ instanceId * 2 ] = x;
        this.texturePositions.array [ instanceId * 2 + 1 ] = y;
    }

	override dispose(): this{
		this.textureGenerator.dispose();

		return super.dispose();
	}
}