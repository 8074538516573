import { BaseObject } from '../common/BaseObject';
import { SymbolsLegendsIM } from './SymbolsLegendsIM';

export class SymbolLegend extends BaseObject{

    private _title = "";

    private _textNeedsUpdate = false;

    constructor(){
        super();
    }

    get title (){
        return this._title || "";
    }

    set title (value: string){
        if(this._title === value) return;
        this._title = value || "";

        (this.instancedOrBatchedMesh as SymbolsLegendsIM).textureGenerator.createLegendAt(this.instanceId, this._title);

        this.textNeedsUpdate = true;
    }

    get textNeedsUpdate() {
        return this._textNeedsUpdate;
    }
    set textNeedsUpdate(value) {
        if(this._textNeedsUpdate === value) return;
        this._textNeedsUpdate = value;
        if(this._instancedOrBatchedMesh){
			(this._instancedOrBatchedMesh as SymbolsLegendsIM).textNeedsUpdate = value;
		}
    }
}