import type { StateCreator } from 'zustand'
import { LocalStorageManager } from '../utils/LocalStorageManager.ts';

export type User = {
    name: string;
    email: string;
    avatar?: string;
}

export type CameraMode = '2d' | '3d';

export interface UserSettings {
    'cameraMode': CameraMode,
}

const defaultUserSettings: UserSettings = {
    cameraMode: '3d',
}

export interface UserSlice {
    user: User | undefined;
    userSettings: UserSettings;
    setUser(user: User | undefined): void;
    setCameraMode: (mode: CameraMode) => void;
    setUserSetting(key: keyof UserSettings, value: unknown): void;
}

export const createUserSlice: StateCreator<UserSlice> = (set, get) => ({
    user: undefined,
    userSettings: LocalStorageManager.getValues<UserSettings>('userSettings', defaultUserSettings),
    setUser: (user) => set(() => (
      { user })
    ),
    setCameraMode: (mode: CameraMode) => {
        get().setUserSetting('cameraMode', mode);
    },
    setUserSetting: (key, value) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        set((state) => (
            { userSettings: {...state.userSettings, [key]: value} }
        ));
        LocalStorageManager.setValue('userSettings', key, value)
    }
})
