import type { FormItem } from '../../types/form.ts';
import type { FormGeneratorData } from './FormGenerator.tsx';

import {useState} from 'react';

function Text({ parameters, data }: { parameters: FormItem, data?: FormGeneratorData }) {

  const  [value, setValue] = useState(data?.[parameters.name] ?? '');

  return (
    <div>
      <label htmlFor={parameters.name} className="block font-medium leading-6">
        {parameters.label}
      </label>
      <div className="mt-2">
        <input
          id={parameters.name}
          type={parameters.type}
          name={parameters.name}
          required={parameters.required}
          className="ui-input"
          placeholder={parameters.placeholder}
          onChange={(e) => setValue(e.target.value)}
          value={value as string}
        />
      </div>
    </div>
  )
}

export default Text;