import { BaseInstancedMesh } from "../common/BaseInstancedMesh";

export class SymbolsShapesIM extends BaseInstancedMesh{
    constructor(symbolClass: any, size : number){
        const symbol = new symbolClass();

        super(symbol.getGeometry(), symbol.getMaterial(), size);

        this.layers.enable( symbol.interactionLayer );

        this.castShadow = symbol.shape.three.castShadow;

        symbol.dispose();

        this.name = "symbolShapesIM";
    }
}