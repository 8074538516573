import { useAppStore } from '../store/Store.ts'

function CosmosControls() {

  const { activeSpace, /* currentMeshInstance */ } = useAppStore((state) => {
    return {
      activeSpace: state.activeSpace,
      currentMeshInstance: state.currentMeshInstance,
    }
  });

  return (
    <div className='fixed bottom-6 left-6 flex gap-4 items-center z-30 ui-pointer-events'>
      {activeSpace ? (
        <>
          { /*<AddSymbol onSymbolClick={addSymbol} spaceId={activeSpace.id}/>*/ }
        </>
      ): null}
    </div>
  )
}

export default CosmosControls;
