import type { FormItem, FormGroup } from '../../types/form.ts'
import type { Primitive } from '../../types/common.ts';

import Text from './Text.tsx'
import Number from './Number.tsx'
import Select from './Select.tsx';
import Checkbox from './Checkbox.tsx';
import Slider from './Slider.tsx';
import {classNames} from '../../utils/helpers.ts';
import {ChevronRightIcon} from '@heroicons/react/20/solid';
import {useState} from 'react';

export function parseFormData(formData: FormData, formConfig: FormItem[]) {
  const parameters: Record<string, unknown> = {};
  for (const parameter of formConfig) {
    switch (parameter.type) {
      case 'number':
        parameters[parameter.name] = parseInt(formData.get(parameter.name) as string);
        break;
      default:
        parameters[parameter.name] = formData.get(parameter.name) as string;
        break;
    }
  }
  return parameters;
}

export type FormGeneratorOnChange = (inputName: string, value: string | boolean | number) => void;

export type FormGeneratorData = {
  [key: string]: Primitive | Primitive[]
}

function FormGenerator({ items, groups, data, onInputChange }: { items?: FormItem[], groups?: FormGroup[], data?: FormGeneratorData, onInputChange?: FormGeneratorOnChange }) {

  const onItemChange: FormGeneratorOnChange = (inputName, value) => {
    if (onInputChange) {
      onInputChange(inputName, value);
    }
  }

  const [openGroups, setOpenGroups] = useState<string[]>([]);

  const isGroupOpen = (group: FormGroup) => {
    return group.open || openGroups.includes(group.id);
  }

  const toggleGroup = (group: FormGroup) => {
    if (openGroups.includes(group.id)) {
      setOpenGroups(openGroups.filter(item => item !== group.id));
    } else {
      setOpenGroups([...openGroups, group.id]);
    }
  }

  const renderItems = (items: FormItem[]) => {
    return (
        items.map((item) => {
          switch (item.type) {
            case 'text':
              return <Text parameters={item} data={data} key={item.name} />
            case 'number':
              return <Number parameters={item} data={data} key={item.name} onChange={onItemChange} />
            case 'select':
              return <Select parameters={item} data={data} key={item.name} onChange={onItemChange} />
            case 'checkbox':
              return <Checkbox parameters={item} data={data} key={item.name} onChange={onItemChange} />
            case 'slider':
              return <Slider parameters={item} data={data} key={item.name} onChange={onItemChange} />
            default:
              return null
          }
        })
    )
  }

  return (
    <div className="flex flex-col gap-4">
      {items?.length && renderItems(items)}
      {groups?.length && groups.map((group) => (
          <div key={group.id}>
            <button
                onClick={() => { toggleGroup(group) }}
                className={classNames(
                    isGroupOpen(group) ? 'bg-base-300 rounded-t-md' : 'hover:bg-base-200 rounded-md',
                    'flex items-center w-full text-left p-2 py-1 gap-x-2 leading-6 font-semibold'
                )}
            >
              <ChevronRightIcon
                  className={classNames(
                      isGroupOpen(group) ? 'rotate-90' : '',
                      'h-5 w-5 shrink-0'
                  )}
                  aria-hidden="true"
              />
              {group.label}
            </button>
            {isGroupOpen(group) && (
                <div className="theme-transition flex flex-col gap-4 p-2.5 bg-base-50 rounded-b-md min-h-48 overflow-visible">
                  {renderItems(group.items)}
                </div>
            )}
          </div>
      ))}
    </div>
  )
}

export default FormGenerator;