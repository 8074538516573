
export type LinkDirection = 'fromNodeDetail' | 'toNodeDetail' | 'both';

export type LinkType = {
    id: string;
    label: string;
    bgColor: string;
    contentColor: string;
    direction?: LinkDirection;
}

export const triggerLink: LinkType = {
    id: 'trigger',
    label: 'Trigger',
    bgColor: 'bg-trigger',
    contentColor: 'text-trigger-content'
}

export const readLink: LinkType = {
    id: 'read',
    label: 'Read',
    bgColor: 'bg-success',
    contentColor: 'text-success-content'
}

export const writeLink: LinkType = {
    id: 'write',
    label: 'Write',
    bgColor: 'bg-info',
    contentColor: 'text-info-content'
}

export const linkTypeMap: {[key:string]: LinkType} = {
    'system:listen': triggerLink,
    'system:invoke': triggerLink,
    'system:read': readLink,
    'system:update': writeLink,
    'system:base': readLink,
    'system:arrow': triggerLink,
    'system:create': writeLink,
    'system:delete': writeLink,
    'system:stream': writeLink
}