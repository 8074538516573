type Props = {
    className?: string,
}

export const ArrowDown = ({className}: Props) => {
    return (
        <svg className={'w-3 h-3 ' + className || ''} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" fill="none">
            <path d="M6.00024 1.79999L6.00024 10.2" stroke="currentColor" strokeWidth="1.75" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M3.00024 7.19998L6.00024 10.2L9.00024 7.19998" stroke="currentColor" strokeWidth="1.75"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

