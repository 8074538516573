import supabase from '../utils/supabase-client';

import signals from 'signals';

import * as THREE from 'three';

import { gsap } from "gsap";

import _ from 'lodash';

import { Repository } from './common/Repository.ts';

import { SerializedSymbol, BaseSymbol} from './symbols/BaseSymbol';
import type { SerializedLink, BaseLink } from './links/BaseLink';

import {LayoutManager} from './utils/layout/LayoutManager';

import{ CosmosThree } from './CosmosThree';
import { BaseObject } from './common/BaseObject.ts';
import { CAMERA_FIT_HEIGHT_MARGIN, CAMERA_FIT_WIDTH_MARGIN, GRID_SHORT_SUBDIV, INTERACTION_ANIMATION_SPEED, INTERACTION_SPOTLIGHT_DELAY, INTERACTION_SPOTLIGHT_STAGGERING, SCALE_FACTOR, SYMBOL_MAKE_SCENARIO_SIZE, SYMBOL_SIZE } from './common/constants.ts';
import { ActiveFilters, FilterItem } from '../store/Filter';
import { FilterManager, FilterResult } from './utils/filter/FilterManager';
import { useAppStore } from '../store/Store';
import { SearchManager } from './utils/search/SearchManager.ts';
import { getElementsToSpotlight, getUnmatchedSymbolsAndLinks } from './utils/graph/GraphUtils.ts';
import CameraControls from 'camera-controls';
import { Logger } from '../utils/Logger';
import { BaseGroup } from './groups/BaseGroup.ts';
import { SelectionIndicator } from './common/SelectionIndicator.ts';
import { SymbolFullLegend } from './symbols/SymbolFullLegend.ts';
import { BaseInstancedMesh } from './common/BaseInstancedMesh.ts';
import { BaseBatchedMesh } from './common/BaseBatchedMesh.ts';
import { attachSymbolsToGroups } from './utils/grouping/GroupUtils.ts';
import { BaseSharedSymbol } from './symbols/BaseSharedSymbol.ts';
import { BaseSharedLink } from './links/BaseSharedLink.ts';
import { BaseVirtualLink } from './links/BaseVirtualLink.ts';
import { findCentroid } from './utils/utils.ts';

export interface SerializedMesh {
	serializedSymbols: SerializedSymbol[];
	serializedLinks: SerializedLink[];
}

export type TBaseSymbol = BaseSymbol | BaseSharedSymbol;
export type TBaseLink = BaseLink | BaseSharedLink;

export class Mesh extends THREE.Group{

    spaceID: string;
	cosmos: CosmosThree;

	layoutAfterLoad = true;
	pendingCameraReset = false;
	loaded = false;

	symbols: BaseSymbol[] = [];
	links: BaseLink[] = [];
	groups: BaseGroup[] = [];

	virtualLinks:BaseVirtualLink[] = [];

	sharedSymbols: BaseSharedSymbol[] = [];
	sharedLinks:BaseSharedLink[] = [];

	private _selected: BaseSymbol | null = null;
	private _multiSelected: BaseSymbol[] = [];

	cameraMode: "3d" | "2d" = "3d";
	activeTheme: "cosmosLight" | "cosmosDark" = "cosmosLight";

	bbox: THREE.Box3 = new THREE.Box3();

	selectionIndicator : SelectionIndicator;
	groupsTitlesCont : THREE.Group;
	fullLegend: SymbolFullLegend;

	showUsages = false;

	loadingStarted: signals.Signal;
	loadingEnded: signals.Signal;
	layoutStarted: signals.Signal;
	layoutEnded: signals.Signal;

	protected _filterResult: FilterResult = {
		symbols: [],
		links: [],
		activeFilterItems: []
	};
	
	private _activeFilterGroups: ActiveFilters = [];
	public filterIndex = '';

	protected _searchManager: SearchManager | null = null;
	protected _layoutManager: LayoutManager;

	private _spotlightDelayedCalls: gsap.core.Tween[] = [];
	private _interactionDelayedCall: gsap.core.Tween | null = null;
	private _fullLegendDelayedCall: gsap.core.Tween | null = null;
	private _baseLayoutBackgroundPromise: Promise<void> | null = null;

    constructor (spaceID: string, cosmos: CosmosThree) {
        super();

		Repository.mesh = this;

		this.name = "mesh";

        this.spaceID = spaceID;
		this.cosmos = cosmos;

		this.selectionIndicator = new SelectionIndicator();
		this.groupsTitlesCont = new THREE.Group();
		this.fullLegend = new SymbolFullLegend();

		this.loadingStarted = new signals.Signal();
		this.loadingEnded = new signals.Signal();
		this.layoutStarted = new signals.Signal();
		this.layoutEnded = new signals.Signal();

		this._layoutManager = new LayoutManager();

		this.cameraMode = cosmos.camMode;
    }

    async load() {
		this.disableInteraction();
		useAppStore.getState().disableUIInteractions();

		this.layoutAfterLoad = true;

		this.loadingStarted.dispatch();

		// load from backend
		Logger.time('[perf] mesh: load symbols and links');
		const { data, error } = await supabase.functions.invoke('symbols-links-processed', {
			body: JSON.stringify({ spaceId: this.spaceID })
		});
		Logger.timeEnd('[perf] mesh: load symbols and links');
		if (error) {
			useAppStore.getState().enableUIInteractions();
			this.loadingEnded.dispatch();
			throw error
		}
		if (!data.symbols.length) {
			useAppStore.getState().enableUIInteractions();
			this.loadingEnded.dispatch();
			return;
		}

		const serializedSymbols = data.symbols;
		const serializedLinks = data.links;
		const serializedSharedSymbols = data.sharedSymbols;
		const serializedSharedLinks = data.sharedLinks;

		Logger.time('[perf] mesh: create symbols types');
		// This will generate and store in the Repository the different symbol types and symbol group hierarchy definitions
		Repository.assignSymbolTypes(serializedSymbols);
		Logger.timeEnd('[perf] mesh: create symbols types');

		Logger.time('[perf] mesh: create shared symbols types');
		// This will generate and store in the Repository the different symbol types and symbol group hierarchy definitions
		Repository.assignSharedSymbolTypes(serializedSharedSymbols);
		Logger.timeEnd('[perf] mesh: create shared symbols types');

        Logger.time('[perf] mesh: instantiate objects');
		this.initObjectsFromJSON(serializedSymbols, serializedLinks, serializedSharedLinks);
		Logger.timeEnd('[perf] mesh: instantiate objects');

		this._searchManager = new SearchManager(serializedSymbols);

		this.loaded = true;
		this.loadingEnded.dispatch();

		localStorage.setItem(`space:${this.spaceID}:viewed`, new Date().toISOString());
    }

	initObjectsFromJSON(serializedSymbols: SerializedSymbol[], serializedLinks: SerializedLink[], serializedSharedLinks: SerializedLink[]): void {
		this.initSymbols(serializedSymbols);

		this.initLinks(serializedLinks, serializedSharedLinks);
		this.initGroups(serializedSymbols);
		this.initVirtualLinks();

		this.add(Repository.groupsShapesMesh!);
		Repository.groupsShapesMesh!.renderOrder = 1;
		this.add(this.groupsTitlesCont);
		this.groupsTitlesCont.renderOrder = 2;

		this.add(this.selectionIndicator);
		this.selectionIndicator.renderOrder = 3;

		this.add(Repository.linksShapesMesh!);
		Repository.linksShapesMesh!.renderOrder = 4;
		this.add(Repository.linksIndicatorsMesh!);
		Repository.linksIndicatorsMesh!.renderOrder = 5;

		Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) =>{
			this.add(symbolShapeMesh);
			symbolShapeMesh.renderOrder = 6;
		});

		this.add(Repository.symbolsAppsMesh!);
		Repository.symbolsAppsMesh!.renderOrder = 7;

		this.add(Repository.symbolsIconsMesh!);
		Repository.symbolsIconsMesh!.renderOrder = 8;

		this.add(Repository.symbolsAppsCountsMesh!);
		Repository.symbolsAppsCountsMesh!.renderOrder = 9;

		if (serializedSharedLinks.length) {
			this.add(Repository.virtualLinksShapesMesh!);
			Repository.virtualLinksShapesMesh!.renderOrder = 11;

			this.add(Repository.sharedLinksShapesMesh!);
			Repository.sharedLinksShapesMesh!.renderOrder = 12;
		}

		// We add the legends to the GUI scene so they are not affected by the grapScene Camera
		CosmosThree.globalGuiScene.add(Repository.symbolsLegendsMesh!);

		// We also forcefully render it so the texture is uploaded to the GPU and there is no 'freeze' when the legends mesh is made visible for the first time depending on camera zoom
		const prevVisibility = Repository.symbolsLegendsMesh!.visible;
		Repository.symbolsLegendsMesh!.visible = true;
		CosmosThree.globalRenderer.render(Repository.symbolsLegendsMesh!, CosmosThree.globalGuiCamera);
		Repository.symbolsLegendsMesh!.visible = prevVisibility;

		// Object that shows the full legend
		CosmosThree.globalGuiScene.add(this.fullLegend);
	}

	initSymbols(serializedSymbols: SerializedSymbol[]){
		const {symbols, sharedSymbols} = Repository.buildSymbols(serializedSymbols);
		this.symbols = symbols;
		this.sharedSymbols = sharedSymbols;

		for(let i = 0; i < this.symbols.length; i++){
			const symbol = this.symbols[i] as BaseSymbol;

			symbol.hovered.add(this.onSymbolHovered, this);
			symbol.unhovered.add(this.onSymbolUnhovered, this);

			symbol.clicked.add(this.onSymbolClicked, this);
			symbol.doubleclicked.add(this.onSymbolDoubleClicked, this);

			symbol.selected.add(this.onSymbolSelected, this);
			symbol.unselected.add(this.onSymbolUnselected, this);

			symbol.hide();
		}

		for(let i = 0; i < this.sharedSymbols.length; i++){
			const sharedSymbol = this.sharedSymbols[i] as BaseSharedSymbol;
			sharedSymbol.hide();
		}
	}

	initLinks(serializedLinks: SerializedLink[], serializedSharedLinks: SerializedLink[]){
		const {links, sharedLinks} = Repository.buildLinks(serializedLinks, serializedSharedLinks);
		this.links = links;
		this.sharedLinks = sharedLinks;

		for(let i = 0; i < this.links.length; i++){
			this.links[i].hide();
		}

		for(let i = 0; i < this.sharedLinks.length; i++){
			this.sharedLinks[i].hide();
		}
	}

	initGroups(serializedSymbols: SerializedSymbol[]){
		this.groups = Repository.buildGroups(serializedSymbols);

		attachSymbolsToGroups(this.symbols);

		for(let i = 0; i < this.groups.length; i++){
			this.groups[i].hide();
		}
	}

	initVirtualLinks(){
		Repository.buildVirtualLinks(this.symbols);
	}

	async layout(cacheOnly = false){
        if (!this.loaded) {
            return;
        }
        if (!cacheOnly) {
			// Dispatching this event will show the layout start toast notification
			this.layoutStarted.dispatch();

			// In case something is selected we unselect it
			this.unselectAll();

			// In case this is not the first layout after a load we hide everything
			if(!this.layoutAfterLoad){
				await this.hideAll();
			}else{
				this.layoutAfterLoad = false;
			}
		}

		// If a background layout is being computed we wait for it to finish before doing a new one
		if (this._baseLayoutBackgroundPromise) {
			await this._baseLayoutBackgroundPromise;
			this._baseLayoutBackgroundPromise = null;
		}

		// TODO: for some reason if the arrays are not sorted the resulting graph is different... is this how yFiles is supposed to work?
		const symbols: TBaseSymbol[] = _.sortBy(this._filterResult.symbols.length && !cacheOnly ? this._filterResult.symbols : this.symbols, ['id']);

        const links: TBaseLink[] = _.sortBy(this._filterResult.links.length && !cacheOnly ? this._filterResult.links : this.links, ['id']);

		// Do the layouting and wait for the result
		await this._layoutManager.layout(symbols, this.groups, links, cacheOnly);

		if (!cacheOnly) {
			// Dispatching this event will show the layout end toast notification
			this.layoutEnded.dispatch();

			// If it's the first layout done after a load with filters applied then we calculate the 'base' layout (with nothing filtered) on background (web worker)
			if (this._activeFilterGroups.length) {
				this._baseLayoutBackgroundPromise = this.layout(true);
			}
		}
	}

	updateSize(){
		this.updateBBox();
		this.center();
		this.updateWorldSize();
		this.updateCameraBounds();
	}

	/** 
	 * Updates the bounding box of the whole mesh.
	 */
	updateBBox(){
		Logger.time('[perf] mesh: calculate bounding box');

		// We need to sync the matrices instantly at least once before updating the mesh bbox, otherwise it would happen in the next frame and the bbox would be wrong
		this.cosmos.syncInstances();

		// Recompute the bounding boxes of all the children
		this.children.forEach(elem => {
			if(elem instanceof BaseInstancedMesh || elem instanceof BaseBatchedMesh){
				(elem as any).computeBoundingBox();
			}
		});

		this.bbox.setFromObject(this);
		
		Logger.timeEnd('[perf] mesh: calculate bounding box');
		if(CosmosThree.globalDebug){
			this.cosmos.meshBBoxHelper.box = this.bbox;
			this.cosmos.meshSphereHelper.geometry.dispose();
			this.cosmos.meshSphereHelper.geometry = new THREE.SphereGeometry( CameraControls.createBoundingSphere( this ).radius, 16, 16);
		}
	}

	center(){
		const vCenter = new THREE.Vector3();
		this.bbox.getSize(vCenter);

		vCenter.x = vCenter.x / 2;
		vCenter.z = vCenter.z / 2;

		vCenter.x = Math.round(vCenter.x / GRID_SHORT_SUBDIV) * GRID_SHORT_SUBDIV;
		vCenter.z = Math.round(vCenter.z / GRID_SHORT_SUBDIV) * GRID_SHORT_SUBDIV;

		this.position.set( -vCenter.x, 0, -vCenter.z);

		CosmosThree.globalMeshOffset.set(-vCenter.x, 0, -vCenter.z);

		this.bbox.setFromObject(this);
	}

	updateWorldSize(){
		this.cosmos.updateWorldSize(this.bbox);
	}

	updateCameraBounds(){
		// Create the bounding sphere
		const sphere = CameraControls.createBoundingSphere(this);

		// Extract the center and radius
		const center = sphere.center;
		const radius = sphere.radius;

		// Calculate the side length of the cube that fully contains the sphere
		const diameter = radius * 2;
		const sideLength = diameter;  // Side length of the cube that contains the sphere

		// Calculate the half side length
		const halfSideLength = sideLength / 2;

		// Calculate the minimum and maximum coordinates of the bounding box
		// Ensure that the sphere is contained along one diagonal of the cube
		const min = new THREE.Vector3(center.x - halfSideLength, center.y - halfSideLength, center.z - halfSideLength);
		const max = new THREE.Vector3(center.x + halfSideLength, center.y + halfSideLength, center.z + halfSideLength);

		const box = new THREE.Box3(min, max);

		// Assign the bounding box to the camera bounds helper and set the camera controls boundary
		if(CosmosThree.globalDebug){ this.cosmos.cameraBoundsHelper.box = box; }
		this.cosmos.camControls.setBoundary(box);
	}

	fitToElems(elemsOrMesh: TBaseSymbol[], animate = true){
		// Logger.time('[perf] mesh: fitToElems');
		const box = new THREE.Box3().makeEmpty();

		if(elemsOrMesh.length === 1){
			// Single object case

			// Create de box from the position of the symbol and the symbol size
			box.setFromCenterAndSize(elemsOrMesh[0].three.position, new THREE.Vector3(SYMBOL_SIZE, 0, SYMBOL_SIZE));
		}else{
			// Multiple object case

			// Iterate over all objects to expand the bounding box based on their positions
			for (let i = 0; i < elemsOrMesh.length; i++) {
				box.expandByPoint( elemsOrMesh[i].three.position );
			}

			// We expand the resulting box to take into account the symbol size (we take the make scenarios symbols size as they are the biggest)
			box.expandByVector(new THREE.Vector3(SYMBOL_MAKE_SCENARIO_SIZE / 2, 0 , SYMBOL_MAKE_SCENARIO_SIZE / 2 ));
		}

		const center = new THREE.Vector3();
		box.getCenter(center);
		
		const centerOffset = new THREE.Vector3(this.position.x, -center.y, this.position.z);

		box.min.add(centerOffset);
		box.max.add(centerOffset);

		box.getCenter(center);

		// Update the dynamic sphere helper
		if(CosmosThree.globalDebug){ this.cosmos.dynamicBoundsHelper.box = box; }

		// Get the corners of the Box3
		const corners = [
			new THREE.Vector3(box.min.x, box.max.y, box.min.z),
			new THREE.Vector3(box.max.x, box.max.y, box.min.z),
			new THREE.Vector3(box.min.x, box.max.y, box.max.z),
			new THREE.Vector3(box.max.x, box.max.y, box.max.z)
		];

		const screenCoords = [];

		for (let i = 0; i < corners.length; i++) {
			// Clone the corner vector
			const vector = corners[i].clone();

			// Project the vector to screen coordinates
			const widthHalf = 0.5 * (this.cosmos.graphCamera.right - this.cosmos.graphCamera.left);
			const heightHalf = 0.5 * (this.cosmos.graphCamera.top - this.cosmos.graphCamera.bottom);

			vector.project(this.cosmos.graphCamera);

			const screenX = (vector.x * widthHalf) + widthHalf;
			const screenY = -(vector.y * heightHalf) + heightHalf;

			// Store the screen coordinates
			screenCoords.push({ x: screenX, y: screenY });
		}

		// Calculate the projected bounding rectangle
		let minX = Infinity, minY = Infinity;
		let maxX = -Infinity, maxY = -Infinity;

		for (let i = 0; i < screenCoords.length; i++) {
			const coord = screenCoords[i];
			if (coord.x < minX) minX = coord.x;
			if (coord.y < minY) minY = coord.y;
			if (coord.x > maxX) maxX = coord.x;
			if (coord.y > maxY) maxY = coord.y;
		}

		// Calculate the dimensions of the projected bounding rectangle
		const boundingWidth = maxX - minX;
		const boundingHeight = maxY - minY;

		// If an item is selected then the right side panel is open, so we have to take its width into account for the available visible area
		let frustumWidth = 0;
		if(this._selected){
			frustumWidth = (-this.cosmos.canvasWidth + 2 * document.getElementById('rightPanel')!.getBoundingClientRect().x) / SCALE_FACTOR;
		}else{
			frustumWidth = (this.cosmos.graphCamera.right - this.cosmos.graphCamera.left);
		}

		const frustumHeight = this.cosmos.graphCamera.top - this.cosmos.graphCamera.bottom;

		// Calculate the zoom factor needed to fit the bounding rectangle in the viewport
		const zoomWidth = (frustumWidth - (frustumWidth * CAMERA_FIT_WIDTH_MARGIN)) / boundingWidth;
		const zoomHeight = (frustumHeight - (frustumWidth * CAMERA_FIT_HEIGHT_MARGIN)) / boundingHeight;

		// Set the camera zoom to the smaller of the two zoom factors, but invert the calculation since higher zoom means smaller view
		const newZoom = this.cosmos.graphCamera.zoom * Math.min(zoomWidth, zoomHeight);

		this.cosmos.camControls.zoomTo(newZoom, animate);
		this.cosmos.camControls.moveTo(center.x, center.y, center.z, animate);
		// Logger.timeEnd('[perf] mesh: fitToElems');
	}

	showAll() {
		for(let i = 0; i < this.symbols.length; i++){
			if(!this.symbols[i].filtered){
				this.symbols[i].visible = true;
			}
		}

		for(let i = 0; i < this.links.length; i++){
			this.links[i].visible = true;
		}

		for(let i = 0; i < this.groups.length; i++){
			this.groups[i].visible = true;
		}
	}

	async hideAll(): Promise<void> {
		// In case something is selected we unselect it
		this.unselectAll();
		this.disableInteraction();
		useAppStore.getState().disableUIInteractions();

		return new Promise<void>((resolve) => {
			for(let i = 0; i < this.symbols.length; i++){
				this.symbols[i].visible = false;
			}

			for(let i = 0; i < this.sharedSymbols.length; i++){
				this.sharedSymbols[i].visible = false;
			}
	
			for(let i = 0; i < this.links.length; i++){
				this.links[i].visible = false;
			}

			for(let i = 0; i < this.sharedLinks.length; i++){
				this.sharedLinks[i].visible = false;
			}

			for(let i = 0; i < this.groups.length; i++){
				this.groups[i].visible = false;
			}
	
			gsap.delayedCall(INTERACTION_ANIMATION_SPEED, () => {
				// The objects are visually hidden by now, opacities to 0, etc.
				// But now we are going to actually 'hide' them from a geometry perspective, so the bounds of their parent obejcts are right.
				// This is very important to fit the camera correctly and to set the 'world' size to the necessary dimensions.

				Logger.time("[perf] reset all elements");

				for(let i = 0; i < this.symbols.length; i++){
					this.symbols[i].three.position.x = 0;
					this.symbols[i].three.position.z = 0;
					this.symbols[i].matrixNeedsUpdate = true;
				}

				for(let i = 0; i < this.sharedSymbols.length; i++){
					this.sharedSymbols[i].three.position.x = 0;
					this.sharedSymbols[i].three.position.z = 0;
					this.sharedSymbols[i].matrixNeedsUpdate = true;
				}

				for(let i = 0; i < this.links.length; i++){
					this.links[i].update();
				}

				for(let i = 0; i < this.virtualLinks.length; i++){
					this.virtualLinks[i].update();
				}

				for(let i = 0; i < this.sharedLinks.length; i++){
					this.sharedLinks[i].update();
				}

				for(let i = 0; i < this.groups.length; i++){
					this.groups[i].update();
				}
				
				this.position.set(0, 0, 0);
				this.updateBBox();

				Logger.timeEnd("[perf] reset all elements");

				resolve();
			});
		});
	}

	spotlightOnFilterItem(filterItem: FilterItem | null) {
		if(!this._selected){ // If an item is selected we don't want to trigger the spotlight
			let symbolsToFilter: TBaseSymbol[] = [];
			if (filterItem) {
				const activeFilter = [{
					id: filterItem.groupDefinition.id,
					definition: filterItem.groupDefinition,
					label: filterItem.groupDefinition.label,
					items: [filterItem],
					isOpen: filterItem.groupDefinition.open ?? false,
					priority: filterItem.groupDefinition.priority ?? 1
				}];
				const filterResults = FilterManager.filterNodes(
					{symbols: this.symbols, links: []},
					activeFilter,
					true
				);
				if (filterResults.symbols.length) {
					symbolsToFilter = filterResults.symbols;
				}
			}

			this.spotlight(!filterItem ? [] : symbolsToFilter, false, false, 0);
		}
	}

	onSymbolHovered(symbol: BaseSymbol){
		if(!this._selected){
			this.spotlight([symbol], true, true, 1);
		}

		// positions and shows the full legend
		this._fullLegendDelayedCall?.kill();
		this._fullLegendDelayedCall = gsap.delayedCall(INTERACTION_SPOTLIGHT_DELAY, () => {
			this.fullLegend.setFrom(symbol);
		});
	}

	onSymbolUnhovered(){
		if(!this._selected){
			this.spotlight([], true, false, 1);
		}

		// hides the full legend
		this._fullLegendDelayedCall?.kill();
		this._fullLegendDelayedCall = gsap.delayedCall(INTERACTION_SPOTLIGHT_DELAY, () => {
			this.fullLegend.setFrom(null);
			if(this._selected){
				this.fullLegend.setFrom(this._selected);
			}
		});
	}

	onSymbolClicked(symbol: BaseSymbol){
		if(!this.cosmos.inputManager.keyControlCommandDown){
			symbol.select = true;
			this._multiSelected = [symbol];
		}else{
			if(this._selected && this._selected !== symbol){
				// Check if the element already exists in the array, if it does remove it instead of adding it
				if(this._multiSelected.includes(symbol)){
					this._multiSelected.splice( this._multiSelected.indexOf(symbol), 1);
				}else{
					this._multiSelected.push(symbol);
				}

				this.spotlight(this._multiSelected, false, true, 1, true);
			}
		}
	}

	onSymbolDoubleClicked(symbol: BaseSymbol){
		this.showUsages = true;
		this.onSymbolSelected(symbol);
	}

	onSymbolSelected(symbol: BaseSymbol){
		if(this._selected){
			this._selected.select = false;
		}
		this._selected = symbol;
		if(this.cosmos.inputManager.keyShiftDown || this.showUsages){
			this.showUsages = false;

			const shared = symbol.shared;

			if(shared && !shared.filtered){
				shared.visible = true;
				shared.links.forEach((sharedLink)=>{
					sharedLink.visible = true;
				});

				this.spotlight([shared], false, true, 2, true);
			}else{
				this.spotlight([symbol], false, true, 1, true);
			}
		}else{
			this.spotlight([symbol], false, true, 1, true);
		}

		// positions and shows the selection indicator
		this.selectionIndicator.setFrom(symbol);
		// positions and shows the full legend
		this._fullLegendDelayedCall?.kill();
		this.fullLegend.setFrom(symbol);
		// opens right side panel
		useAppStore.getState().setNodeDetail(symbol);
	}

	onSymbolUnselected(symbol: BaseSymbol){
		const shared = symbol.shared;

		if(shared){
			shared.visible = false;
			shared.links.forEach((sharedLink)=>{
				sharedLink.visible = false;
			});
		}
		this._selected = null;
		this.spotlight([], false, true, 1, true);
		// hides the selection indicator
		this.selectionIndicator.hide();
		// hides the full legend
		this._fullLegendDelayedCall?.kill();
		this.fullLegend.setFrom(null);
		// closes right side panel
		useAppStore.getState().setNodeDetail(null);
	}
	
	unselectAll(){
		if(this._selected){
			this._selected.select = false;
			this._selected = null;
			this._multiSelected = [];
		}
	}

	spotlight(symbols: TBaseSymbol[], delayed = true, staggered = true, level = 1, zoomTo = false){
		// If there are delayed calls from previous spotlight, cancel them
		this._spotlightDelayedCalls.forEach((call: gsap.core.Tween) => call.kill());
		this._spotlightDelayedCalls = [];

		const delayedCall = gsap.delayedCall(delayed ? INTERACTION_SPOTLIGHT_DELAY : 0, () => {
			if(symbols.length){
				// We get the elements to highlight
				const elemsToSpotlight = getElementsToSpotlight(symbols, level);
				// Now we get the elements to un-highlight
				const symbolsToSpotlight: TBaseSymbol[] = [];
				const linksToSpotlight: TBaseLink[] = [];
				elemsToSpotlight.forEach(({ symbols, links }) => {
					symbols.forEach(symbol => symbolsToSpotlight.push(symbol));
					links.forEach(link => linksToSpotlight.push(link));
				});
				const elemsToUnSpotlight = getUnmatchedSymbolsAndLinks(symbolsToSpotlight, linksToSpotlight);

				// zoom to the elements
				if(zoomTo){
					this.cosmos.camControls.saveState();
					this.pendingCameraReset = true;
					this.fitToElems(symbolsToSpotlight);
				}

				// first mute and un-spotlight links
				for(let i = 0 ; i < elemsToUnSpotlight.unmatchedLinks.length; i++){
					elemsToUnSpotlight.unmatchedLinks[i].spotlight = false;
					elemsToUnSpotlight.unmatchedLinks[i].muted = true;
				}

				// then mute and un-spotlight all symbols
				for(let i = 0 ; i < elemsToUnSpotlight.unmatchedSymbols.length; i++){
					elemsToUnSpotlight.unmatchedSymbols[i].spotlight = false;
					elemsToUnSpotlight.unmatchedSymbols[i].muted = true;
				}

				// And finally check the elements to spotlight to unmute and highlight them
				for(let j = 0 ; j < elemsToSpotlight.length; j++){
					const delay = j * INTERACTION_SPOTLIGHT_STAGGERING; // Adjust delay multiplier as needed
					const delayedCall = gsap.delayedCall(staggered ? delay : 0, () => {
						for(let i = 0 ; i < elemsToSpotlight[j].symbols.length; i++) {
							elemsToSpotlight[j].symbols[i].spotlightLevel = ((elemsToSpotlight.length -1) === 0) ? 0 : j / (elemsToSpotlight.length -1);
							elemsToSpotlight[j].symbols[i].spotlight = true;
						}
		
						for(let i = 0 ; i < elemsToSpotlight[j].links.length; i++) {
							elemsToSpotlight[j].links[i].spotlight = true;
						}
					});
					this._spotlightDelayedCalls.push(delayedCall);
				}
			}else{
				// first unmute all links
				for(let i = 0 ; i < this.links.length; i++){
					this.links[i].spotlight = false;
				}

				// then unmute all symbols
				for(let i = 0 ; i < this.symbols.length; i++){
					this.symbols[i].spotlight = false;
				}

				// then unmute all shared links
				for(let i = 0 ; i < this.sharedLinks.length; i++){
					this.sharedLinks[i].spotlight = false;
				}

				// then unmute all shared symbols
				for(let i = 0 ; i < this.sharedSymbols.length; i++){
					this.sharedSymbols[i].spotlight = false;
				}

				// restore camera
				if(zoomTo && this.pendingCameraReset){
					this.cosmos.camControls.reset(true);
				}
			}
		});
		this._spotlightDelayedCalls.push(delayedCall);
	}

	async filterNodes(activeFilterGroups?: ActiveFilters) {
		let forceLayout = false;

		if(this._selected){
			this.unselectAll();
		}

		if(activeFilterGroups){
			this._activeFilterGroups = activeFilterGroups;
		}else{
			forceLayout = true;
		}

		Logger.time('[perf] mesh: filter nodes (including layout if necessary)');

		const symbols = this.symbols;
		const links = this.links;

		this._filterResult = FilterManager.filterNodes({symbols, links}, this._activeFilterGroups);

		const newFilterList = FilterManager.getUpdatedFilterList(
			this._filterResult.symbols.length ? this._filterResult.symbols : [],
			symbols,
			useAppStore.getState().filterList || [],
			this._activeFilterGroups
		);
		useAppStore.getState().setFilterList(newFilterList);

		// The order of these two lines is very important, do not change
		this._filterResult.links = this._filterResult.symbols.length ? this._filterResult.links : this.links;
		this._filterResult.symbols = this._filterResult.symbols.length ? this._filterResult.symbols : this.symbols;

		// Apply the filter state to all objects
		// Elements to filter
		for(let i = 0; i < this.groups.length; i++){
			this.groups[i].filtered = true;
		}

		for(let i = 0; i < this.sharedSymbols.length; i++){
			this.sharedSymbols[i].filtered = true;
		}

		for(let i = 0; i < this.sharedLinks.length; i++){
			this.sharedLinks[i].filtered = true;
		}

		for(let i = 0; i < this.symbols.length; i++){
			this.symbols[i].filtered = true;
		}

		for(let i = 0; i < this.links.length; i++){
			this.links[i].filtered = true;
		}

		// Elements to un-filter
		for(let i = 0; i < this._filterResult.symbols.length; i++){
			this._filterResult.symbols[i].filtered = false;
		}

		for(let i = 0; i < this._filterResult.links.length; i++){
			this._filterResult.links[i].filtered = false;
		}

		for(let i = 0; i < this._filterResult.symbols.length; i++){
			Repository.groups.get(this._filterResult.symbols[i].groupId)!.filtered = false;
		}

		for(let i = 0; i < this._filterResult.symbols.length; i++){
			const symbol = this._filterResult.symbols[i];
			const sharedSymbol = symbol.shared;
			if(sharedSymbol){
				sharedSymbol.filtered = false;
				for(let j = 0; j < sharedSymbol.links.length; j++){
					const sharedLink = sharedSymbol.links[j];
					if(!sharedLink.source?.filtered){
						sharedLink.filtered = false;
					}
				}
			}
		}

		// We need to update the sharedSymbols positions
		Repository.sharedSymbols.forEach(sharedSymbol => {
			const centroid = findCentroid(sharedSymbol.neighbors);
			sharedSymbol.three.position.x = centroid.x;
			sharedSymbol.three.position.z = centroid.y;
			sharedSymbol.matrixNeedsUpdate = true;
		});

		let shouldAnimateCam = true;

		// first layout for the mesh or the mesh should re-layout
		if(
			forceLayout
			|| this.layoutAfterLoad
			|| (this._layoutManager.shouldReLayout( this._filterResult.symbols )
				|| (
					this._baseLayoutBackgroundPromise
					&& !this._activeFilterGroups.length
				)
			)
		) {
			shouldAnimateCam = false;
			await this.layout();
		}

		// Now that all elements are in their right place we compute the size of the mesh
		this.updateSize();

		// Shows all elements if they are hidden
		this.showAll();

		// Fits the camera to the filtered symbols
		this.fitToElems(this._filterResult.symbols, shouldAnimateCam);

		// We restore the user interaction on the graph and the UI.
		this._interactionDelayedCall = gsap.delayedCall(INTERACTION_ANIMATION_SPEED, () => {
			this.enableInteraction();
			useAppStore.getState().enableUIInteractions();
		});

		Logger.timeEnd('[perf] mesh: filter nodes (including layout if necessary)');
	}

	search(query: string){
		if (!this._searchManager) {
			Logger.warn('Mesh Search Manager is not initialized');
			return [];
		}
		return this._searchManager.searchFuse(query, this._filterResult);
	}

	disableInteraction(){
		this._interactionDelayedCall?.kill();
		this.cosmos.inputManager.enabled = false;
		this.cosmos.camControls.enabled = false;
	}

	enableInteraction(){
		this.cosmos.inputManager.enabled = true;
		this.cosmos.camControls.enabled = true;
	}

	setCamera(mode: "3d" | "2d", animated = true){
		if(mode === this.cameraMode){ return }

		this.cameraMode = mode;
		this.pendingCameraReset = false;
		this.cosmos.setCameraMode(mode, animated);
	}

	setTheme(activeTheme: "cosmosLight" | "cosmosDark", animated = true){
		this.activeTheme = activeTheme;

		if(activeTheme === "cosmosLight"){
			Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) =>{
				gsap.to((symbolShapeMesh.material as any).uniforms.fadeColor.value, {duration: animated ? INTERACTION_ANIMATION_SPEED : 0, r: this.cosmos.groundColorLight.r, g: this.cosmos.groundColorLight.g, b: this.cosmos.groundColorLight.b, ease: "none"});
			});
			if(Repository.symbolsAppsMesh){
				gsap.to((Repository.symbolsAppsMesh.material as any).uniforms.fadeColor.value, {duration: animated ? INTERACTION_ANIMATION_SPEED : 0, r: this.cosmos.groundColorLight.r, g: this.cosmos.groundColorLight.g, b: this.cosmos.groundColorLight.b, ease: "none"});
			}
		}else if(activeTheme === "cosmosDark"){
			Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) =>{
				gsap.to((symbolShapeMesh.material as any).uniforms.fadeColor.value, {duration: animated ? INTERACTION_ANIMATION_SPEED : 0, r: this.cosmos.groundColorDark.r, g: this.cosmos.groundColorDark.g, b: this.cosmos.groundColorDark.b, ease: "none"});
			});
			if(Repository.symbolsAppsMesh){
				gsap.to((Repository.symbolsAppsMesh.material as any).uniforms.fadeColor.value, {duration: animated ? INTERACTION_ANIMATION_SPEED : 0, r: this.cosmos.groundColorDark.r, g: this.cosmos.groundColorDark.g, b: this.cosmos.groundColorDark.b, ease: "none"});
			}
		}

		this.cosmos.setTheme(activeTheme, animated);
	}

	override clear(): this{
		return this.dispose();
	}

	dispose(){
		this._layoutManager.destroy();

		this.cosmos.inputManager.hovered = undefined;

		// Send all gsap animations to Valhalla
        gsap.globalTimeline.clear(true);

		this.cosmos.guiScene.children.forEach((child)=>{
            child.clear();
        });

		this.cosmos.guiScene.remove(this.cosmos.guiScene.getObjectByName("legendsInstancedMesh")!);

		if (!this.loaded) {
			return this;
		}

		this.symbols.forEach((symbol) =>{
			symbol.dispose();
		});

		this.sharedSymbols.forEach((sharedSymbol) =>{
			sharedSymbol.dispose();
		});

		this.links.forEach((link) =>{
			link.dispose();
		});

		this.virtualLinks.forEach((virtualLink) =>{
			virtualLink.dispose();
		});

		this.sharedLinks.forEach((sharedLink) =>{
			sharedLink.dispose();
		});

		this.groups.forEach((group) =>{
			group.dispose();
		});

		this.selectionIndicator.dispose();

		this.fullLegend.dispose();

		Repository.symbolsAppsMesh?.dispose();
		Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) =>{
			symbolShapeMesh.dispose();
		});
		Repository.linksShapesMesh?.dispose();
		Repository.linksIndicatorsMesh?.dispose();
		Repository.symbolsIconsMesh?.dispose();
		Repository.symbolsAppsCountsMesh?.dispose();
		Repository.symbolsLegendsMesh?.dispose();
		Repository.groupsShapesMesh?.dispose();

		Repository.virtualLinksShapesMesh?.dispose();

		Repository.sharedLinksShapesMesh?.dispose();

		Repository.symbolsAppsMesh = null;
		Repository.symbolsShapesMeshes.clear();
		Repository.linksShapesMesh = null;
		Repository.linksIndicatorsMesh = null;
		Repository.symbolsIconsMesh = null;
		Repository.symbolsAppsCountsMesh = null;
		Repository.symbolsLegendsMesh = null;
		Repository.groupsShapesMesh = null;

		Repository.clear();

		this.symbols = [];
		this.links = [];
		this.groups = [];

		this.virtualLinks = [];

		this.sharedSymbols = [];
		this.sharedLinks = [];

		this._activeFilterGroups = [];

		super.clear();

		BaseObject.idCount = 0;

		return this;
	}
}