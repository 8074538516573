import type {ComponentType} from 'react';

export const COMPONENTS = {
    FEATURES_PANEL: './../components/utils/features/FeaturesPanel.tsx'
}

export class ComponentLoader {

    static async load(name: string) {
        const module = await import(/* @vite-ignore */COMPONENTS[name as keyof typeof COMPONENTS]);
        return module.default as ComponentType;
    }

}