import type { StateCreator } from 'zustand'
import { TBaseSymbol, TBaseLink } from '../three/Mesh.ts'


export interface NodeDetailSlice {
    nodeDetail: TBaseSymbol | TBaseLink | null,
    setNodeDetail: (node: TBaseSymbol | TBaseLink | null) => void
}

export const createNodeDetailSlice: StateCreator<NodeDetailSlice> = (set) => ({
    nodeDetail: null,
    setNodeDetail: (node: TBaseSymbol | TBaseLink | null) => {
        set(() => (
            {
                nodeDetail: node
            }
        ));
    }
})
