import * as THREE from 'three';

export const ExtensionGreyscale = {
    name: "extension-greyscale",
    uniforms: {
      greyscale: 0,
      fadeColor: new THREE.Color(0.75, 0.75, 0.80)
    },
    vertexShader: (shader: any) => {
      shader = `
        attribute float instGreyscale; // Define greyscale attribute
        varying float vGreyscale; // Define varying variable to pass to fragment shader
        ${
            shader.replace(
              '#include <project_vertex>',
              `
              #include <project_vertex>
              vGreyscale = instGreyscale; // Pass greyscale attribute to fragment shader
				      `
            )
        }
      `;
      return shader;
    },
    fragmentShader: (shader: any) => {
        shader = `
          uniform float greyscale;
          uniform vec3 fadeColor;
          varying float vGreyscale; // Receive greyscale value from vertex shader
          ${
              shader.replace(
                '#include <color_fragment>',
                `
                #include <color_fragment>
                vec3 color = diffuseColor.rgb;
                vec3 grey = vec3(dot(color, vec3(0.299, 0.587, 0.114)));
                vec3 greyColor = mix(color, grey, (vGreyscale != 0.0 ? vGreyscale : greyscale));
                float whitenessFactor = max(0.0, (vGreyscale != 0.0 ? vGreyscale : greyscale));
                vec3 finalColor = mix(greyColor, fadeColor, whitenessFactor);
                diffuseColor.rgb = finalColor;
                `
              )
          }
        `;
        return shader;
    },
};