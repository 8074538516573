import * as THREE from 'three';

import { BaseInstancedMesh } from "../common/BaseInstancedMesh";
import { CosmosThree } from '../CosmosThree';
import { SymbolIcon } from './SymbolIcon';

export class SymbolsIconsIM extends BaseInstancedMesh{

    texturePositions: THREE.InstancedBufferAttribute;

	iconNeedsUpdate = false;

    constructor(size : number){
		const icon = new SymbolIcon();

        const geometry = icon.getGeometry();
        
        const texturePositions = new THREE.InstancedBufferAttribute(new Float32Array(size * 2), 2, true);

		geometry.setAttribute('texOffset', texturePositions);

        const material = icon.getMaterial();

        material.uniforms.atlasSize.value = new THREE.Vector2(CosmosThree.iconsAtlasJson!.meta.size.w, CosmosThree.iconsAtlasJson!.meta.size.h);
        material.uniforms.texSize.value = new THREE.Vector2(CosmosThree.iconsAtlasJson!.frames[0].frame.w, CosmosThree.iconsAtlasJson!.frames[0].frame.h);

        super(geometry, material, size);

        this.texturePositions = texturePositions;

        this.name = "iconsInstancedMesh";
    
		icon.dispose();
	}

	override sync (){
		super.sync();

		if(this.iconNeedsUpdate){
            for(let i = 0 ; i < this.elems.length; i++){
                const elem = this.elems[i] as SymbolIcon;
                if(elem.iconNeedsUpdate){
                    this.syncIcon(elem);
                }
            }
        }
	}

	syncIcon(elem: SymbolIcon){
        elem.iconNeedsUpdate = false;
		const iconImageData = CosmosThree.iconsAtlasMap?.get(elem.slug);
		if(iconImageData){
			this.setIconAt(elem.instanceId, iconImageData.frame.x, iconImageData.frame.y);
			this.texturePositions.needsUpdate = true;
		}
    }

	setIconAt(instanceId: number, x: number, y: number){
        this.texturePositions.array [ instanceId * 2 ] = x;
        this.texturePositions.array [ instanceId * 2 + 1 ] = y;
    }
}