import type {WorkerSymbol, WorkerLink, LayoutResult, WorkerGroup} from '../LayoutWorker.ts';
import {LayoutOptions} from '../LayoutManager.ts';
import LicenseFile from '../../../../../license.json';
import {
  Class,
  License,
  LayoutExecutor,
  GraphBuilder,
  Rect,
  RecursiveGroupLayout,
  Mapper,
  RecursiveGroupLayoutData,
  OrganicLayout,
} from '@celonis/yfiles';
import type { IGraph, ILayoutAlgorithm, INode, OrganicLayoutClusteringPolicy, OrganicLayoutGroupSubstructureScope } from '@celonis/yfiles';
import { GROUP_TITLE_HEIGHT, SCALE_FACTOR, SYMBOL_MAKE_SCENARIO_SIZE } from '../../../common/constants.ts';
import { Logger } from '../../../../utils/Logger.ts';

License.value = LicenseFile;

// Reason for the next line: https://docs.yworks.com/yfileshtml/#/dguide/yfiles-modules#es-modules-loading_missing-modules
Class.ensure(LayoutExecutor);

export interface OrganicLayoutOptions {
  clusterAsGroupSubstructureAllowed?: boolean,
  clusteringPolicy?: OrganicLayoutClusteringPolicy,
  compactnessFactor?: number,
  clusteringQuality?: number,
  groupSubstructureScope?: OrganicLayoutGroupSubstructureScope,
  groupNodeCompactness?: number,
  groupSubstructureSize?: number,
  qualityTimeRatio?: number,
}

export interface YFilesLayoutOptions {
  organicLayoutOptions?: OrganicLayoutOptions,
}

export class YFilesLayout {

  public static async applyLayout(
    symbols: WorkerSymbol[],
    groups: WorkerGroup[],
    links: WorkerLink[],
    options: LayoutOptions
  ): Promise<LayoutResult> {

    const graph = this.buildGraph(symbols, groups, links, options);

    const result: LayoutResult = {symbols: []};

    graph.nodes.forEach((node) => {
      const symbol: WorkerSymbol = node.tag;
      result.symbols.push({
        id: symbol.id,
        x: node.layout.center.x,
        y: node.layout.center.y,
        groupId: symbol.groupId
      })
    });

    return result;
  }

  private static buildGraph(
      symbols: WorkerSymbol[],
      groups: WorkerGroup[],
      links: WorkerLink[],
      options: LayoutOptions
  ): IGraph {
    const builder = new GraphBuilder();

    const yfilesOptions = options.yfiles;

    builder.createEdgesSource({
      data: links,
      sourceId: (edge) => edge.source.id,
      targetId: (edge) => edge.target.id,
    });

    if (groups.length) {
      builder.createGroupNodesSource({
        data: groups,
        id: (node) => node.id,
      });
    }

    builder.createNodesSource({
      data: symbols,
      id: (node) => node.id,
      parentId: (node) => node.groupId,
      layout: () => new Rect(0, 0, SYMBOL_MAKE_SCENARIO_SIZE * SCALE_FACTOR, SYMBOL_MAKE_SCENARIO_SIZE * SCALE_FACTOR),
    });

    Logger.time(`[perf] mesh: build graph`);
    const graph = builder.buildGraph();

    // the RecursiveGroupLayout can use a core layout algorithm to arrange the top level hierarchy
    const coreLayout = new OrganicLayout({
      minimumNodeDistance: GROUP_TITLE_HEIGHT * 4 * SCALE_FACTOR
    });

    const layout = new RecursiveGroupLayout(coreLayout);

    // Type guard to check if a node has a tag with a parentId
    function hasParentId(node: INode): node is INode & { tag: { parentId: any } } {
      return node.tag !== undefined && node.tag.parentId !== undefined;
    }

    // Get nodes that satisfy the condition
    const nodesWithParentId = graph.nodes.filter(hasParentId);

    const groupLayoutOptions = {
      minimumNodeDistance: SYMBOL_MAKE_SCENARIO_SIZE * SCALE_FACTOR * 1.5,
      nodeEdgeOverlapAvoided: true,
      nodeOverlapsAllowed: false,
      deterministic: true,
      maximumDuration: 120000,
      groupSubstructureSize: 2,
      ...yfilesOptions.organicLayoutOptions
    }

    // assign a layout algorithm to each group node
    const mapper = new Mapper<INode, ILayoutAlgorithm>();

    nodesWithParentId.forEach((node) => {
      mapper.set(node, new OrganicLayout(groupLayoutOptions));
    });

    const layoutData = new RecursiveGroupLayoutData({
      groupNodeLayouts: mapper
    });
    
    Logger.timeEnd(`[perf] mesh: build graph`);

    Logger.time(`[perf] mesh: layout graph`);

    graph.applyLayout(layout, layoutData);

    Logger.timeEnd(`[perf] mesh: layout graph`);

    return graph;
  }


}