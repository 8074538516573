type Props = {
    className?: string,
}

export const Search = ({className}: Props) => {
    return (
        <svg className={'h-3.5 w-3.5 ' + className || ''} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
            <path d="M12.2 12.2L8.62842 8.62839" stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M5.80005 9.79999C8.00919 9.79999 9.80005 8.00913 9.80005 5.79999C9.80005 3.59085 8.00919 1.79999 5.80005 1.79999C3.59091 1.79999 1.80005 3.59085 1.80005 5.79999C1.80005 8.00913 3.59091 9.79999 5.80005 9.79999Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}
