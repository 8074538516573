import { BaseBatchedMesh } from '../common/BaseBatchedMesh';
import { GroupShape } from './GroupShape';

export class GroupsShapesBM extends BaseBatchedMesh{
    constructor(size : number){
        const link = new GroupShape();

        super(size, size * 512, size * 2048, link.getMaterial());

        this.castShadow = false;
        this.receiveShadow = true;

        link.dispose();
    }
}