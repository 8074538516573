import { Repository } from "../../../common/Repository";
import { BaseSymbol, SerializedSymbol } from "../../BaseSymbol";

export class MakeModuleSymbol extends BaseSymbol {
    constructor () {
        super();
    }

	override get type() {
		return super.type;
	}

	override set type(value) {
		super.type = value;

		this._preventOpenUrl = true;
		this.iconSlug = "make";
	}

	override fromJSON(object: SerializedSymbol): void {
		object.typeLabel = 'Make Module';
		super.fromJSON(object)
	}
}

Repository.install('make:module', MakeModuleSymbol);