import type {
    ActiveFilters,
    FilterItem,
    FilterListGroup,
    FiltersFromUrl,
    FilterDefinition,
    FilterDefinitionMapping, FilterGroupDefinition
} from '../../../store/Filter.ts';

import {FilterMappingType} from '../../../store/Filter.ts';
import _ from 'lodash';
import { BaseSymbol } from '../../symbols/BaseSymbol.ts';
import { BaseLink } from '../../links/BaseLink.ts';

type TempFilterItems = { [key: string]: { [key: string]: FilterItem } };
type FiltersDefinition = {
    [key: string]: FilterListGroup
};

export interface FilterResult {
    activeFilterItems: FilterItem[]
    symbols: BaseSymbol[];
    links: BaseLink[];
}

function resolveFilterValue(
    value: string | number | boolean | FilterDefinitionMapping,
    node: BaseSymbol,
    mappingArrayIndex?: number
) {
    if (
        typeof value === 'string'
        || typeof value === 'number'
        || typeof value === 'boolean'
    ) {
        return value;
    }
    if (
        node &&
        typeof value === 'object' &&
        typeof value.mapping === 'string' &&
        !Array.isArray(value)
    ) {
        let mapping = value.mapping;
        if (typeof mappingArrayIndex !== 'undefined') {
            // @TODO now only support one level of array nesting
            mapping = mapping.replace('[]', `[${mappingArrayIndex}]`);
        }
        return _.get(node.originalData, mapping);
    }
}

function resolveFilterItem(
    node: BaseSymbol,
    preselected: FiltersFromUrl,
    filtersDefinition: FiltersDefinition,
    definition: FilterDefinition,
    groupDefinition: FilterGroupDefinition,
    _tempItems: TempFilterItems,
    mappingArrayIndex?: number
) {
    let itemId;
    let label;
    let forUndefined = false;
    // check undefined
    const itemToCheckValue = resolveFilterValue({
        mapping: definition.filterMapping
    }, node, mappingArrayIndex);

    if (typeof itemToCheckValue === 'undefined' && definition.undefinedLabel) {
        forUndefined = true;
        itemId = `undefined`;
        label = definition.undefinedLabel;
    } else {
        itemId = resolveFilterValue(definition.id, node, mappingArrayIndex);
    }

    if (!itemId) {
        return;
    }

    if (!label) {
        label = resolveFilterValue(definition.label, node, mappingArrayIndex);
    }


    let addCount = 0;
    // number of symbols
    if (definition.filterType === 'boolean') {
        if (itemToCheckValue === definition.value) {
            addCount = 1;
        }
    } else {
        addCount = 1;
    }

    if (_tempItems[definition.groupId][itemId]) {
        _tempItems[definition.groupId][itemId].numberOfSymbols! += addCount;
        return;
    }

    let selected = false;

    if (typeof preselected[definition.groupId] !== 'undefined') {
        if (preselected[definition.groupId].includes(itemId)) {
            selected = true;
            filtersDefinition[definition.groupId].isOpen = true;
        }
    }

    const item = {
        id: itemId,
        label,
        forUndefined,
        definition: definition,
        groupDefinition: groupDefinition,
        symbolType: node.constructor.name,
        selected,
        priority: definition.priority ?? 1,
        numberOfSymbols: addCount,
        disabled: false
    } as FilterItem;

    _tempItems[definition.groupId][itemId] = item;
}

export class FilterManager {

    static getUpdatedFilterList = (
        filteredSymbols: BaseSymbol[],
        allSymbols: BaseSymbol[],
        filters: FilterListGroup[],
        activeFilterGroups: ActiveFilters
    ) => {

        const symbols = filteredSymbols.length ? filteredSymbols : allSymbols;

        const groupIgnoredFilterItems: {[key: string]: FilterItem[]} = {};
        const activeFilterGroupIds = activeFilterGroups.map((item) => {
            return item.id;
        });

        if (activeFilterGroupIds.length) {
            for (const filterListGroup of activeFilterGroups) {
                if (!filterListGroup.items.length) {
                    continue;
                }

                const activeFilterGroupId = filterListGroup.id;
                const pickedFilterGroups = activeFilterGroups.filter((item) => {
                    return item.id !== activeFilterGroupId;
                });

                const filteredExceptGroup = this.filterNodes(
                    { symbols: allSymbols, links: [] },
                    pickedFilterGroups,
                    true,
                    activeFilterGroupId === 'symbolType'
                );

                const g = this.collectFiltersFromNodes(
                    filteredExceptGroup.symbols.length ? filteredExceptGroup.symbols : allSymbols,
                    {}
                );
                groupIgnoredFilterItems[activeFilterGroupId] = _.flatMap(g, 'items');
            }
        }

        const groups = this.collectFiltersFromNodes(symbols, {});
        const items: FilterItem[] = _.flatMap(groups, 'items');
        const clonedFilters = _.cloneDeep(filters);
        clonedFilters.forEach(filterGroup => {
            filterGroup.items.forEach(filterItem => {
                let item = undefined;
                if (groupIgnoredFilterItems[filterGroup.id] && groupIgnoredFilterItems[filterGroup.id].length) {
                    item = groupIgnoredFilterItems[filterGroup.id].find(item => item.id === filterItem.id && item.definition.groupId === filterGroup.id);
                } else {
                    item = items.find(item => item.id === filterItem.id && item.definition.groupId === filterGroup.id);
                }
                if (item) {
                    filterItem.numberOfSymbols = item.numberOfSymbols;
                    filterItem.disabled = false;
                    if (filterItem.numberOfSymbols === 0) {
                        filterItem.disabled = true;
                    }
                } else {
                    filterItem.numberOfSymbols = 0;
                    filterItem.disabled = true;
                }
            });
        });
        return clonedFilters;
    }

    static collectFiltersFromNodes = (nodes: BaseSymbol[], preselected: FiltersFromUrl) => {
        const filtersDefinition: FiltersDefinition = {};
        const _tempGroups = new Map<string, FilterGroupDefinition>();
        const _tempItems: TempFilterItems = {};
        nodes.forEach(node => {

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const definitionGroups: FilterGroupDefinition[] = node.constructor?.getFilterGroupDefinition();

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const definitionItems: FilterDefinition[] = node.constructor?.getFilterDefinition();

            // @TODO maybe move this to base symbol getDefinition method
            definitionGroups.push({
                id: 'symbolType',
                label: 'Object type',
                priority: 0
            });
            definitionItems.push({
                groupId: 'symbolType',
                label: {
                    mapping: 'typeLabel',
                },
                id: {
                    mapping: 'type',
                },
                inputType: 'checkbox',
                filterType: 'string',
                filterMapping: 'type',
                filterMappingType: 'string' as FilterMappingType
            });

            // process groups
            for (const definitionGroup of definitionGroups) {
                _tempGroups.set(definitionGroup.id, definitionGroup);
            }

            if (definitionItems?.length) {
                for (const definition of definitionItems) {
                    const groupDefinition = _tempGroups.get(definition.groupId);
                    if (!groupDefinition) {
                        continue;
                    }
                    if (!filtersDefinition[groupDefinition.id]) {
                        filtersDefinition[groupDefinition.id] = {
                            id: definition.groupId,
                            definition: groupDefinition,
                            label: groupDefinition.label,
                            items: [],
                            isOpen: groupDefinition.open ?? false,
                            priority: groupDefinition.priority ?? 1
                        }
                        _tempItems[groupDefinition.id] = {};
                    }

                    if (typeof definition.id !== 'string' && definition.id?.mapping?.includes('[]')) {
                        const parts = definition.id.mapping.split('[]');
                        const items = _.get(node.originalData, parts[0]);
                        if (!Array.isArray(items)) {
                            continue;
                        }
                        for (let i = 0; i < items.length; i++) {
                            resolveFilterItem(node, preselected, filtersDefinition, definition, groupDefinition, _tempItems, i);
                        }
                    } else {
                        resolveFilterItem(node, preselected, filtersDefinition, definition, groupDefinition, _tempItems);
                    }
                }
            }
        })

        // remap items to groups
        for (const [key, value] of Object.entries(filtersDefinition)) {
            const group = value as FilterListGroup;
            const items = Object.values(_tempItems[key]);
            // sort
            const sortedItems = _.orderBy(items, ['priority', 'forUndefined', 'label'], ['desc', 'asc', 'asc']);
            // update disabled
            for (const item of sortedItems) {
                if (item.numberOfSymbols === 0) {
                    item.disabled = true;
                }
            }
            group.items = sortedItems;
        }
        const sortedDefinitions = _.orderBy(Object.values(filtersDefinition).flat(), ['priority', 'label'], ['desc', 'asc']);
        return sortedDefinitions;
    }

    private static activateNearestNodes = (
        node: BaseSymbol,
        filterPassed: Map<string, BaseSymbol>,
        affectedByFilter: Set<string>,
        toShow: Map<string, BaseSymbol>,
        alreadyChecked: string[] = []
    ) => {
        alreadyChecked.push(node.id);
        const links = node.links;
        links.forEach(link => {
            const nodesToCheck = [link.target, link.source] as BaseSymbol[];
            for (const nodeToCheck of nodesToCheck) {
                if (
                    nodeToCheck &&
                    !alreadyChecked.includes(nodeToCheck.id) &&
                    (!affectedByFilter.has(nodeToCheck.id) || filterPassed.has(nodeToCheck.id))
                ) {
                    toShow.set(nodeToCheck.id, nodeToCheck);
                }
            }
        })
    }

    // activate filtered nodes until it reaches nodes that didn't pass the filtering
    private static activateFullChainToNode = (
        node: BaseSymbol,
        filterPassed: Map<string, BaseSymbol>,
        affectedByFilter: Set<string>,
        toShow: Map<string, BaseSymbol>,
        alreadyChecked: string[] = []
    ) => {
        alreadyChecked.push(node.id);
        const links = node.links;
        links.forEach(link => {
            const nodesToCheck = [link.target, link.source] as BaseSymbol[];
            for (const nodeToCheck of nodesToCheck) {
                if (
                    nodeToCheck &&
                    !alreadyChecked.includes(nodeToCheck.id) &&
                    (!affectedByFilter.has(nodeToCheck.id) || filterPassed.has(nodeToCheck.id))
                ) {
                    toShow.set(nodeToCheck.id, nodeToCheck);
                    this.activateFullChainToNode(nodeToCheck, filterPassed, affectedByFilter, toShow, alreadyChecked);
                }
            }
        })
    }

    private static setFilterPassedToRelatives = (
        node: BaseSymbol,
        symbolType: string,
        filterGroupId: string,
        filterPassedByRelativesInGroups: Map<string, Set<string>>,
        alreadyChecked: string[] = []
    ) => {
        alreadyChecked.push(node.id);

        if (!filterPassedByRelativesInGroups.has(filterGroupId)) {
            filterPassedByRelativesInGroups.set(filterGroupId, new Set())
        }
        filterPassedByRelativesInGroups.get(filterGroupId)?.add(node.id);

        const links = node.links;
        links.forEach(link => {
            const nodesToCheck = [link.target, link.source] as BaseSymbol[];
            for (const nodeToCheck of nodesToCheck) {
                if (!nodeToCheck) {
                    continue;
                }
                if (
                    !alreadyChecked.includes(nodeToCheck.id) &&
                    nodeToCheck.constructor.name !== symbolType
                ) {
                    this.setFilterPassedToRelatives(
                        nodeToCheck,
                        symbolType,
                        filterGroupId,
                        filterPassedByRelativesInGroups,
                        alreadyChecked);
                }
            }
        })
    }

    private static itemPassed(
        node: BaseSymbol,
        filterItem: FilterItem,
        passedValue: boolean,
        passedDefault: boolean,
        mappingArrayIndex?: number
    ) {
        let passed = passedDefault;
        const valueToCheck = resolveFilterValue({ mapping: filterItem.definition.filterMapping }, node, mappingArrayIndex);
        if (typeof valueToCheck === 'undefined') {
            if (filterItem.forUndefined) {
                passed = passedValue;
            }
            return passed;
        }

        switch (filterItem.definition.filterMappingType) {
            case FilterMappingType.BOOLEAN: {
                if (
                    typeof filterItem.definition.value !== 'undefined'
                ) {
                    if (filterItem.definition.value === valueToCheck) {
                        passed = passedValue;
                    }
                } else if (valueToCheck === true) {
                    passed = passedValue;
                }
                break
            }
            case FilterMappingType.STRING: {
                if (valueToCheck === filterItem.id) {
                    passed = passedValue;
                }
                break;
            }
        }
        return passed;
    }

    public static filterNodes = (
        data: { symbols: BaseSymbol[], links: BaseLink[]},
        activeFilterGroups: ActiveFilters,
        getSymbolsOnly = false,
        forceSpread = false
    ): FilterResult => {
        
        const activeFilterItems: FilterItem[] = activeFilterGroups.reduce((acc: FilterItem[], group) => {
            return acc.concat(group.items);
        }, []);

        const affectedByFilter: Set<string> = new Set();
        const filterPassedByRelativesInGroups: Map<string, Set<string>> = new Map();
        const filterPassed: Map<string, BaseSymbol> = new Map();
        const filterPassedInGroups: Map<string, Set<string>> = new Map();

        const filterResult: FilterResult = {
            activeFilterItems,
            symbols: [],
            links: []
        }

        const nodes: BaseSymbol[] = data.symbols;
        const links: BaseLink[] = data.links;

        // Nothing to search in
        if (!nodes.length) return filterResult;

        // empty filters, reset nodes and return empty result
        if (!activeFilterItems.length && !getSymbolsOnly) return filterResult;
        nodes.forEach(node => {

            // filter login between groups should have AND logic
            for (const filterGroup of activeFilterGroups) {

                const filterGroupId = filterGroup.id;

                let passed = false;
                const passedValue = !passed;

                for (const filterItem of filterGroup.items) {
                    if (filterItem.symbolType !== node.constructor.name) {
                        continue;
                    }

                    affectedByFilter.add(node.id);

                    if (filterItem.definition.filterMapping.includes('[]')) {
                        const parts = filterItem.definition.filterMapping.split('[]');
                        const items = _.get(node.originalData, parts[0]);
                        if (!Array.isArray(items)) {
                            continue;
                        }
                        const _tempPassedArray: boolean[] = [];
                        for (let i = 0; i < items.length; i++) {
                            _tempPassedArray.push(this.itemPassed(node, filterItem, passedValue, passed, i));
                        }
                        passed = _tempPassedArray.some((_i => _i));
                    } else {
                        passed = this.itemPassed(node, filterItem, passedValue, passed);
                    }
                }

                if (filterGroup.items.length && passed) {
                    this.setFilterPassedToRelatives(node, node.constructor.name, filterGroupId, filterPassedByRelativesInGroups);
                    if (!filterPassedInGroups.get(filterGroupId)) {
                        filterPassedInGroups.set(filterGroupId, new Set());
                    }
                    filterPassedInGroups.get(filterGroupId)?.add(node.id);
                }
            }
        });

        nodes.forEach(node => {
            const passedByRelatives: boolean[] = [];
            const passedInGroups: boolean[] = [];
            for (const filterGroup of activeFilterGroups) {
                if (!filterGroup.items.length) {
                    continue;
                }
                const filterGroupId = filterGroup.id;
                if (filterPassedByRelativesInGroups.get(filterGroupId)?.has(node.id)) {
                    passedByRelatives.push(true);
                } else {
                    passedByRelatives.push(false);
                }

                passedInGroups.push(filterPassedInGroups.get(filterGroupId)?.has(node.id) ?? false);
            }

            if (passedInGroups.length) {
                // nodes has to pass in each group, differs by filter type
                const finalPassed = passedInGroups.find(item => item) && passedByRelatives.every(item => item);

                if (finalPassed) {
                    filterPassed.set(node.id, node);
                }
            }
        })

        const symbolsToShow: Map<string, BaseSymbol> = new Map();

        for (const node of filterPassed.values()) {
            symbolsToShow.set(node.id, node);

            if (getSymbolsOnly && !forceSpread) {
                continue;
            }
            
            this.activateNearestNodes(node, filterPassed, affectedByFilter, symbolsToShow);
        }

        const symbolsToShowArray = Array.from(symbolsToShow.values());
        const linksToShowArray: BaseLink[] = [];

        if (getSymbolsOnly) {
            filterResult.symbols = symbolsToShowArray;
            return filterResult;
        }
        
        // remove links
        links.forEach(link => {
            const shouldShow = symbolsToShow.has(link.source?.id || "") && symbolsToShow.has(link.target?.id || "");
            if (shouldShow) {
                linksToShowArray.push(link);
            }
        });

        filterResult.symbols = symbolsToShowArray;
        filterResult.links = linksToShowArray;

        return filterResult;
    }
}

export default FilterManager;