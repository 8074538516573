import * as THREE from 'three';

import { BaseInstancedMesh } from "../common/BaseInstancedMesh";
import { LEGENDS_FINAL_ZOOM, LEGENDS_INITIAL_ZOOM, LEGEND_HEIGHT, LEGEND_POSITION_Y_OFFSET, LEGEND_WIDTH, SCALE_FACTOR } from '../common/constants';
import { CosmosThree } from '../CosmosThree';
import { LegendsTextureGenerator } from '../utils/three/LegendsTextureGenerator';
import { BaseObject } from '../common/BaseObject';
import { SymbolLegend } from './SymbolLegend';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionAtlas } from '../utils/materials/extensions/ExtensionAtlas';
import { ExtensionOpacity } from '../utils/materials/extensions/ExtensionOpacity';

export class SymbolsLegendsIM extends BaseInstancedMesh{

	textureGenerator: LegendsTextureGenerator;
    texturePositions: THREE.InstancedBufferAttribute;

	textNeedsUpdate = false;

    constructor(size : number){

		const textureGenerator = new LegendsTextureGenerator();

        const geometry = new THREE.PlaneGeometry(LEGEND_WIDTH / SCALE_FACTOR, LEGEND_HEIGHT / SCALE_FACTOR, 1, 1);
        
        const texturePositions = new THREE.InstancedBufferAttribute(new Float32Array(size * 2), 2, true);

		geometry.setAttribute('texOffset', texturePositions);

		// We use a THREE.MeshBasicMaterial so the lighting doesn't affect the legends
		const material = new (ExtendedMaterial as any)(
            THREE.MeshBasicMaterial,
            [ExtensionAtlas, ExtensionOpacity],
            {
                map: textureGenerator.texture,
                atlasSize: new THREE.Vector2(textureGenerator.width, textureGenerator.height),
                texSize: new THREE.Vector2(textureGenerator.legendWidth, textureGenerator.legendHeight),
                transparent: true,
				depthWrite: false, // If this is removed something weird happens with the legends when they overlap each other
                //wireframe: true
            },
            { debug: false }
        );

        super(geometry, material, size);

		this.textureGenerator = textureGenerator;
        this.texturePositions = texturePositions;

		this.name = "legendsInstancedMesh";
    }

	override sync (){
		/* Logger.time('[perf] mesh: update matrices'); */
		for(let i = 0 ; i < this.elems.length; i++){
			if(this.visible){
				const elem = this.elems[i];
				this.syncMatrix(elem);
				if(elem.opacityNeedsUpdate){
                    this.syncOpacity(elem);
                }
			}
		}
		/* Logger.timeEnd('[perf] mesh: update matrices'); */

		if(this.textNeedsUpdate){
            for(let i = 0 ; i < this.elems.length; i++){
                const elem = this.elems[i] as SymbolLegend;
                if(elem.textNeedsUpdate){
                    this.syncText(elem);
                }
            }
        }

		// We want to make the legends disappear depending on camera zoom value
		this.updateOpacityFromZoom();
		
	}

	override syncMatrix(elem: BaseObject){
		if(elem.three.parent){
			this.vector.setFromMatrixPosition(elem.three.parent.matrix);

			this.vector.add(CosmosThree.globalMeshOffset);
			this.vector.project(CosmosThree.globalGraphCamera); // `camera` is a THREE.PerspectiveCamera

			this.vector.unproject(CosmosThree.globalGuiCamera);

			// Update the matrices of the objects in the scene
			this.vector.y -= CosmosThree.globalGraphCamera.zoom * LEGEND_POSITION_Y_OFFSET;

			this.mtx.makeTranslation(this.vector);

			// We apply the scale of the legend to efectively 'hide' the legend if necessary
			// The opacity is controlled by other systems so we can't mess with that to accomplish the 'hide'/'show'
			this.mtx.scale(elem.three.scale);

			this.setMatrixAt(elem.instanceId, this.mtx);

			this.instanceMatrix.needsUpdate = true;
		}
	}

	syncText(elem: SymbolLegend){
        elem.textNeedsUpdate = false;
		const legendImageData = this.textureGenerator.legendsPositions.get(elem.instanceId + "");
        if(legendImageData){
			this.setTextAt(elem.instanceId, legendImageData.x, legendImageData.y);
			this.texturePositions.needsUpdate = true;
		}
    }

	setTextAt(instanceId: number, x: number, y: number){
        this.texturePositions.array [ instanceId * 2 ] = x;
        this.texturePositions.array [ instanceId * 2 + 1 ] = y;
    }

	updateOpacityFromZoom() {
		const currentZoom = CosmosThree.globalGraphCamera.zoom;
		if(currentZoom < LEGENDS_FINAL_ZOOM){
			if(currentZoom < LEGENDS_INITIAL_ZOOM){
				this.visible = false;
				(this.material as THREE.Material).opacity = 0;
			}else{
				this.visible = true;
				// Ensure the zoom level is clamped within the initial and final zoom values
				const clampedZoom = Math.min(Math.max(currentZoom, LEGENDS_INITIAL_ZOOM), LEGENDS_FINAL_ZOOM);
			
				// Calculate the interpolation factor based on the current zoom level
				const interpolatedOpacity = (clampedZoom - LEGENDS_INITIAL_ZOOM) / (LEGENDS_FINAL_ZOOM - LEGENDS_INITIAL_ZOOM);
			
				// Set the opacity of the mesh
				(this.material as THREE.Material).opacity = interpolatedOpacity;
			}
		}else{
			this.visible = true;
			(this.material as THREE.Material).opacity = 1;
		}
	}

	override dispose(): this{
		this.textureGenerator.dispose();

		return super.dispose();
	}
}