import type { FormEvent } from 'react'
import type { Space } from '../../store/Space.ts'

import { Form } from 'react-router-dom'
import { useAppStore } from '../../store/Store.ts'
import { useState } from 'react'
import { SpinnerLogo } from '../../assets/icons/Spinner.tsx'

function SpaceForm({ space } : { space? : Partial<Space>}) {

  const { closeModal, createSpace, updateSpace } = useAppStore((state) => {
    return {
      closeModal: state.closeModal,
      createSpace: state.createSpace,
      updateSpace: state.updateSpace,
    }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(e.currentTarget);
    const spaceData: Partial<Space> = {
      name: formData.get('name') as string,
    }

    if (space?.id) {
      await updateSpace(space.id, spaceData);
    } else {
      await createSpace(spaceData);
    }

    setTimeout(() => {
      closeModal();
    }, 300);

    setTimeout(() => {
      setIsSubmitting(false);
    }, 500);
  }

  return (
    <Form className="ui-pointer-events p-6" onSubmit={onSubmit}>
      {/* {data && data.errors && (
        <ul>
          {Object.values(data.errors).map((err) => (
            <li key={err}>{err}</li>
          ))}
        </ul>
      )} */}
      <div className="flex gap-4 flex-col">
        <div>
          <h2 className="text-xl font-semibold leading-7">
            {space?.id ? `Update space ${space.name} ` : 'Create new Space'}
          </h2>
        </div>
        <div>
          <label htmlFor="name" className="block text-sm font-medium leading-6">
            Name
          </label>
          <div className="mt-2">
            <input
              id="name"
              type="text"
              name="name"
              required
              defaultValue={space ? space.name : ''}
              className="ui-input"
              placeholder=""
            />
          </div>
        </div>
        <div className="sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            className="ui-btn sm:col-start-2"
            disabled={isSubmitting}
          >
            {isSubmitting ? <SpinnerLogo /> : 'Save'}
          </button>
          <button
            type="button"
            className="ui-btn-outline mt-3 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            disabled={isSubmitting}
          >
            Cancel
          </button>
        </div>
      </div>
    </Form>
  );
}

export default SpaceForm;
