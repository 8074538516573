import { useAppStore } from '../../../store/Store.ts';
import { useDeepCompareEffect } from '../../../utils/hooks.ts';
import { useState, useMemo } from 'react';
import type { ActiveFilters, FilterItem } from '../../../store/Filter.ts';

export default function UseFiltersState() {

    const [activeFilters, setActiveFilters] = useState<ActiveFilters | null>(null);

    const {
        filterList,
        getActiveFilters,
    } = useAppStore((state) => {
        return {
            filterList: state.filterList,
            getActiveFilters: state.getActiveFilters,
        }
    })

    const _tempActiveFilters: ActiveFilters | null = useMemo(() => {
        return getActiveFilters();
    }, [filterList]);

    useDeepCompareEffect(() => {
        setActiveFilters(_tempActiveFilters);
    }, [_tempActiveFilters]);

    const flattenActiveFilters: FilterItem[] = useMemo(() => {
        if (!activeFilters) {
            return [];
        }
        return activeFilters.reduce((acc: FilterItem[], group) => {
            return acc.concat(group.items);
        }, [])
    }, [activeFilters]);

    const activeFiltersCount = useMemo(() => {
        if (!activeFilters) {
            return 0;
        }
        return activeFilters.reduce((acc, group) => {
            return acc + group.items.filter((item) => {
                return item.selected
            }).length;
        }, 0)
    }, [activeFilters]);

    const activeFiltersCountByGroup = (groupId: string) => {
        if (!activeFilters) {
            return 0;
        }
        return activeFilters.reduce((acc, group) => {
            if (group.id !== groupId) {
                return acc;
            }
            return acc + group.items.filter((item) => {
                return item.selected
            }).length;
        }, 0)
    };

    return {
        activeFilters,
        activeFiltersCount,
        activeFiltersCountByGroup,
        flattenActiveFilters
    }

}