type Props = {
    className?: string,
}

export const OpenUrl = ({className}: Props) => {
    return(
        <svg className={'w-4 h-4 ' + className || ''} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2669_1729)">
                <path d="M14.5334 6.38438V1.71771H9.8667" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M14.5333 1.71771L8 8.25105" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path
                    d="M6.13334 3.58438H4.26667C2.72014 3.58438 1.46667 4.83785 1.46667 6.38438V11.9844C1.46667 13.5309 2.72014 14.7844 4.26667 14.7844H9.86667C11.4132 14.7844 12.6667 13.5309 12.6667 11.9844V10.1177"
                    stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2669_1729">
                    <rect width="16" height="16" fill="transparent"/>
                </clipPath>
            </defs>
        </svg>

    )
}