import { BaseInstancedMesh } from "../common/BaseInstancedMesh";
import { LinkIndicator } from "./LinkIndicator";

export class LinksIndicatorsIM extends BaseInstancedMesh{

    constructor(size : number){
        const app = new LinkIndicator();

        super(app.getGeometry(), app.getMaterial(), size);

        app.dispose();

        this.castShadow = false; // May not be worth the performance cost as it's barely visible. But more important, when they are set transparent the shadow is still visible and there is no way around it... (tranparency in 3D world is always a pain in the ass).

        this.name = "indicatorsInstancedMesh";
    }
}