import { Repository } from "../../common/Repository";
import { TBaseSymbol } from "../../Mesh";

export const attachSymbolsToGroups = (symbols: TBaseSymbol[]) => {
    Repository.groups.forEach((group) => {
        const groupSymbols: TBaseSymbol[] = [];
        for(let i = 0; i < symbols.length; i++){
            if(symbols[i].groupId === group.id){
                groupSymbols.push(symbols[i]);
            }
        }
        group.symbols = groupSymbols;
    });
}