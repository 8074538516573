import {ActiveFilters, FilterItem} from '../store/Filter.ts';

import {FEATURES} from '../store/Features.ts';

export type FeaturesFromUrl = {
  [key in FEATURES]?: string
}

export const prepareQuery = (activeFilters: ActiveFilters): string => {
  const query = [];
  // filters
  const filterItems = activeFilters.reduce((acc: FilterItem[], group) => {
    return acc.concat(group.items);
  }, []);
  const filterQuery: string[] = []
  if (filterItems.length) {
    for (const filterGroup of activeFilters) {
      if (!filterGroup.items.length) {
        continue;
      }
      const groupId = filterGroup.id;
      filterQuery.push(`${groupId}:(${filterItems.map((item) => item.id).join(' OR ')})`);
    }
  }

  if (filterQuery.length) {
    query.push(`filters=${encodeURIComponent(filterQuery.join(' '))}`);
  }

  return query.join('&');
}


export const decodeFiltersFromQuery = (searchParams: URLSearchParams): Record<string, string[]> => {
  const decodedFilters: Record<string, string[]> = {};
  for (const [key, value] of searchParams.entries()) {
    switch (key) {
      case 'filters': {
        const filterGroups = value.split(')');
        filterGroups.forEach((group) => {
          if (!group.length) {
            return;
          }
          const [id, items] = group.split(':(');
          const groupId = id.trim();
          if (!decodedFilters[groupId]) {
            decodedFilters[groupId] = [];
          }
          const filterItems = items?.length ? items.split(' OR ') : [];
          filterItems.forEach((item) => {
            decodedFilters[groupId].push(item);
          })
        })
        break;
      }
    }
  }
  return decodedFilters;
}