type Props = {
    className?: string,
}

export const ArrowBoth = ({className}: Props) => {
    return (
        <svg className={'w-20 h-2 ' + (className || '')} viewBox="0 0 78 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.13965 4.00008L77.1396 4.00001M1.13965 4.00008L4.36143 1.33008M1.13965 4.00008L4.36143 6.67008 M77 4.00004L1 3.99998M77 4.00004L73.7782 1.33004M77 4.00004L73.7782 6.67004"
                  stroke="currentColor" strokeWidth="1.9" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

