import { BaseBatchedMesh } from '../common/BaseBatchedMesh';
import { LinkVirtualShape } from './LinkVirtualShape';

export class LinksVirtualShapesBM extends BaseBatchedMesh{
    constructor(size : number){
        const link = new LinkVirtualShape();

        super(size, size * 64 * 4, size * 64 * 2 * 4, link.getMaterial());

        // this.layers.enable( link.interactionLayer );

        this.castShadow = false;
        this.receiveShadow = false;

        link.dispose();

        this.name = "linksVirtualShapesBM";
    }
}