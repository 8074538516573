import { LoaderFunctionArgs, Outlet, redirect } from 'react-router-dom';

import { useAppStore } from '../../store/Store.ts'
import {LocalStorageKeys} from '../../types/common.ts';
import type {Space} from '../../store/Space.ts';

function SpaceDetail() {
	return (
	<>
		<Outlet />
	</>
	);
}

export default SpaceDetail;

export async function loader({params}: LoaderFunctionArgs) {
	if (!params.spaceId) {
		const spaces = await useAppStore.getState().fetchSpaces();
		if (!spaces || !spaces.length) {
			return null;
		}
		const spaceIds = spaces.map((space) => {
			return space.id;
		});
		let lastActiveFound: string | null = null;
		const activeIdFromLocalStorage = localStorage.getItem(LocalStorageKeys.LAST_ACTIVE_SPACE);
		if (activeIdFromLocalStorage) {
			spaces.forEach((space: Space) => {
				if (space.id === activeIdFromLocalStorage) {
					lastActiveFound = space.id;
					return;
				}
			});
		}
		if (lastActiveFound && spaceIds.includes(lastActiveFound)) {
			return redirect(`/spaces/${lastActiveFound}`);
		} else {
			return redirect(`/spaces/${spaces[0].id}`);
		}
	}

	let spaceData = null;
	if (useAppStore.getState().activeSpace?.id !== params.spaceId) {
		spaceData = await useAppStore.getState().fetchSpace(params.spaceId);
		if (spaceData) {
			useAppStore.getState().setActiveSpace(spaceData);
		}
	}

	return spaceData;
}