import type {UiPanel} from '@/store/UiPanel.ts';

import { useAppStore } from '@/store/Store.ts'
import {useMemo} from 'react';
import {UI_PANEL_POSITION} from '@/store/UiPanel.ts';
import {Transition} from '@headlessui/react';
import NodeDetail from './nodeDetail/NodeDetail.tsx';
import {Close} from '@/assets/icons/Close.tsx';

export default function RightPanels() {

    const { uiPanels, closeUiPanel } = useAppStore((state) => {
        return {
            uiPanels: state.uiPanels,
            closeUiPanel: state.closeUiPanel
        }
    });

    const rightPanels = useMemo(() => {
        return Array.from(uiPanels).reduce((acc: UiPanel[], mapItem) => {
            const panel = mapItem[1];
            if (panel.position === UI_PANEL_POSITION.RIGHT) {
                acc.push(panel)
            }
            return acc;
        }, [])
    }, [uiPanels]);

    return (
        <div id="rightPanel" className="right-0 mr-6 w-max absolute group flex flex-col gap-4">
            {rightPanels.map((panel) => (
                <Transition
                    key='nodeDetail'
                    show={panel.show}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div key={panel.id} className="ui-pointer-events flex justify-end">
                        <div className="absolute right-0 top-0 hidden pr-5 pt-5 sm:block">
                            <button
                                tabIndex={-1}
                                type="button"
                                className="ui-btn-icon-close"
                                onClick={() => closeUiPanel(panel.id)}
                            >
                                <Close />
                            </button>
                        </div>
                        <panel.component />
                    </div>
                </Transition>
            ))}
            <NodeDetail />
        </div>
    );

}