import * as THREE from 'three';

import * as signals from 'signals';

import { gsap } from "gsap";

import { Repository } from '../common/Repository';
import { INTERACTION_ANIMATION_SPEED, LINK_INDICATOR_VELOCITY, PREVENT_Z_FIGHTING } from '../common/constants';
import { BaseObject } from '../common/BaseObject';
import { ContextMenu } from '../utils/interaction/ContextMenu';
import { LinkIndicator } from './LinkIndicator';
import { LinkShape } from './LinkShape';
import { TBaseSymbol } from '../Mesh';

export interface SerializedLink {
	id: string;
	type: string;
	source?: string;
	target?: string;
	attributes: unknown;
	isDuplicate?: boolean;
	forShared?: boolean;
	allLinks: SerializedLink[];
}

export abstract class BaseLink extends BaseObject{
	readonly isLink = true;
	readonly isShared = false;
	readonly isVirtual = false;

	interactionLayer = 1;

	originalData: SerializedLink | null = null;

	private _type = "";

	protected _source?: TBaseSymbol;
	protected _target?: TBaseSymbol;

	private _shape: LinkShape;
	indicator: LinkIndicator;

	tlIndicator: gsap.core.Timeline;

	private _hover = false;
	private _click = false;
	private _doubleclick = false;
	private _select = false;

	private _visible = false;
	private _filtered = false;
	private _muted = false;
	private _spotlight = false;

	hovered: signals.Signal;
	unhovered: signals.Signal;

	clicked: signals.Signal;
	doubleclicked: signals.Signal;

	selected: signals.Signal;
	unselected: signals.Signal;

	contextmenued: signals.Signal;

	private opacityTo: gsap.QuickToFunc;

	private opacityIndicatorTo: gsap.QuickToFunc;

    constructor () {
        super();

		this.three.layers.set( this.interactionLayer );

		this._shape = new LinkShape();
		this._shape.three.position.y = PREVENT_Z_FIGHTING;
		this.three.add(this._shape.three);

		this.indicator = new LinkIndicator();
		this.three.add(this.indicator.three);

		this.hovered = new signals.Signal();
		this.unhovered = new signals.Signal();

		this.clicked = new signals.Signal();
		this.doubleclicked = new signals.Signal();

		this.selected = new signals.Signal();
		this.unselected = new signals.Signal();

		this.contextmenued = new signals.Signal();
		this.contextmenued.add(this.onContextMenu, this);

		// Quick tween functions to boost performance even more
		this.opacityTo = gsap.quickTo(this, "opacity", { duration: INTERACTION_ANIMATION_SPEED, ease: "none" });
    
		this.opacityIndicatorTo = gsap.quickTo(this.indicator, "opacity", { duration: INTERACTION_ANIMATION_SPEED, ease: "none" });

		this.tlIndicator = new gsap.core.Timeline({repeat: -1, repeatDelay: 0, paused: true});
	}

	public get type() {
		return this._type;
	}

	public set type(value) {
		if(value === this._type){return}
		this._type = value;

		this._shape.instancedOrBatchedMesh = Repository.linksShapesMesh!;
		this.indicator.instancedOrBatchedMesh = Repository.linksIndicatorsMesh!;
	}

	override get matrixNeedsUpdate() {
		return this._matrixNeedsUpdate;
	}
	override set matrixNeedsUpdate(value) {
		this._matrixNeedsUpdate = value;

		this._shape.matrixNeedsUpdate = value;
		this.indicator.matrixNeedsUpdate = value;
	}

	override get color() {
		return this._color;
	}
	override set color(value: THREE.Color) {
		if (value === this._color) return;
		this._color = value;
		this.colorNeedsUpdate = true;

		this._shape.color = value;
		this.indicator.color = value;
	}

	override get opacity() {
		return this._opacity;
	}
	override set opacity(value) {
		if(this._opacity === value) return;
		this._opacity = value;
		this.opacityNeedsUpdate = true;

		this._shape.opacity = value;
	}

	get hover() {
		return this._hover;
	}
	set hover(value) {
		if(this._hover === value){ return }

		this._hover = value;

		if(this._hover){
			this.hovered.dispatch(this);
		}else{
			this.unhovered.dispatch(this);
		}
	}

	get click() {
		return this._select;
	}

	set click(value) {
		if(this._filtered){ return }

		this._click = value;

		if(this._click){
			this.clicked.dispatch(this);
		}
	}

	get doubleclick() {
		return this._select;
	}

	set doubleclick(value) {
		if(this._filtered){ return }

		this._doubleclick = value;

		if(this._doubleclick){
			this.doubleclicked.dispatch(this);
		}
	}

	get select() {
		return this._select;
	}
	set select(value) {
		if(this._select === value){ return }

		this._select = value;

		if(this._select){
			this.selected.dispatch(this);
		}else{
			this.unselected.dispatch(this);
		}
	}

	get visible() {
		return this._visible;
	}
	set visible(value) {
		if(this._visible === value) return;

		this._visible = value;

		if(!this._filtered){
			if(!this._visible){
				this.muted = true;
				this.hideIn();
			}else{
				this.muted = false;
				this.hideOut();
			}
		}
	}

	get filtered() {
		return this._filtered;
	}

	set filtered(value) {
		if(this._filtered === value) return;

		this._filtered = value;

		if(this._visible){
			if(this._filtered){
				this.muted = true;
				this.hideIn();
			}else{
				this.muted = false;
				this.hideOut();
			}
		}
	}

	get muted() {
		return this._muted;
	}

	set muted(value) {
		if(this._filtered || !this.visible){ return }
		if(this._muted === value) return;

		this._muted = value;

		if(this._muted){
			this.muteIn();
		}else{
			this.muteOut();
		}
	}

	get spotlight() {
		return this._spotlight;
	}
	set spotlight(value) {
		if(this._filtered || !this.visible){ return }

		this._spotlight = value;

		if(this._spotlight){
			this.muted = false;
			this.opacityIndicatorTo(1);
			this.tlIndicator.play(0);
		}else{
			this.muted = false;
			this.opacityIndicatorTo(0);
			this.tlIndicator.pause(0);
		}
	}

	get source() {
		return this._source;
	}
	set source(value) {
		if (this._source === value) return;
		if (this._source) {
			this._source.unlinked.dispatch('output', this);
		}
		this._source = value;
		if (this._source) {
			this._source.linked.dispatch('output', this);
		}
	}

	get target() {
		return this._target;
	}

	set target(value) {
		if (this._target === value) return;
		if (this._target) {
			this._target.unlinked.dispatch('input', this);
		}
		this._target = value;
		if (this._target) {
			this._target.linked.dispatch('input', this);
		}
	}

	update(){
		if(!this.source || !this.target){ return }

		this.three.position.x = this.source.three.position.x;
		this.three.position.z = this.source.three.position.z;
		this.matrixNeedsUpdate = true;

		this._shape.updateGeometry(this.source, this.target);

		const prevColor = this.target.color.getHSL({} as THREE.HSL);
		prevColor.s = this.source.active ? prevColor.s : 0;
		this.color = new THREE.Color().setHSL(prevColor.h, prevColor.s, prevColor.l);
	
		if(!this.source.active || !this.target.active){ return }

		// Define start and end points
        const startPoint = new THREE.Vector3(0, 0, 0);
        const endPoint = this.target.three.position.clone().sub(this.source.three.position);

        // Calculate direction vector
        const direction = new THREE.Vector3().subVectors(endPoint, startPoint).normalize();

        // Calculate the angle of rotation around the Y-axis
        const angle = Math.atan2(direction.x, direction.z) + Math.PI;

        this.indicator.three.rotation.y = angle;

        // Calculate the length of the link
        const linkLength = startPoint.distanceTo(endPoint);

        // Set the duration based on the length of the link
        const duration = linkLength / LINK_INDICATOR_VELOCITY; // Define SOME_VELOCITY_CONSTANT based on your needs

		this.tlIndicator.clear();
        this.tlIndicator.add(gsap.to(this.indicator.three.position, {duration: duration, x: endPoint.x, z: endPoint.z, ease: "none", onUpdate: () => { this.matrixNeedsUpdate = true; } }));
	}

	onContextMenu(point: THREE.Vector2){
		const cm = new ContextMenu();
		this.createContextMenu(cm, point);
	}

	muteIn(){
		this.opacityTo(0);
	}

	muteOut(){
		this.opacityTo(1);
	}

	hide(){
		this.opacity = 0;
		this.indicator.opacity = 0;
	}

	hideIn(){
		this.opacityTo(0);

		this.opacityIndicatorTo(0);
	}

	hideOut(){
		this.opacityTo(1);

		this.opacityIndicatorTo(1);
	}

	protected createContextMenu(cm: ContextMenu, point: THREE.Vector2): void {
		if (this.type) {
			cm.addItem(`Link type: ${this.type}`, 'arrow-up-right-from-square', () => {
				//
			})
		}

		cm.updatePosition(point);
	}

	toJSON(): SerializedLink {
		return {
			id: this.id,
			type: Repository.typeOf(this),
			source: this.source?.id,
			target: this.target?.id,
			attributes: {},
			allLinks: this.originalData?.allLinks || []
		}
	}

	fromJSON(object: SerializedLink): void {
		this.originalData = object;
		this.id = object.id;
	}

	override dispose(){
		this.hovered.removeAll();
		this.unhovered.removeAll();
		this.selected.removeAll();
		this.unselected.removeAll();
		this.contextmenued.removeAll();

		this._shape?.dispose();
		this.indicator?.dispose();

		super.dispose();
	}
}