import {useAppStore} from '../../../store/Store.ts';
import FormGenerator from '../../form/FormGenerator.tsx';
import {Cog6ToothIcon} from '@heroicons/react/24/outline';
import {LayoutCacheManager} from '../../../three/utils/layout/LayoutCacheManager.ts';
import ThemeManager from '../../../utils/ThemeManager.ts';

export default function FeaturesPanel() {

    const {
        featuresDefinition,
        setFeatureValue,
        features,
        activeTheme,
        setTheme,
        updateThemeVariable,
    } = useAppStore((state) => {
        return {
            featuresDefinition: state.featuresDefinition,
            setFeatureValue: state.setFeatureValue,
            features: state.features,
            activeTheme: state.activeTheme,
            setTheme: state.setTheme,
            updateThemeVariable: state.updateThemeVariable
        }
    });

    const onChange = (inputName: string, value: string | boolean | number) => {
        setFeatureValue(inputName, value);
    }

    const clearFeaturesLocalStorage = () => {
        localStorage.removeItem('global:features');
        LayoutCacheManager.clearStorage();
        window.location.reload();
    }

    const onThemeSettingsChange = (inputName: string, value: string | boolean | number) => {
        switch (inputName) {
            case 'theme': {
                setTheme(value as string);
                break;
            }
            default: {
                updateThemeVariable(inputName, value as string);
                break;
            }
        }
    };

    return (
        <div className="ui-panel w-[24rem] py-sm">
            <div className="mb-4 px-sm flex items-center gap-2">
                <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                <h2 className="text-xl font-semibold leading-7">
                    Features / Settings
                </h2>
            </div>
            <div className="max-h-[50vh] px-sm overflow-auto flex flex-col gap-2">
                <FormGenerator groups={featuresDefinition} data={features} onInputChange={onChange} />
                <FormGenerator groups={ThemeManager.getThemeSettingsForm()} data={{theme: activeTheme.id}} onInputChange={onThemeSettingsChange} />
            </div>
            <div className="mt-4 px-sm flex justify-end">
                <button
                    type="button"
                    onClick={clearFeaturesLocalStorage}
                    className="ui-btn-outline leading-normal text-base-content-500"
                >
                    Clear local storage
                </button>
            </div>
        </div>
    );
}