import {useAppStore} from '../../store/Store.ts';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {PowerIcon} from '@heroicons/react/24/solid';
import {Cog6ToothIcon} from '@heroicons/react/24/outline';
import supabase from '../../utils/supabase-client';
import {useNavigate} from 'react-router-dom';
import {UI_PANEL_POSITION, UI_PANELS} from '../../store/UiPanel.ts';
import FeaturesPanel from './features/FeaturesPanel.tsx';
import {Logger} from '../../utils/Logger';

export default function UserMenu() {

    const navigate = useNavigate();

    const { user, openUiPanel } = useAppStore((state) => {
        return {
            user: state.user,
            openUiPanel: state.openUiPanel
        }
    });

    const logOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            Logger.error(`Error signing out: ${error}`);
        }
        return navigate('/');
    }

    return (
        <Menu as="div" className="ui-panel ui-pointer-events items-center">
            <div>
                <MenuButton title={user?.email} className="flex items-center outline-none">
                    {user && (
                        <img
                            className="inline-block rounded-box h-10 w-10"
                            referrerPolicy="no-referrer"
                            src={user.avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name)}&size=128&background=fff`}
                            alt={user.name}
                        />
                    )}
                </MenuButton>
            </div>
            <MenuItems
                anchor="bottom end"
                transition
                className="ui-listbox-options w-fit max-w-60 right-6 z-10 mt-2 origin-top-right transition duration-100 ease-in-out data-[leave]:data-[closed]:opacity-0"
            >
                <div className="">
                    <MenuItem>
                        <div
                            tabIndex={-1}
                            className={'data-[focus]:bg-base-200 cursor-pointer tabindex flex w-full px-4 py-2 text-left'}
                            onClick={() => openUiPanel(UI_PANELS.FEATURES, UI_PANEL_POSITION.RIGHT, FeaturesPanel)}
                        >
                            <Cog6ToothIcon className={'mr-3 h-5 w-5'} />
                            Settings / Features
                        </div>
                    </MenuItem>
                    <MenuItem>
                        <div
                            tabIndex={-1}
                            className={'data-[focus]:bg-base-200 cursor-pointer flex w-full px-4 py-2 text-left'}
                            onClick={logOut}
                        >
                            <PowerIcon className={'mr-3 h-5 w-5'} />
                            Sign out
                        </div>
                    </MenuItem>
                </div>
            </MenuItems>
        </Menu>
    )
}