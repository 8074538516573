import { BaseBatchedMesh } from '../common/BaseBatchedMesh';
import { LinkSharedShape } from './LinkSharedShape';

export class LinksSharedShapesBM extends BaseBatchedMesh{
    constructor(size : number){
        const link = new LinkSharedShape();

        super(size, size * 64, size * 64 * 2, link.getMaterial());

        this.layers.enable( link.interactionLayer );

        this.castShadow = false;
        this.receiveShadow = false;

        link.dispose();

        this.name = "linksSharedShapesBM";
    }
}