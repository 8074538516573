import { AppEnv } from './AppEnv.ts';

export class Logger {

    static time(label: string) {
        if (AppEnv.getMode() !== 'production') {
            console.time(label);
        }
    }

    static timeEnd(label: string) {
        if (AppEnv.getMode() !== 'production') {
            console.timeEnd(label);
        }
    }
    static log(...args: unknown[]) {
        if (AppEnv.getMode() !== 'production') {
            console.log(...args);
        }
    }

    static warn(...args: unknown[]) {
        console.warn(...args);
    }

    static error(...args: unknown[]) {
        console.error(...args);
    }

    static debug(...args: unknown[]) {
        if (AppEnv.getMode() !== 'production') {
            console.debug(...args);
        }
    }

}