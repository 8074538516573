import * as THREE from 'three';

export const ExtensionBatchedOpacity = {
    name: "extension-batched-opacity",
    uniforms: {
      opacitiesTexture: new THREE.Texture()
    },
    vertexShader: (shader: any) => {
      shader = `
        uniform highp sampler2D opacitiesTexture; // Define opacity texture
        varying float vInstOpacity; // Define varying variable to pass to fragment shader
        ${
            shader.replace(
              `#include <batching_vertex>`,
              `
              #include <batching_vertex>

              #ifdef USE_BATCHING
                vInstOpacity = texelFetch( opacitiesTexture, ivec2( 0, getIndirectIndex( gl_DrawID ) ), 0 ).r;
              #endif
				      `
            )
        }
      `;
      return shader;
    },
    fragmentShader: (shader: any) => {
      shader = `
        varying float vInstOpacity; // Receive opacity value from vertex shader
        ${
            shader.replace(
              '#include <color_fragment>',
              `
              #include <color_fragment>

              diffuseColor *= vInstOpacity * opacity;
              `
            )
        }
      `;
      return shader;
    },
};