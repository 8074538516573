import type { FormItem } from '../../types/form.ts'
import type { FormGeneratorOnChange, FormGeneratorData } from './FormGenerator.tsx'
import type {ChangeEvent} from 'react';
import {useState} from 'react';
import InfoBlock from './utils/InfoBlock.tsx';

function Slider({parameters, data, onChange}: { parameters: FormItem, data?: FormGeneratorData, onChange?: FormGeneratorOnChange }) {

  const defaultValue = data?.[parameters.name] ?? (parameters.defaultValue as number ?? 0);

  const [value, setValue] = useState(defaultValue);

  const changed = (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
     if (onChange) {
         onChange(parameters.name, parseFloat(e.target.value));
     }
  }

  return (
      <div>
          <label htmlFor={parameters.name} className="flex justify-between font-medium leading-6">
              <div>{parameters.label}</div>
              <div className="flex-shrink-0 ml-2">{value as string}</div>
          </label>
          {(parameters?.info) && (
            <InfoBlock text={parameters?.info} />
          )}
          <div className="mt-2">
              <input
                  className="w-full h-2 bg-base-300 rounded-lg appearance-none cursor-pointer"
                  id={parameters.name}
                  name={parameters.name}
                  type="range"
                  min={parameters.attributes?.min ?? 0}
                  max={parameters.attributes?.max ?? 10}
                  step={parameters.attributes?.step ?? 1}
                  value={value as number}
                  onChange={changed}
              />
          </div>
      </div>
  )
}

export default Slider;