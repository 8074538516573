type Props = {
    className?: string,
}

export const Home = ({className}: Props) => {
    return (
        <svg className={'h-4 w-4 box-content ' + className || ''} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.80597 10.0833C9.80597 9.08584 8.99741 8.27728 8 8.27728C7.00259 8.27728 6.19403 9.08584 6.19403 10.0833H9.80597ZM9.80597 13.5V10.0833H6.19403V13.5H9.80597Z"
                fill="currentColor" fillOpacity="0.6"/>
            <path
                d="M2.64402 4.24387L6.78625 1.39271C7.56547 0.856352 8.59646 0.862716 9.36901 1.40865L13.3786 4.24209C13.9773 4.66516 14.3332 5.3526 14.3332 6.08568V11.2425C14.3332 12.4893 13.3225 13.5 12.0758 13.5H3.92397C2.6772 13.5 1.6665 12.4893 1.6665 11.2425V6.10341C1.6665 5.36048 2.03204 4.66511 2.64402 4.24387Z"
                stroke="currentColor" strokeWidth="1.80597"/>
        </svg>
    )
}
