type Props = {
    className?: string,
}

export const Folders = ({className}: Props) => {
    return (
        <svg className={'h-4 w-4' + className || ''} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.19714 2.63077V2.63077C5.19714 2.2824 5.47955 2 5.82791 2H9.47922C9.74958 2 10.01 2.10186 10.2086 2.2853L11.275 3.2701C11.4736 3.45353 11.734 3.5554 12.0044 3.5554L14.8749 3.5554C15.4687 3.5554 15.95 4.03673 15.95 4.63048V10.3899C15.95 10.7123 15.6887 10.9736 15.3663 10.9736V10.9736"
                stroke="currentColor" strokeOpacity="0.4" strokeWidth="2.15017" strokeLinecap="round"/>
            <path
                d="M0.415283 6.64684V13.2629C0.415283 14.4504 1.37794 15.413 2.56545 15.413H11.121C12.3085 15.413 13.2711 14.4504 13.2711 13.2629V8.62174C13.2711 7.43424 12.3085 6.47157 11.121 6.47157H8.38818C7.81427 6.47157 7.26417 6.24213 6.86035 5.83433L6.16678 5.13393C5.76296 4.72612 5.21286 4.49668 4.63894 4.49668H2.56545C1.37795 4.49668 0.415283 5.45934 0.415283 6.64684Z"
                fill="currentColor"/>
        </svg>
    )
}
