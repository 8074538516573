import * as THREE from 'three';

import { gsap } from "gsap";

import { Repository } from '../common/Repository';
import { INTERACTION_ANIMATION_SPEED } from '../common/constants';
import { BaseObject } from '../common/BaseObject';
import { TBaseSymbol } from '../Mesh';
import { LinkSharedShape } from './LinkSharedShape';

export interface SerializedLink {
	id: string;
	type: string;
	source?: string;
	target?: string;
	attributes: unknown;
}

export abstract class BaseSharedLink extends BaseObject{
	readonly isLink = true;
	readonly isShared = true;
	readonly isVirtual = false;

	interactionLayer = 1;

	originalData: SerializedLink | null = null;

	private _type = "";

	protected _source?: TBaseSymbol;
	protected _target?: TBaseSymbol;

	private _shape: LinkSharedShape;

	private _visible = false;
	protected _filtered = false;
	private _muted = false;
	private _spotlight = false;

	private opacityTo: gsap.QuickToFunc;

    constructor () {
        super();

		this.three.layers.set( this.interactionLayer );

		this._shape = new LinkSharedShape();
		this.three.add(this._shape.three);

		// Quick tween functions to boost performance even more
		this.opacityTo = gsap.quickTo(this, "opacity", { duration: INTERACTION_ANIMATION_SPEED, ease: "none" });
	}

	public get type() {
		return this._type;
	}

	public set type(value) {
		if(value === this._type){return}
		this._type = value;

		this._shape.instancedOrBatchedMesh = Repository.sharedLinksShapesMesh!;
	}

	override get matrixNeedsUpdate() {
		return this._matrixNeedsUpdate;
	}
	override set matrixNeedsUpdate(value) {
		this._matrixNeedsUpdate = value;

		this._shape.matrixNeedsUpdate = value;
	}

	override get color() {
		return this._color;
	}
	override set color(value: THREE.Color) {
		if (value === this._color) return;
		this._color = value;
		this.colorNeedsUpdate = true;

		this._shape.color = value;
	}

	override get opacity() {
		return this._opacity;
	}
	override set opacity(value) {
		if(this._opacity === value) return;
		this._opacity = value;
		this.opacityNeedsUpdate = true;

		this._shape.opacity = value;
	}

	get visible() {
		return this._visible;
	}
	set visible(value) {
		if(this._visible === value) return;

		this._visible = value;

		if(!this.filtered){
			if(!this._visible){
				this.hideIn();
			}else{
				this.hideOut();
			}
		}
	}

	get filtered() {
		return this._filtered;
	}
	set filtered(value) {
		if(this._filtered === value) return;

		this._filtered = value;

		if(this._visible){
			if(this._filtered){
				this.hideIn();
			}else{
				this.hideOut();
			}
		}
	}

	get muted() {
		return this._muted;
	}

	set muted(value) {
		if(this._filtered || !this.visible){ return }
		if(this._muted === value) return;

		this._muted = value;
	}

	get spotlight() {
		return this._spotlight;
	}
	set spotlight(value) {
		if(this._filtered || !this.visible){ return }

		this._spotlight = value;
	}

	get source() {
		return this._source;
	}
	set source(value) {
		if (this._source === value) return;
		if (this._source) {
			this._source.unlinked.dispatch('output', this);
		}
		this._source = value;
		if (this._source) {
			this._source.linked.dispatch('output', this);
		}
	}

	get target() {
		return this._target;
	}

	set target(value) {
		if (this._target === value) return;
		if (this._target) {
			this._target.unlinked.dispatch('input', this);
		}
		this._target = value;
		if (this._target) {
			this._target.linked.dispatch('input', this);
		}
	}

	update(){
		if(!this.source || !this.target){ return }

		this.three.position.x = this.source.three.position.x;
		this.three.position.z = this.source.three.position.z;
		this.matrixNeedsUpdate = true;

		this._shape.updateGeometry(this.source, this.target);

		const prevColor = this.target.color.getHSL({} as THREE.HSL);
		prevColor.s = this.source.active ? prevColor.s : 0;
		this.color = new THREE.Color().setHSL(prevColor.h, prevColor.s, prevColor.l).lerp(new THREE.Color(0xffffff), 0.1);
	}

	hide(){
		this.opacity = 0;
	}

	hideIn(){
		this.opacityTo(0);
	}

	hideOut(){
		this.opacityTo(1);
	}

	toJSON(): SerializedLink {
		return {
			id: this.id,
			type: Repository.typeOf(this),
			source: this.source?.id,
			target: this.target?.id,
			attributes: {}
		}
	}

	fromJSON(object: SerializedLink): void {
		this.originalData = object;
		this.id = object.id;
	}

	override dispose(){
		this._shape?.dispose();

		super.dispose();
	}
}