import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, InformationCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { NotificationType } from '../../store/Notification.ts'
import { useAppStore } from '../../store/Store.ts'
import { SpinnerLogo } from '../../assets/icons/Spinner.tsx'

export default function Notifications() {

  const { notifications, removeNotification } = useAppStore((state) => {
    return {
      notifications: state.notifications,
      removeNotification: state.removeNotification
    }
  });

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-end sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            {notifications.map((item) => (
              <Transition
                key={item.id}
                show={item.show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="ui-pointer-events w-full max-w-sm overflow-hidden rounded-lg bg-base-50 shadow-md">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        {item.type === NotificationType.INFO && (
                          <InformationCircleIcon className="h-6 w-6 text-info-content" aria-hidden="true" />
                        )}
                        {item.type === NotificationType.SUCCESS && (
                          <CheckCircleIcon className="h-6 w-6 text-success-content" aria-hidden="true" />
                        )}
                        {item.type === NotificationType.WARNING && (
                          <ExclamationCircleIcon className="h-6 w-6 text-warning-content" aria-hidden="true" />
                        )}
                          {item.type === NotificationType.ERROR && (
                              <XCircleIcon className="h-6 w-6 text-error" aria-hidden="true" />
                          )}
                          {item.type === NotificationType.LOADING && (
                              <SpinnerLogo />
                          )}
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="font-medium">{item.title}</p>
                        {item.message && (
                          <p className="mt-1 text-base-content-500">{item.message}</p>
                        )}
                      </div>
                        {item.type !== NotificationType.LOADING && (
                          <div className="ml-4 flex flex-shrink-0">
                            <button
                              type="button"
                              className="ui-btn-icon-close"
                              onClick={() => {
                                removeNotification(item.id)
                              }}
                            >
                              <span className="sr-only">Close</span>
                              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </Transition>
            ))}
        </div>
      </div>
    </>
  )
}