import * as THREE from 'three';
import { BaseObject } from './BaseObject';

export class BaseBatchedMesh extends THREE.BatchedMesh{

    elems: BaseObject[] = [];

    protected _greyscalesTexture: THREE.DataTexture | null = null;
    protected _opacitiesTexture: THREE.DataTexture | null = null;
    protected _drawProgressTexture: THREE.DataTexture | null = null;

    matrixNeedsUpdate = false;
	colorNeedsUpdate = false;
    opacityNeedsUpdate = false;
    greyscaleNeedsUpdate = false;
    drawProgressNeedsUpdate = false;

    protected mtx = new THREE.Matrix4();

    constructor (maxInstanceCount: number, maxVertexCount: number, maxIndexCount: number, material: THREE.Material){
        super(maxInstanceCount, maxVertexCount, maxIndexCount, material);

        // this.matrixAutoUpdate = false;
        // this.matrixWorldAutoUpdate = false;

        if((this.material as any).uniforms.greyscalesTexture){this.initGreyScaleTexture();}
        if((this.material as any).uniforms.opacitiesTexture){this.initOpacityTexture();}
        if((this.material as any).uniforms.drawProgressTexture){this.initDrawProgressTexture();}
    }

    initGreyScaleTexture(){
        const greyscalesTexture = new THREE.DataTexture( new Float32Array( this.maxInstanceCount ).fill( 0.0 ), 1, this.maxInstanceCount, THREE.RedFormat, THREE.FloatType );
		this._greyscalesTexture = greyscalesTexture;

        (this.material as any).uniforms.greyscalesTexture.value = this._greyscalesTexture;
    }
    
    initOpacityTexture(){
        const opacitiesTexture = new THREE.DataTexture( new Float32Array( this.maxInstanceCount ).fill( 0.0 ), 1, this.maxInstanceCount, THREE.RedFormat, THREE.FloatType );
		this._opacitiesTexture = opacitiesTexture;

        (this.material as any).uniforms.opacitiesTexture.value = this._opacitiesTexture;
    }

    initDrawProgressTexture(){
        const drawProgressTexture = new THREE.DataTexture( new Float32Array( this.maxInstanceCount ).fill( 0.0 ), 1, this.maxInstanceCount, THREE.RedFormat, THREE.FloatType );
		this._drawProgressTexture = drawProgressTexture;

        (this.material as any).uniforms.drawProgressTexture.value = this._drawProgressTexture;
    }

    sync(){
        if(this.matrixNeedsUpdate || this.colorNeedsUpdate || this.opacityNeedsUpdate || this.greyscaleNeedsUpdate || this.drawProgressNeedsUpdate){
            for(let i = 0 ; i < this.elems.length; i++){
                const elem = this.elems[i];
                if(elem.matrixNeedsUpdate){
                    this.syncMatrix(elem);
                }
                if(elem.colorNeedsUpdate){
                    this.syncColor(elem);
                }
                if(this._opacitiesTexture && elem.opacityNeedsUpdate){
                    this.syncOpacity(elem);
                }
                if(this._greyscalesTexture && elem.greyscaleNeedsUpdate){
                    this.syncGreyscale(elem);
                }
                if(this._drawProgressTexture && elem.drawProgressNeedsUpdate){
                    this.syncDrawProgress(elem);
                }
            }
            
            this.computeBoundingSphere();
        }
    }

    syncMatrix(elem: BaseObject){
        elem.matrixNeedsUpdate = false;

        if(elem.three.parent){
            elem.three.updateMatrix();
            elem.three.parent.updateMatrix();
            this.setMatrixAt(elem.instanceId, this.mtx.multiplyMatrices(elem.three.parent.matrix, elem.three.matrix));
        }else{
            elem.three.updateMatrix();
            this.setMatrixAt(elem.instanceId, elem.three.matrix);
        }
	}

    syncColor(elem: BaseObject){
        elem.colorNeedsUpdate = false;
        this.setColorAt(elem.instanceId, elem.color);
    }

    syncGreyscale(elem: BaseObject){
        elem.greyscaleNeedsUpdate = false;
        this.setGreyscaleAt(elem.instanceId, elem.greyscale);
    }

    setGreyscaleAt( instanceId: number, greyscale: number ) {
        const greyscalesTexture = this._greyscalesTexture!;
		const greyscalesArray = this._greyscalesTexture!.image.data;
		const drawInfo = (this as any)._drawInfo;
		if ( instanceId >= drawInfo.length || drawInfo[ instanceId ].active === false ) {
			return this;
		}

		greyscalesArray[ instanceId ] = greyscale;
		greyscalesTexture.needsUpdate = true;

		return this;
	}

    syncOpacity(elem: BaseObject){
        elem.opacityNeedsUpdate = false;

        this._opacitiesTexture!.needsUpdate = true;

        this.setOpacityAt(elem.instanceId, elem.opacity);
    }

    setOpacityAt( instanceId: number, opacity: number ) {
        const opacitiesTexture = this._opacitiesTexture!;
		const opacitiesArray = this._opacitiesTexture!.image.data;
		const drawInfo = (this as any)._drawInfo;

		if ( instanceId >= drawInfo.length || drawInfo[ instanceId ].active === false ) {
			return this;
		}

		opacitiesArray[ instanceId ] = opacity;
		opacitiesTexture.needsUpdate = true;

		return this;
	}

    syncDrawProgress(elem: BaseObject){
        elem.drawProgressNeedsUpdate = false;
        this.setDrawProgressAt(elem.instanceId, elem.drawProgress);
    }

    setDrawProgressAt( instanceId: number, drawProgress: number ) {
        const drawProgressTexture = this._drawProgressTexture!;
		const drawProgressArray = this._drawProgressTexture!.image.data;
		const drawInfo = (this as any)._drawInfo;
		if ( instanceId >= drawInfo.length || drawInfo[ instanceId ].active === false ) {
			return this;
		}

		drawProgressArray[ instanceId ] = drawProgress;
		drawProgressTexture.needsUpdate = true;

		return this;
	}

    override dispose() : this{
        this.geometry.dispose();
		(this.material as THREE.Material).dispose();

        if(this._greyscalesTexture){
            this._greyscalesTexture!.dispose();
            this._greyscalesTexture = null;
        }

        if(this._opacitiesTexture){
            this._opacitiesTexture!.dispose();
            this._opacitiesTexture = null;
        }

        if(this._drawProgressTexture){
            this._drawProgressTexture!.dispose();
            this._drawProgressTexture = null;
        }

        this.elems = [];

        return super.dispose();
    }

}