import type { PostgrestError } from '@supabase/supabase-js'

export type DbResult<T> = T extends PromiseLike<infer U> ? U : never
export type DbResultOk<T> = T extends PromiseLike<{ data: infer U }> ? Exclude<U, null> : never
export type DbResultErr = PostgrestError

export type Json = string | number | boolean | null | { [key: string]: Json | undefined } | Json[];
export type Nullable<T> = T | null | undefined;
export type SupabaseResponse<T> = { data: Nullable<T>, error: Nullable<PostgrestError>}

export type Primitive = string | number | boolean | bigint | symbol | undefined | null;

export enum LocalStorageKeys {
  LAST_ACTIVE_SPACE = 'lastActiveSpace',
}