import * as THREE from 'three';
import { Repository } from '../../common/Repository';
import { useAppStore } from '../../../store/Store';

export class ContextMenu {
	public readonly element: HTMLUListElement;
	static instance?: ContextMenu;
	private _coords = new THREE.Vector2(0, 0);

	constructor() {
		if (ContextMenu.instance) {
			ContextMenu.instance.close();
		}

		ContextMenu.instance = this;

		this.element = document.createElement('ul');
		this.element.classList.add('context-menu');
		this.element.classList.add('list-unstyled');

		document.body.appendChild(this.element);

		// When the context menu is open we disable the interaction until it's closed
		// With that we don't need to synch the context menu position with the elements, and we prevent unnecessary handling of edge cases like the context menu reaching the borders of the viewport,etc
		Repository.mesh?.disableInteraction();
		useAppStore.getState().disableUIInteractions();

		document.body.addEventListener('click', () => {
			if (!ContextMenu.instance) return;
			
			ContextMenu.instance.close();
			ContextMenu.instance = undefined;

			// We close the context menu 
			Repository.mesh?.enableInteraction();
			useAppStore.getState().enableUIInteractions();
		});
	}

	get x() {
		return this._coords.x;
	}

	get y() {
		return this._coords.y;
	}

	addItem(title: string, icon: string, handler: () => void) {
		const li: HTMLLIElement = document.createElement('li');
		const i: HTMLElement = document.createElement('i');
		this.element.appendChild(li);

		i.classList.add('fa-regular');
		i.classList.add('fa-fw');
		if (icon) i.classList.add(`fa-${icon}`);

		li.innerText = title;
		li.addEventListener('click', event => {
			event.preventDefault();
			this.close();
			handler();
			return false;
		});
		li.prepend(i);
	}

	updatePosition(coords: THREE.Vector2){
		this._coords = coords;

		// We handle all edge cases when the context menu can't fit when too close to viewport borders
		const bodyRect = document.body.getBoundingClientRect();
		const contextMenuRect = this.element.getBoundingClientRect();

		this.element.style.left = `auto`;
		this.element.style.top = `auto`;
		this.element.style.right = `auto`;
		this.element.style.bottom = `auto`;

		if(this._coords.x + contextMenuRect.width > bodyRect.width){
			this.element.style.right = `${Math.round(bodyRect.width - this._coords.x)}px`;
		}else{
			this.element.style.left = `${Math.round(this._coords.x)}px`;
		}

		if(this._coords.y + contextMenuRect.height > bodyRect.height){
			this.element.style.bottom = `${Math.round(bodyRect.height - this._coords.y)}px`;
		}else{
			this.element.style.top = `${Math.round(this._coords.y)}px`;
		}
	}

	close() {
		this.element.parentElement?.removeChild(this.element);
	}
}
