import type {BaseSymbol} from '@/three/symbols/BaseSymbol.ts';
import type {BaseLink} from '@/three/links/BaseLink.ts';
import {useState, useEffect, Fragment, ChangeEvent} from 'react';
import NodeLink from './NodeLink.tsx';
import {DottedLine} from '@/assets/icons/DottedLine.tsx';
import {ArrowDown} from '@/assets/icons/ArrowDown.tsx';
import {Search} from '@/assets/icons/Search.tsx';
import {useAppStore} from '@/store/Store.ts';
import {linkTypeMap} from '@/utils/link.ts';

type Props = {
    nodeDetail: BaseSymbol;
    showSearch?:boolean;
    limit: number;
    showFiltered?: boolean;
    limitFiltered?: number
    showCount?: boolean;
    onShowAll?: () => void;
}

type LinkItem = {
    node: BaseSymbol,
    link: BaseLink
}

const resolveLinksInOtherFolders = (
    nodeDetail: BaseSymbol,
    virtualLink: BaseLink,
    linksMap: Map<string, LinkItem>
): void => {
    let inputLinks: BaseLink[] = [];
    let outputLinks: BaseLink[] = [];
    let node: BaseSymbol | undefined;
    if (virtualLink.source!.id === nodeDetail.id) {
        inputLinks = virtualLink.target!.inputLinks as BaseLink[];
        outputLinks = virtualLink.target!.outputLinks as BaseLink[];
        node = virtualLink.target! as BaseSymbol;
    } else if (virtualLink.target!.id === nodeDetail.id) {
        inputLinks = virtualLink.source!.inputLinks as BaseLink[];
        outputLinks = virtualLink.source!.outputLinks as BaseLink[];
        node = virtualLink.source! as BaseSymbol;
    }
    if (!node) {
        return;
    }
    const linksArrays = [inputLinks,outputLinks];
    linksArrays.forEach((linkArray) => {
        linkArray.forEach((link) => {
            if (link.isShared || link.isVirtual) {
                return;
            }
            linksMap.set(link.id, {
                node: node,
                link: link,
            });
        });
    })
}

const filterLinksByQuery = (query: string) => {
    return (link: LinkItem): boolean => {
        const linkTypes = [];
        const mainType = linkTypeMap[link.link.type];
        if (mainType) {
            linkTypes.push(mainType.id);
        }
        if (link.link.originalData?.allLinks?.length) {
            for (let i = 0; i < link.link.originalData?.allLinks.length; i++) {
                const anotherLink = link.link.originalData?.allLinks[i];
                const type = linkTypeMap[anotherLink.type];
                if (type && !linkTypes.includes(type.id)) {
                    linkTypes.push(type.id);
                }
            }
        }
        const found = linkTypes.find((typeId) => {
            return typeId.includes(query);
        });
        if (found) {
            return true;
        }
        if (link.link.target!.id === link.node.id) {
            // check source title
            return (link.link.source! as BaseSymbol).title.toLowerCase().includes(query)
        } else {
            // check target title
            return (link.link.target! as BaseSymbol).title.toLowerCase().includes(query)
        }
    };
}

export default function NodeLinks({nodeDetail, showSearch, limit, showFiltered, limitFiltered, showCount, onShowAll}: Props) {

    const { uiInteractive } = useAppStore((state) => {
        return {
            uiInteractive: state.uiInteractive
        }
    });

    const [visibleLinksCount, setVisibleLinksCount] = useState(0);
    const [filteredLinksCount, setFilteredLinksCount] = useState(0);
    const [visibleLinks, setVisibleLinks] = useState<LinkItem[]>([]);
    const [filteredLinks, setFilteredLinks] = useState<LinkItem[]>([]);
    const [showAllFilteredLinks, setShowAllFilteredLinks] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const query = searchQuery && searchQuery.length > 1 ? searchQuery : '';
        const linkItems: Map<string, LinkItem> = new Map();
        const linkItemsInFolders: Map<string, LinkItem> = new Map();

        let links: LinkItem[] = [];
        const linksArrays = [];
        if (nodeDetail.inputLinks.length) {
            linksArrays.push(nodeDetail.inputLinks);
        }
        if (nodeDetail.outputLinks.length) {
            linksArrays.push(nodeDetail.outputLinks);
        }
        linksArrays.forEach((linkArray) => {
            linkArray.forEach((link) => {
                if (link.isShared) {
                    return;
                } else if (link.isVirtual) {
                    resolveLinksInOtherFolders(nodeDetail, link, linkItemsInFolders);
                } else {
                    linkItems.set(link.id, {
                        node: nodeDetail,
                        link: link,
                    });
                }
            });
        });

        links = links.concat(Array.from(linkItems.values()));
        links = links.concat(Array.from(linkItemsInFolders.values()));

        let vLinks: LinkItem[] = [];
        let fLinks: LinkItem[] = [];

        for (let i = 0; i < links.length; i++) {
            const link = links[i];
            if (link.link.filtered && showFiltered) {
                fLinks.push(link);
            } else {
                vLinks.push(link);
            }
        }

        setVisibleLinksCount(vLinks.length);
        setFilteredLinksCount(fLinks.length)

        if (!query.length) {
            if (limit && limit > 0 && vLinks.length > limit) {
                vLinks = vLinks.slice(0, limit);
            }
            if (!showAllFilteredLinks && showFiltered && limitFiltered && limitFiltered > 0 && fLinks.length > limitFiltered) {
                fLinks = fLinks.slice(0, limitFiltered);
            }
        } else {
            vLinks = vLinks.filter(filterLinksByQuery(query));
            fLinks = fLinks.filter(filterLinksByQuery(query));
        }

        setVisibleLinks(vLinks);
        setFilteredLinks(fLinks);

    }, [showAllFilteredLinks, searchQuery, nodeDetail]);

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value.trim().toLowerCase())
    }

    return (
        <div className={'flex flex-col gap-6'}>
            {showSearch && (
                <div className="relative rounded-md shadow-sm">
                    <div
                        className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <Search />
                    </div>
                    <input
                        type="text"
                        name="searchInLinks"
                        id="searchInLinks"
                        className="ui-search w-full"
                        placeholder="Find a link..."
                        onChange={onSearch}
                        value={searchQuery}
                        disabled={!uiInteractive}
                    />
                </div>
            )}
            {visibleLinks.length > 0 && (
                <div className={'flex flex-col w-full gap-2'}>
                    <div className={'text-lg'}>Links {showCount && `(${visibleLinksCount})`}</div>
                    <div className="flex flex-col">
                        {visibleLinks.map((link) => (
                            <Fragment key={link.link.id}>
                                <div className={'flex items-center w-full text-base-content-500'}><DottedLine/></div>
                                <NodeLink nodeDetail={link.node} link={link.link as BaseLink}/>
                            </Fragment>
                        ))}
                        <div className={'flex items-center w-full text-base-content-500'}><DottedLine/></div>
                    </div>
                    {limit > 0 && visibleLinksCount > 3 && (
                        <div
                            className={'w-full text-center font-medium cursor-pointer'}
                            onClick={() => onShowAll ? onShowAll() : undefined}
                        >
                            View all ({visibleLinksCount}) -&gt;
                        </div>
                    )}
                </div>
            )}
            {showFiltered && filteredLinks.length > 0 && (
                <div className={'flex flex-col w-full gap-2'}>
                    <div className={'text-lg'}>Filtered out Links {showCount && `(${filteredLinksCount})`}</div>
                    <div className="flex flex-col">
                        {filteredLinks.map((link) => (
                            <Fragment key={link.link.id}>
                                <div className={'flex items-center w-full text-base-content-500'}><DottedLine/></div>
                                <NodeLink nodeDetail={link.node} link={link.link as BaseLink}/>
                            </Fragment>
                        ))}
                        <div className={'flex items-center w-full text-base-content-500'}><DottedLine/></div>
                    </div>
                    {limitFiltered && limitFiltered > 0 && nodeDetail?.links.length > 3 && (
                        <div
                            className={'w-full flex items-center justify-center text-center font-medium cursor-pointer'}
                            onClick={() => setShowAllFilteredLinks(!showAllFilteredLinks)}
                        >
                            {showAllFilteredLinks ? (
                                <>View less</>
                            ) : (
                                <>View all ({filteredLinksCount})&nbsp;<ArrowDown className={'inline'}/></>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}