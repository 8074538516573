type Props = {
    className?: string,
}

export const DottedLine = ({className}: Props) => {
    return (
        <svg className={'w-full h-0.5 ' + className || ''} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 2" fill="none">
            <path d="M1 1H359" stroke="currentColor" strokeLinecap="round" strokeDasharray="0.5 6"/>
        </svg>
    )
}