import * as THREE from 'three';

import { VIRTUAL_LINK_LENGTH, VIRTUAL_LINK_Y } from "../../../common/constants";

export const ExtensionBatchedVirtualLinkDrawProgress = {
    name: "extension-batched-draw-progress",
    uniforms: {
        drawProgress: { value: 0.0 },
        drawProgressTexture: new THREE.Texture()
    },
    vertexShader: (shader: any) => {
        return `
        uniform highp sampler2D drawProgressTexture; // Define drawProgress texture
        varying float vDrawProgress;
        varying float vProgress;

        ${shader.replace(
            `#include <batching_vertex>`,
            `
            #include <batching_vertex>

            #ifdef USE_BATCHING
                vDrawProgress = texelFetch( drawProgressTexture, ivec2( 0, getIndirectIndex( gl_DrawID ) ), 0 ).r;
                // vDrawProgress = 0.5;

                // Calculate the total length of the link using SYMBOL_SHARED_Y and VIRTUAL_LINK_LENGTH
                float totalLength = sqrt(${VIRTUAL_LINK_LENGTH.toFixed(1)} * ${VIRTUAL_LINK_LENGTH.toFixed(1)} + ${VIRTUAL_LINK_Y.toFixed(1)} * ${VIRTUAL_LINK_Y.toFixed(1)});

                // Calculate the progress along the curve
                vProgress = length(vec3(position.x, position.y, position.z)) / totalLength;
            #endif
            `
        )}
        `;
    },
    fragmentShader: (shader: any) => {
        return `
        varying float vDrawProgress; // Receive drawProgress value from vertex shader
        varying float vProgress; // Receive the progress along the curve from the vertex shader

        ${shader.replace(
            '#include <color_fragment>',
            `
            #include <color_fragment>

            // Calculate the alpha based on the draw progress
            float alpha = vProgress <= vDrawProgress ? 1.0 : 0.0;

            // Set the fragment color with the calculated alpha
            diffuseColor *= alpha * opacity;
            `
        )}
        `;
    },
};
