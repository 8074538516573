import type { StateCreator } from 'zustand'
export interface SettingsSlice {
    showSettings: boolean;
    openSettings: () => void;
    closeSettings: () => void;
    toggleSettings: () => void;
}

export const createSettingsSlice: StateCreator<SettingsSlice> = (set) => ({
    showSettings: false,
    openSettings: () => set(() => ({ showSettings: true })),
    closeSettings: () => set(() => ({ showSettings: false })),
    toggleSettings: () => set((state) => ({ showSettings: !state.showSettings })),
})
