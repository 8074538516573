import {changeHue} from '@/utils/color.ts';
import type {BaseSymbol} from '@/three/symbols/BaseSymbol.ts';
import type {BaseLink} from '@/three/links/BaseLink.ts';
import {ArrowLeft} from '@/assets/icons/ArrowLeft.tsx';
import {ArrowRight} from '@/assets/icons/ArrowRight.tsx';
import {ArrowBoth} from '@/assets/icons/ArrowBoth.tsx';
import {classNames} from '@/utils/helpers.ts';
import type {LinkType} from '@/utils/link.ts';
import {linkTypeMap} from '@/utils/link.ts';

type Props = {
    nodeDetail: BaseSymbol;
    link: BaseLink,
}

export default function NodeLink({nodeDetail, link}: Props) {

    let rightNode: null | BaseSymbol = null;

    const resolveLinks = () => {
        const linkMap: Map<string, LinkType> = new Map();
        const mainLinkType = linkTypeMap[link.type];
        if (mainLinkType) {
            const mainLink: LinkType = {
                id: mainLinkType.id,
                label: mainLinkType.label,
                bgColor: mainLinkType.bgColor,
                contentColor: mainLinkType.contentColor,
                direction: link.target?.id === nodeDetail.id ? 'toNodeDetail' : 'fromNodeDetail'
            };
            linkMap.set(mainLinkType.id, mainLink);
        }
        if (link.originalData?.allLinks?.length) {
            for (let i = 0; i < link.originalData.allLinks.length; i++) {
                const anotherLink = link.originalData.allLinks[i];
                const type = linkTypeMap[anotherLink.type];
                if (!type) {
                    continue;
                }
                const direction = anotherLink.target === nodeDetail.id ? 'toNodeDetail' : 'fromNodeDetail';
                const linkTypeAlready = linkMap.get(type.id);
                if (linkTypeAlready && linkTypeAlready.direction !== direction) {
                    linkTypeAlready.direction = 'both';
                    linkMap.set(type.id, linkTypeAlready);
                    continue;
                }
                linkMap.set(type.id, {...type, direction: direction});
            }
        }
        return Array.from(linkMap.values());
    }

    const getRightNode = (): BaseSymbol => {
        if (rightNode) {
            return rightNode;
        }
        let node: BaseSymbol;
        if (link.target?.id === nodeDetail.id) {
            node = link.source! as BaseSymbol;
        } else {
            node = link.target! as BaseSymbol
        }
        rightNode = node;
        return node;
    }

    return (
        <div className={'flex flex-row gap-3 py-3'}>
            <div className={'flex items-center'}>
                <div
                    className={`rounded-badge ui-shadow-b w-5 h-5 shrink-0 p-1`}
                    style={{
                        background: `linear-gradient(${changeHue(`#${nodeDetail.color.getHexString()}`, 15)}, #${nodeDetail.color.getHexString()})`
                    }}
                >
                    {nodeDetail.iconURL && (
                        <img alt={nodeDetail.originalData?.title || 'Name unknown'}
                             src={nodeDetail.iconURL}/>
                    )}
                </div>
            </div>
            <div className={'flex flex-col gap-0.5 items-center justify-center'}>
                {resolveLinks().map((linkType) => (
                    <div key={linkType.id} className={classNames(linkType.contentColor, 'flex items-center relative h-5')}>
                        {linkType.direction === 'both' ? (
                            <ArrowBoth/>
                        ) : linkType.direction === 'toNodeDetail' ? (
                            <ArrowLeft/>
                        ) : (
                            <ArrowRight/>
                        )}
                        <div
                            className={classNames(linkType.bgColor, linkType.contentColor, 'absolute min-w-[3.75rem] text-center border-l-2 border-r-2 border-base-100 h-full left-0 right-0 mx-auto w-max text-sm leading-3 py-1 px-2 rounded-badge')}>
                            {linkType.label}
                        </div>
                    </div>
                ))}
            </div>
            <div className={'flex items-center'}>
                <div
                    className={`rounded-badge ui-shadow-b w-6 h-6 shrink-0 p-1`}
                    style={{
                        background: `linear-gradient(${changeHue(`#${getRightNode().color.getHexString()}`, 15)}, #${getRightNode().color.getHexString()})`
                    }}
                >
                    {getRightNode().iconURL && (
                        <img alt={getRightNode().originalData?.title || 'Name unknown'}
                             src={getRightNode().iconURL}/>
                    )}
                </div>
            </div>
            <div className={'flex items-center pl-1 [word-break:break-word]'}>
                {getRightNode().title}
            </div>
        </div>
    );
}