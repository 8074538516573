import * as THREE from 'three';
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { SSAOPass } from 'three/addons/postprocessing/SSAOPass.js';
import { FilmPass } from 'three/addons/postprocessing/FilmPass.js';

import { gsap } from "gsap";

import { GRID_LARGE_SUBDIV, GRID_SHORT_SUBDIV, GROUND_OFFSET, INTERACTION_ANIMATION_SPEED, SCALE_FACTOR } from './common/constants.js';
import { Repository } from './common/Repository.js';

// Addons
import { InfiniteGridHelper } from './utils/three/InfiniteGridHelper.js';
import CameraControls from 'camera-controls';

CameraControls.install( { THREE: THREE } );
// Stats and GUI
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { RendererStats } from './utils/three/threex.rendererstats.js';
import {Pane} from 'tweakpane';

import { BaseInstancedMesh } from './common/BaseInstancedMesh.js';
import { InputManager } from './utils/interaction/InputManager.js';
import { IconImageData, TexturePackerJsonData } from './utils/three/TextureAtlasTypes.js';
import { AppEnv } from '../utils/AppEnv.ts';
import { Logger } from '../utils/Logger.ts';

// https://msdf-bmfont.donmccurdy.com/
import FontJSON from '../assets/msdf/Inter-Medium-msdf.json';
import FontImage from '../assets/msdf/Inter-Medium.png';
export const folderTitleCharset = " azertyuiopqsdfghjklmwxcvbnAZERTYUIOPQSDFGHJKLMWXCVBNéÉàÀèÈùÙëËüÜïÏâêîôûÂÊÎÔÛíÍáÁóÓúÚñÑłŁçÇýÝčČšŠæÆœŒ/*-–+7894561230,;:!?¡¿.%$£€={}()[]&~\"'‘’`#_°@АаБбВвГгДдЕеЁёЖжЗзИиЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЪъЫыЬьЭэЮюЯяüÜöÖäÄñÑςερτυθιοπασδφγηξκλζχψωβνμΕΡΤΥΘΙΟΠΑΣΔΦΓΗΞΚΛΖΧΨΩΒΝΜåÅæÆøØ";
import * as ThreeMeshUI from './utils/three/three-mesh-ui/three-mesh-ui.js';
import { SysInfo } from './utils/misc/SysInfo.ts';

export class CosmosThree {

    container: HTMLDivElement;

    static systemInfo: SysInfo;

    //Debug params
    static globalDebug = true;

    //  lil GUI, stats & Cam controls
    stats: Stats | null = null;
    rendererStats: any | null = null;
    gui: Pane | null = null;
    showGUI = false;
    showStats = true;
    createCamControls = true;
    enableCameraControls = true;
    showLightHelpers = false;
    showMeshBBoxHelper = true;
    showMeshSphereHelper = false;
    showMeshTransformedBBoxHelper = false;
    showCameraBoundsBBoxHelper = false;
    showDynamicSphereHelper = false;
    showDynamicBoundsHelper = false;

    // Antialias & RGBEncoding
    private useAntialias = true;
    private usesRGBEncoding = true;

    // Postprocessing
    private usePostProcessing = false;
    private useSSAO = false;
    private useFilm = false;

    // ThreeJs
    canvasWidth = 1;
    canvasHeight = 1;

    static globalCanvasWidth = 1;
    static globalCanvasHeight = 1;

    rendererPixelRatio = 1;
    static globalRendererPixelRatio = 1;

    loadManager: THREE.LoadingManager | null = null;

    // We make the icons texture assets static for easy access across classes
    static iconsAtlasTexture: THREE.Texture = new THREE.Texture();
    static iconsAtlasJson: TexturePackerJsonData | null = null;
    static iconsAtlasMap: Map<string, IconImageData> | null = null;

    graphScene!: THREE.Scene;
    static globalGraphScene: THREE.Scene;

    guiScene!: THREE.Scene;
    static globalGuiScene: THREE.Scene;
    clock!: THREE.Clock;

    cube: THREE.Mesh = new THREE.Mesh();

    ground!: THREE.Mesh;
    grid!: InfiniteGridHelper;

    graphCamera!: THREE.OrthographicCamera;
    static globalGraphCamera: THREE.OrthographicCamera;
    graphCameraTargetHelper!: THREE.Mesh;
    guiCamera!: THREE.OrthographicCamera;
    static globalGuiCamera: THREE.OrthographicCamera;

    camMode: "3d" | "2d" = "3d";
    activeTheme: "cosmosLight" | "cosmosDark" = "cosmosLight";

    static globalMeshOffset = new THREE.Vector3();

    ambientLight!: THREE.AmbientLight;
    directionalLight1!: THREE.DirectionalLight;
    light1Helper!: THREE.DirectionalLightHelper;
    light1CameraHelper!: THREE.CameraHelper;
    directionalLight2!: THREE.DirectionalLight;
    light2Helper!: THREE.DirectionalLightHelper;
    light2CameraHelper!: THREE.CameraHelper;

    meshBBoxHelper: THREE.Box3Helper = new THREE.Box3Helper(new THREE.Box3(), 0xff0000);
    meshSphereHelper: THREE.Mesh = new THREE.Mesh( 
        new THREE.SphereGeometry( 1, 16, 16 ), 
        new THREE.MeshBasicMaterial( { color: 0xff0000, wireframe: true } )
    );

    meshTransformedBBoxHelper: THREE.Box3Helper = new THREE.Box3Helper(new THREE.Box3(), 0x0000ff);
    cameraBoundsHelper: THREE.Box3Helper = new THREE.Box3Helper(new THREE.Box3(), 0x00ff00);
    dynamicSphereHelper: THREE.Mesh = new THREE.Mesh( 
        new THREE.SphereGeometry( 1, 16, 16 ), 
        new THREE.MeshBasicMaterial( { color: 0x00ffff, wireframe: true } )
    );
    dynamicBoundsHelper: THREE.Box3Helper = new THREE.Box3Helper(new THREE.Box3(), 0x00ff00);

    inputManager!: InputManager;

    camControls!: CameraControls;
    renderer!: THREE.WebGLRenderer;
    static globalRenderer: THREE.WebGLRenderer;
    static globalAnisotropy: number = -1;
    isRendererPaused = false;

    composer?: EffectComposer;
    ssaoPass?: SSAOPass;
    filmPass?: FilmPass;

    requestedAnimationFrameId = 0;

    // Presets
    minGroundSize = 20000 / SCALE_FACTOR;
    groundSize = this.minGroundSize;

    graphCameraFrustrumSize = 1000 / SCALE_FACTOR;
    graphCameraNear = - (Math.sqrt(2 * Math.pow(this.groundSize, 2)) + this.graphCameraFrustrumSize);
    graphCameraFar = (Math.sqrt(2 * Math.pow(this.groundSize, 2)) + this.graphCameraFrustrumSize);
    showCameraTargetHelper = true;

    clearColorLight = new THREE.Color("#f8f8ff"); // ghost white
    groundColorLight = new THREE.Color("#e5e5ff"); // ghost white - adjusted to ilumination
    gridColorLight = new THREE.Color("#f2f2f2"); // f2f2f2

    clearColorDark = new THREE.Color("#363638");
    groundColorDark = new THREE.Color("#313133"); // - adjusted to ilumination
    gridColorDark = new THREE.Color("#898989");

    clearColor = this.clearColorLight.clone();
    groundColor = this.groundColorLight.clone();
    gridColor = this.gridColorLight.clone();

    ambientLightIntensity = 1.2; // 1.2
    ambientLightColor = 0xffffff;

    directionalLight1Intensity = 3; // 1
    directionalLight2Intensity = 1.2; // 1.6

    constructor (container: HTMLDivElement, systemInfo: SysInfo){
        CosmosThree.systemInfo = systemInfo;

        Logger.log('Client System Info:', CosmosThree.systemInfo);

        CosmosThree.globalDebug = AppEnv.getMode() !== 'production';

        this.container = container;

        this.rendererPixelRatio = (window.devicePixelRatio >= 2) ? 2 : window.devicePixelRatio;
        CosmosThree.globalRendererPixelRatio = this.rendererPixelRatio;

        // Stats
        if(this.showStats){ this.addStats(); }

        this.loadAssets();

        this.addScenes();

        this.addCameras();

        this.addLights();

        this.addGroundAndGrid();

        if(CosmosThree.globalDebug) { this.addHelpers(); }

        this.addRenderer();

        this.addInputManager(); // It's very important for the input manager to be instantiated before the camera controls so the wheel event of the input controls get more precedence in the execution stack. Otherwise there is no way to override the default behaviour of camera controls.

        this.addCamControls();

        this.addEffectComposer();

        // Gui
        if(CosmosThree.globalDebug && this.showGUI){ this.addGUIControls(); }

        // Post-creation global resize
        window.addEventListener('resize', this.updateSize.bind(this), false);

        // Animation and rendering loop
        this.animate();
    }

    addStats(){
        this.stats = new Stats();
        this.stats.dom.id = "cosmos-performance-stats";
        this.stats.showPanel(0);
        this.container.appendChild(this.stats.dom);

        if(CosmosThree.globalDebug){
            this.rendererStats = RendererStats();
            this.rendererStats.domElement.style.position = 'absolute';
            this.rendererStats.domElement.style.right = '0px';
            this.rendererStats.domElement.style.bottom = '48px';
            this.container.appendChild(this.rendererStats.domElement);
        }
    }

    loadAssets(){
        this.loadManager = new THREE.LoadingManager();

        this.loadManager.onStart = function ( url, itemsLoaded, itemsTotal ) {
            Logger.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };
        
        
        this.loadManager.onLoad = function ( ) {
            Logger.log( 'Loading Complete!');
        };
        
        this.loadManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
            Logger.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };
        
        this.loadManager.onError = function ( url ) {
            Logger.log( 'There was an error loading ' + url );
        };

        // Assets loaders
        new THREE.TextureLoader(this.loadManager).load(
            // resource URL
            '/atlases/pack64.png',
        
            // onLoad callback
            (texture) => {
                // we create the texture when the image is loaded
                CosmosThree.iconsAtlasTexture = texture;

                // Crispier icons when scaled down but more artifacts and less antialias
                // CosmosThree.iconsAtlasTexture.generateMipmaps = false;

                CosmosThree.iconsAtlasTexture.anisotropy = CosmosThree.globalAnisotropy;
            },
        
            // onProgress callback currently not supported
            undefined,
        
            // onError callback
            function ( err ) {
                Logger.error( 'An error happened.', err );
            }
        );

        new THREE.FileLoader(this.loadManager).load(
            // resource URL
            '/atlases/pack64.json',
        
            // onLoad callback
            ( data ) => {
                // output the text to the console
                // Logger.log( "Text", data );
                CosmosThree.iconsAtlasJson = JSON.parse(data as string);
                // Logger.log( "JSON", this.iconsAtlasJson);

                // We create a map from the Json for fast lookup
                if(CosmosThree.iconsAtlasJson){
                // Create a Map
                CosmosThree.iconsAtlasMap = new Map<string, IconImageData>();
                CosmosThree.iconsAtlasJson.frames.forEach(icon => {
                        const { filename, ...imageData } = icon;
                        CosmosThree.iconsAtlasMap!.set(filename, imageData);
                    });
                }
                // Logger.log(this.iconsAtlasMap);
            },
        
            // onProgress callback
            function ( xhr ) {
                Logger.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
            },
        
            // onError callback
            function ( err ) {
                Logger.error( 'An error happened', err );
            }
        );

        new THREE.TextureLoader(this.loadManager).load(
            // resource URL
            FontImage,

            ( texture ) => {
                ThreeMeshUI.FontLibrary.addFont( 'InterMedium', FontJSON, texture );
            },

            // onProgress callback currently not supported
            undefined,
        
            // onError callback
            function ( err ) {
                Logger.error( 'An error happened.', err );
            }
        );
    }

    addScenes(){
        // Graph Scene
        this.graphScene = new THREE.Scene();
        CosmosThree.globalGraphScene = this.graphScene;

        // Graph Scene
        this.guiScene = new THREE.Scene();
        CosmosThree.globalGuiScene = this.guiScene;
    }

    addCameras(){
        this.graphCamera = new THREE.OrthographicCamera( - this.container.clientWidth / (2 * SCALE_FACTOR), this.container.clientWidth / (2 * SCALE_FACTOR), this.container.clientHeight / (2 * SCALE_FACTOR), -this.container.clientHeight / (2 * SCALE_FACTOR), this.graphCameraNear, this.graphCameraFar );
        this.graphCamera.position.set( 1, 1, 1 );

        CosmosThree.globalGraphCamera = this.graphCamera;

        if(CosmosThree.globalDebug){
            this.graphCameraTargetHelper = new THREE.Mesh(
                new THREE.SphereGeometry( 40 / SCALE_FACTOR ),
                new THREE.MeshBasicMaterial( { color: 0xffff00 } )
            )
            this.graphScene.add( this.graphCameraTargetHelper );
            this.graphCameraTargetHelper.visible = false;
        }

        // GUI camera
        this.guiCamera = new THREE.OrthographicCamera( - this.container.clientWidth / (2 * SCALE_FACTOR), this.container.clientWidth / (2 * SCALE_FACTOR), this.container.clientHeight / (2 * SCALE_FACTOR), -this.container.clientHeight / (2 * SCALE_FACTOR), -1 );
        CosmosThree.globalGuiCamera = this.guiCamera;
    }

    addLights(){
        // Lights
        this.ambientLight = new THREE.AmbientLight(this.ambientLightColor, this.ambientLightIntensity);
        this.graphScene.add( this.ambientLight );

        this.directionalLight1 = new THREE.DirectionalLight(0xffffff, this.directionalLight1Intensity);
        this.directionalLight1.castShadow = true;
        this.directionalLight1.position.set(0, this.groundSize / 4, this.groundSize / 2);

        this.directionalLight1.shadow.camera.far = new THREE.Vector3(0,0,0).distanceTo(this.directionalLight1.position) * 2;
        this.directionalLight1.shadow.camera.near = 0;

        this.directionalLight1.shadow.mapSize.width = 4096;
        this.directionalLight1.shadow.mapSize.height = 4096;

        // this.directionalLight1.shadow.bias = -0.0001;

        this.directionalLight1.shadow.camera.left = -this.groundSize / 2;
        this.directionalLight1.shadow.camera.right = this.groundSize / 2;
        this.directionalLight1.shadow.camera.top = this.groundSize / 2;
        this.directionalLight1.shadow.camera.bottom = -this.groundSize / 2;

        this.directionalLight1.shadow.camera.zoom = 1;
        
        // this.directionalLight1.shadow.normalBias = -0.0001;
        // this.directionalLight1.shadow.radius = 2;

        this.graphScene.add(this.directionalLight1);

        if(CosmosThree.globalDebug){
            this.light1Helper = new THREE.DirectionalLightHelper( this.directionalLight1, 5 );
            this.light1Helper.visible = this.showLightHelpers;
            this.graphScene.add( this.light1Helper );

            this.light1CameraHelper = new THREE.CameraHelper(this.directionalLight1.shadow.camera);
            this.light1CameraHelper.visible = this.showLightHelpers;
            this.graphScene.add(this.light1CameraHelper);
        }

        this.directionalLight2 = new THREE.DirectionalLight(0xffffff, this.directionalLight2Intensity);
        this.directionalLight2.position.set(0, this.groundSize / 2, 0);
        this.graphScene.add(this.directionalLight2);

        if(CosmosThree.globalDebug){
            this.light2Helper = new THREE.DirectionalLightHelper( this.directionalLight2, 5 );
            this.light2Helper.visible = this.showLightHelpers;
            this.graphScene.add( this.light2Helper );

            this.light2CameraHelper = new THREE.CameraHelper(this.directionalLight2.shadow.camera);
            this.light2CameraHelper.visible = this.showLightHelpers;
            this.graphScene.add(this.light2CameraHelper);
        }
    }

    addGroundAndGrid(){
        // Ground
        const groundGeo = new THREE.PlaneGeometry( isFinite(this.groundSize)? this.groundSize : 1, isFinite(this.groundSize)? this.groundSize : 1, 2, 2 ); // Try to make the ground with many segments to prevent supposed screen tearing because of precision problems
        const groundMat = new THREE.MeshLambertMaterial( {color: this.groundColor} );

        this.ground = new THREE.Mesh( groundGeo, groundMat );
        this.ground.receiveShadow = true;
        this.ground.rotation.x = - Math.PI / 2;
        this.ground.position.set(0, -GROUND_OFFSET, 0) // We move it a little bit in the z axis to avoid z-fighting with the grid
        this.graphScene.add( this.ground );

        // Grid
        this.grid = new InfiniteGridHelper(GRID_SHORT_SUBDIV, GRID_LARGE_SUBDIV, this.gridColor, this.graphCameraFar, 'xzy' );
        this.grid.receiveShadow = true;
        this.grid.position.set(0, -GROUND_OFFSET/2, 0); // We move it a little bit in the y axis to avoid z-fighting with the ground
        this.graphScene.add(this.grid);
    }

    addHelpers(){
        // 
        /* const cubeMat = new MeshTransmissionMaterial({
            _transmission: 1,
            thickness: 0,
            roughness: 0,
            chromaticAberration: 0.03,
            anisotropicBlur: 0.1,
            distortion: 0,
            distortionScale: 0.5,
            temporalDistortion: 0.0,
        }) */

        /* const cubeMat = new THREE.MeshBasicMaterial({
            color: 0xff3300,
            side: THREE.DoubleSide
        });
                
        this.cube = new THREE.Mesh(new THREE.BoxGeometry( 200 / SCALE_FACTOR, 200 / SCALE_FACTOR, 200 / SCALE_FACTOR ), cubeMat);
        this.cube.name = "cube";
        this.cube.receiveShadow = true;
        this.cube.castShadow = true;
        this.cube.position.y = 100 / SCALE_FACTOR;
        this.guiScene.add( this.cube ); */

        // axes
        this.graphScene.add( new THREE.AxesHelper( 300 / SCALE_FACTOR ) );

        // Mesh BBox helper
        this.graphScene.add(this.meshBBoxHelper);
        this.meshBBoxHelper.visible = this.showMeshBBoxHelper;

        // Mesh Sphere helper
        this.graphScene.add(this.meshSphereHelper);
        this.meshSphereHelper.visible = this.showMeshSphereHelper;

        // Mesh Wrapper BBox helper
        this.graphScene.add(this.meshTransformedBBoxHelper);
        this.meshTransformedBBoxHelper.visible = this.showMeshTransformedBBoxHelper;

        // Camera bounds BBox helper
        this.graphScene.add(this.cameraBoundsHelper);
        this.cameraBoundsHelper.visible = this.showCameraBoundsBBoxHelper;

        // Dynamic bounding sphere helper
        this.graphScene.add(this.dynamicSphereHelper);
        this.dynamicSphereHelper.visible = this.showDynamicSphereHelper;

        // Dynamic bounding sphere helper
        this.graphScene.add(this.dynamicBoundsHelper);
        this.dynamicBoundsHelper.visible = this.showDynamicBoundsHelper;
    }

    addRenderer(){
        // Renderer
        this.renderer = new THREE.WebGLRenderer({ 
            antialias: this.useAntialias ,
            powerPreference:"high-performance",
            // logarithmicDepthBuffer: true,
        });

        CosmosThree.globalRenderer = this.renderer;

        this.renderer.setClearColor(this.clearColor);

        this.updateSize();

        this.renderer.info.autoReset = false; 
        this.renderer.autoClear = false;

        this.renderer.setPixelRatio( this.rendererPixelRatio );

        if(this.usesRGBEncoding){
            this.renderer.outputColorSpace = THREE.SRGBColorSpace;
        }
    
        this.renderer.shadowMap.enabled = true;
        // this.renderer.toneMapping = THREE.LinearToneMapping;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        CosmosThree.globalAnisotropy = this.renderer.capabilities.getMaxAnisotropy();

        // This prevents the black 1px border outline to be shown when the canvas is focused
        this.renderer.domElement.style.outline = "none";
        // The canvas needs to have a tab index set for it to be able to be focused.
        // If it can't be focused it won't be able to fire keyboard events.
        this.renderer.domElement.setAttribute("tabindex", "0");

        this.container.appendChild(this.renderer.domElement);
    }

    addInputManager(){
        // Input manager (raycasting, mouse, states, etc)
        this.inputManager = new InputManager(this);
    }

    addCamControls(){
        // Camera Controls
        this.clock = new THREE.Clock();
        this.camControls = new CameraControls(this.graphCamera, this.renderer.domElement);
        this.camControls.maxZoom = 1;
        this.camControls.minZoom = 0.1;
        this.camControls.dollySpeed = 3 * CosmosThree.globalRendererPixelRatio;
        this.camControls.draggingSmoothTime = 0.100;
        this.camControls.enabled = this.enableCameraControls;
        this.camControls.mouseButtons.left = CameraControls.ACTION.TRUCK;

        // this.camControls.mouseButtons.right = CameraControls.ACTION.ROTATE; // uncomment to allow rotation with right mouse button
        this.camControls.mouseButtons.right = CameraControls.ACTION.NONE;

        this.camControls.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
        this.camControls.touches.one = CameraControls.ACTION.TOUCH_TRUCK;
        this.camControls.touches.two = CameraControls.ACTION.TOUCH_ZOOM_TRUCK;

        // this.camControls.touches.two = CameraControls.ACTION.TOUCH_ROTATE;
        this.camControls.touches.two = CameraControls.ACTION.NONE;

        this.camControls.dollyToCursor = true;
    }

    addEffectComposer() {
        if(this.usePostProcessing){
            this.composer = new EffectComposer( this.renderer );

            const renderPass = new RenderPass( this.graphScene, this.graphCamera );
            this.composer.addPass( renderPass );

            //SSAO
            this.ssaoPass = new SSAOPass( this.graphScene, this.graphCamera, this.canvasWidth, this.canvasHeight, 16 );
        
            // Film
            this.filmPass = new FilmPass(0.3, false);

            this.addPasses();
        }
    }

    addPasses(){
        this.removePasses();

        if(this.usePostProcessing){
            /* if(this.showFXAA){
                this.composer.addPass( this.fxaaPass );
            } */
            if(this.useSSAO && this.ssaoPass){
                this.composer?.addPass( this.ssaoPass );
            }

            if(this.useFilm && this.filmPass){
                this.composer?.addPass( this.filmPass );
            }
            /* if(this.showBloom){
                this.composer.addPass( this.bloomPass );
            }
            if(this.showRGBShift){
                this.composer.addPass( this.rgbShift );
            }
            if(this.showGlitch){
                this.composer.addPass( this.glitchPass );
            }
            if(this.showFilm){
                this.composer.addPass( this.filmPass );
            } */
        }
    }

    removePasses(){
        if(this.usePostProcessing){
            if(this.ssaoPass){
                this.composer?.removePass( this.ssaoPass );
            }
            if(this.filmPass){
                this.composer?.removePass( this.filmPass );
            }
            /* this.composer.removePass( this.bloomPass );
            this.composer.removePass( this.rgbShift );
            this.composer.removePass( this.glitchPass );
            this.composer.removePass( this.filmPass );
            this.composer.removePass( this.fxaaPass ); */
        }
    }

    addGUIControls() {
        this.gui = new Pane();
        this.gui.expanded = true;

        // Camera --
        const folderCamera = this.gui.addFolder({ title: 'Camera' });
        const folderCameraPosition = folderCamera.addFolder( { title: 'Position' } );
        folderCameraPosition.addBinding(this.graphCamera.position, 'x', { readonly: true, min: -20000, max: 20000 });
        folderCameraPosition.addBinding(this.graphCamera.position, 'y', { readonly: true, min: -20000, max: 20000 });
        folderCameraPosition.addBinding(this.graphCamera.position, 'z', { readonly: true, min: -20000, max: 20000 });

        const folderCameraRotation = folderCamera.addFolder( { title: 'Rotation' } );
        folderCameraRotation.addBinding(this.graphCamera.rotation, 'x', { readonly: true, min: -Math.PI * 2, max: Math.PI * 2 });
        folderCameraRotation.addBinding(this.graphCamera.rotation, 'y', { readonly: true, min: -Math.PI * 2, max: Math.PI * 2 });
        folderCameraRotation.addBinding(this.graphCamera.rotation, 'z', { readonly: true, min: -Math.PI * 2, max: Math.PI * 2 });

        const folderCameraZoom = folderCamera.addFolder( { title: 'Zoom' } );
        folderCameraZoom.addBinding(this.graphCamera, 'zoom', { readonly: true, min: -100, max: 100 });

        const folderLights = this.gui.addFolder( { title: 'Lights', expanded: false } );
        folderLights.addBinding(this, 'showLightHelpers', {label: "visible"}).on('change', () => {
            this.light1Helper.visible = this.showLightHelpers;
            this.light1CameraHelper.visible = this.showLightHelpers;
            this.light2Helper.visible = this.showLightHelpers;
            this.light2CameraHelper.visible = this.showLightHelpers;
        });

        const folderAmbientLight = folderLights.addFolder( { title: 'Ambient' } );
        folderAmbientLight.addBinding( this.ambientLight, 'intensity', { min: 0, max: 5 } );

        const folderDirectionaLight1 = folderLights.addFolder( { title: 'Directional Light 1' } );

        const folderDirectionaLight1Tabs = folderDirectionaLight1.addTab({
            pages: [
              {title: 'Light'},
              {title: 'Shadow'},
            ],
          });

        folderDirectionaLight1Tabs.pages[0].addBinding( this.directionalLight1, 'intensity', { min: 0, max: 5 } );
        folderDirectionaLight1Tabs.pages[0].addBinding( this.directionalLight1.position, 'x', { min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR } );
        folderDirectionaLight1Tabs.pages[0].addBinding( this.directionalLight1.position, 'y', { min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR } );
        folderDirectionaLight1Tabs.pages[0].addBinding( this.directionalLight1.position, 'z', { min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR } );

        folderDirectionaLight1Tabs.pages[1].addBinding(this.directionalLight1.shadow.camera, 'far', {min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR}).on('change', () => {
            this.directionalLight1.shadow.camera.updateProjectionMatrix();
            this.light1CameraHelper.update();
        });

        folderDirectionaLight1Tabs.pages[1].addBinding(this.directionalLight1.shadow.camera, 'near', {min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR}).on('change', () => {
            this.directionalLight1.shadow.camera.updateProjectionMatrix();
            this.light1CameraHelper.update();
        });

        folderDirectionaLight1Tabs.pages[1].addBinding(this.directionalLight1.shadow.camera, 'left', {min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR}).on('change', () => {
            this.directionalLight1.shadow.camera.updateProjectionMatrix();
            this.light1CameraHelper.update();
        });

        folderDirectionaLight1Tabs.pages[1].addBinding(this.directionalLight1.shadow.camera, 'right', {min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR}).on('change', () => {
            this.directionalLight1.shadow.camera.updateProjectionMatrix();
            this.light1CameraHelper.update();
        });

        folderDirectionaLight1Tabs.pages[1].addBinding(this.directionalLight1.shadow.camera, 'top', {min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR}).on('change', () => {
            this.directionalLight1.shadow.camera.updateProjectionMatrix();
            this.light1CameraHelper.update();
        });

        folderDirectionaLight1Tabs.pages[1].addBinding(this.directionalLight1.shadow.camera, 'bottom', {min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR}).on('change', () => {
            this.directionalLight1.shadow.camera.updateProjectionMatrix();
            this.light1CameraHelper.update();
        });

        const folderDirectionaLight2 = folderLights.addFolder( { title: 'Directional Light 2' } );

        const folderDirectionaLight2Tabs = folderDirectionaLight2.addTab({
            pages: [
              {title: 'Light'},
              {title: 'Shadow'},
            ],
          });

        folderDirectionaLight2Tabs.pages[0].addBinding( this.directionalLight2, 'intensity', { min: 0, max: 5 } );
        folderDirectionaLight2Tabs.pages[0].addBinding( this.directionalLight2.position, 'x', { min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR } );
        folderDirectionaLight2Tabs.pages[0].addBinding( this.directionalLight2.position, 'y', { min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR } );
        folderDirectionaLight2Tabs.pages[0].addBinding( this.directionalLight2.position, 'z', { min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR } );

        folderDirectionaLight2Tabs.pages[1].addBinding(this.directionalLight2.shadow.camera, 'far', {min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR}).on('change', () => {
            this.directionalLight2.shadow.camera.updateProjectionMatrix();
            this.light2CameraHelper.update();
        });

        folderDirectionaLight2Tabs.pages[1].addBinding(this.directionalLight2.shadow.camera, 'near', {min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR}).on('change', () => {
            this.directionalLight2.shadow.camera.updateProjectionMatrix();
            this.light2CameraHelper.update();
        });

        folderDirectionaLight2Tabs.pages[1].addBinding(this.directionalLight2.shadow.camera, 'left', {min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR}).on('change', () => {
            this.directionalLight2.shadow.camera.updateProjectionMatrix();
            this.light2CameraHelper.update();
        });

        folderDirectionaLight2Tabs.pages[1].addBinding(this.directionalLight2.shadow.camera, 'right', {min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR}).on('change', () => {
            this.directionalLight2.shadow.camera.updateProjectionMatrix();
            this.light2CameraHelper.update();
        });

        folderDirectionaLight2Tabs.pages[1].addBinding(this.directionalLight2.shadow.camera, 'top', {min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR}).on('change', () => {
            this.directionalLight2.shadow.camera.updateProjectionMatrix();
            this.light2CameraHelper.update();
        });

        folderDirectionaLight2Tabs.pages[1].addBinding(this.directionalLight2.shadow.camera, 'bottom', {min: -50000 / SCALE_FACTOR, max: 50000 / SCALE_FACTOR}).on('change', () => {
            this.directionalLight2.shadow.camera.updateProjectionMatrix();
            this.light2CameraHelper.update();
        });

        const folderMesh = this.gui.addFolder( { title: 'Helpers' } );
        folderMesh.addBinding(this, 'showCameraTargetHelper', {label: "camera target"}).on('change', () => { this.graphCameraTargetHelper.visible = this.showCameraTargetHelper; });
        folderMesh.addBinding(this, 'showMeshBBoxHelper', {label: "bounding box"}).on('change', () => { this.meshBBoxHelper.visible = this.showMeshBBoxHelper; });
        folderMesh.addBinding(this, 'showMeshSphereHelper', {label: "bounding sphere"}).on('change', () => { this.meshSphereHelper.visible = this.showMeshSphereHelper; });
        folderMesh.addBinding(this, 'showMeshTransformedBBoxHelper', {label: "bounding Wrapper box"}).on('change', () => { this.meshTransformedBBoxHelper.visible = this.showMeshTransformedBBoxHelper; });
        folderMesh.addBinding(this, 'showCameraBoundsBBoxHelper', {label: "camera bounds box"}).on('change', () => { this.cameraBoundsHelper.visible = this.showCameraBoundsBBoxHelper; });
        folderMesh.addBinding(this, 'showDynamicSphereHelper', {label: "dynamic bounding sphere"}).on('change', () => { this.dynamicSphereHelper.visible = this.showDynamicSphereHelper; });
        folderMesh.addBinding(this, 'showDynamicBoundsHelper', {label: "dynamic bounding box"}).on('change', () => { this.dynamicBoundsHelper.visible = this.showDynamicBoundsHelper; });
        
        const folderMaterial = this.gui.addFolder( { title: 'Top base material', expanded: false } );

        const params = {
            color: {r: 255, g: 255, b: 255},
            transmission: 1,
            opacity: 1,
            metalness: 0,
            roughness: 0,
            ior: 1.5,
            thickness: 0.01,
            specularIntensity: 1,
            specularColor: 0xffffff,
            envMapIntensity: 1,
            lightIntensity: 1,
            exposure: 1
        };

        folderMaterial.addBinding(params, 'color', {view: 'color'}).on('change', () => {
            ((this.graphScene.getObjectByName("basesTopInstancedMesh") as BaseInstancedMesh).material as THREE.MeshPhysicalMaterial).color.set(params.color as THREE.ColorRepresentation);
        });

        folderMaterial.addBinding(params, 'transmission', {min: 0, max: 1, step: 0.01}).on('change', () => {
            ((this.graphScene.getObjectByName("basesTopInstancedMesh") as BaseInstancedMesh).material as THREE.MeshPhysicalMaterial).transmission = params.transmission;
        });

        folderMaterial.addBinding(params, 'opacity', {min: 0, max: 1, step: 0.01}).on('change', () => {
            ((this.graphScene.getObjectByName("basesTopInstancedMesh") as BaseInstancedMesh).material as THREE.MeshPhysicalMaterial).opacity = params.opacity;
        });

        folderMaterial.addBinding(params, 'metalness', {min: 0, max: 1, step: 0.01}).on('change', () => {
            ((this.graphScene.getObjectByName("basesTopInstancedMesh") as BaseInstancedMesh).material as THREE.MeshPhysicalMaterial).metalness = params.metalness;
        });

        folderMaterial.addBinding(params, 'roughness', {min: 0, max: 1, step: 0.01}).on('change', () => {
            ((this.graphScene.getObjectByName("basesTopInstancedMesh") as BaseInstancedMesh).material as THREE.MeshPhysicalMaterial).roughness = params.roughness;
        });

        folderMaterial.addBinding(params, 'ior', {min: 1, max: 2, step: 0.01}).on('change', () => {
            ((this.graphScene.getObjectByName("basesTopInstancedMesh") as BaseInstancedMesh).material as THREE.MeshPhysicalMaterial).ior = params.ior;
        });

        folderMaterial.addBinding(params, 'thickness', {min: 0, max: 5, step: 0.01}).on('change', () => {
            ((this.graphScene.getObjectByName("basesTopInstancedMesh") as BaseInstancedMesh).material as THREE.MeshPhysicalMaterial).thickness = params.thickness;
        });

        folderMaterial.addBinding(params, 'specularIntensity', {min: 0, max: 1, step: 0.01}).on('change', () => {
            ((this.graphScene.getObjectByName("basesTopInstancedMesh") as BaseInstancedMesh).material as THREE.MeshPhysicalMaterial).specularIntensity = params.specularIntensity;
        });

        folderMaterial.addBinding(params, 'specularColor', {view: 'color'}).on('change', () => {
            ((this.graphScene.getObjectByName("basesTopInstancedMesh") as BaseInstancedMesh).material as THREE.MeshPhysicalMaterial).specularColor.set(params.specularColor);
        });

    }

    updateSize(){
        this.canvasWidth = this.container.clientWidth;
        this.canvasHeight = this.container.clientHeight;

        CosmosThree.globalCanvasWidth = this.canvasWidth;
        CosmosThree.globalCanvasHeight = this.canvasHeight;

        // Update renderer
        this.renderer.setSize( this.canvasWidth, this.canvasHeight );

        // Update graph camera
        this.graphCamera.left = -this.canvasWidth / (2 * SCALE_FACTOR);
        this.graphCamera.right = this.canvasWidth / (2 * SCALE_FACTOR);
		this.graphCamera.top = this.canvasHeight / (2 * SCALE_FACTOR);
		this.graphCamera.bottom = -this.canvasHeight / (2 * SCALE_FACTOR);

        this.graphCamera.updateProjectionMatrix();

        // Update gui camera
        this.guiCamera.left = -this.canvasWidth / (2 * SCALE_FACTOR);
        this.guiCamera.right = this.canvasWidth / (2 * SCALE_FACTOR);
		this.guiCamera.top = this.canvasHeight / (2 * SCALE_FACTOR);
		this.guiCamera.bottom = -this.canvasHeight / (2 * SCALE_FACTOR);

        this.guiCamera.updateProjectionMatrix();

        if(CosmosThree.globalDebug) { this.light1CameraHelper.update(); }
    }

    updateWorldSize(bbox: THREE.Box3){
        this.groundSize = Math.max(this.minGroundSize, (Math.max(Math.abs(bbox.max.x) + Math.abs(bbox.min.x), Math.abs(bbox.max.z) + Math.abs(bbox.min.z))) );

        this.graphCameraNear = - (Math.sqrt(2 * Math.pow(this.groundSize, 2)) + this.graphCameraFrustrumSize);
        this.graphCameraFar = (Math.sqrt(2 * Math.pow(this.groundSize, 2)) + this.graphCameraFrustrumSize);

        this.graphCamera.near = this.graphCameraNear;
        this.graphCamera.far = this.graphCameraFar;

        this.graphCamera.updateProjectionMatrix();

        if(this.camMode === "3d"){
            this.directionalLight1.position.set(0, this.groundSize / 4, this.groundSize / 2);
        }else if(this.camMode === "2d"){
            this.directionalLight1.position.set(0, this.groundSize / 4, 0);
        }

        this.directionalLight1.shadow.camera.far = new THREE.Vector3(0,0,0).distanceTo(this.directionalLight1.position) * 2;
        this.directionalLight1.shadow.camera.left = -this.groundSize / 2;
        this.directionalLight1.shadow.camera.right = this.groundSize / 2;
        this.directionalLight1.shadow.camera.top = this.groundSize / 2;
        this.directionalLight1.shadow.camera.bottom = -this.groundSize / 2;

        this.directionalLight1.shadow.camera.updateProjectionMatrix();
        if(CosmosThree.globalDebug){ this.light1CameraHelper.update(); }

        this.directionalLight2.position.set(0, this.groundSize / 2, 0);

        this.ground.geometry.dispose();
        this.ground.geometry = new THREE.PlaneGeometry( isFinite(this.groundSize)? this.groundSize : 1, isFinite(this.groundSize)? this.groundSize : 1, 2, 2 );

        (this.grid.material as THREE.ShaderMaterial).uniforms.uDistance.value = this.graphCamera.far;
    }

    updateDynamicSphereHelper(boundingSphere: THREE.Sphere) {
        this.dynamicSphereHelper.geometry.dispose();
        this.dynamicSphereHelper.geometry = new THREE.SphereGeometry(boundingSphere.radius, 16, 16);
        this.dynamicSphereHelper.position.copy(boundingSphere.center);
    }

    setCameraMode(mode: "3d" | "2d", animated = true){
        // We need to move the camera target so the rotation happens around the 'correct' orbit point.
        // Otherwise at certain positions and/or zoom levels the movement would be super crazy.
        // To do that we cast a ray from the viewport center to the ground floor, and we move the camera center to that point, whic should be not perceptible.
        // Then we perform the rest of the animation (camera rotation, lights, etc).
        // TODO: The calculated point can be outside the camera bounds so the camera manager may clamp it, that means you see a jump to move it inside the bounds. For now it's still WAY better than before.
        const raycaster = new THREE.Raycaster();

        const raycasterVector = new THREE.Vector3();
        const raycasterDirection = new THREE.Vector3();

        const pointer = new THREE.Vector2();

        pointer.x = ( (this.canvasWidth / 2) / this.canvasWidth ) * 2 - 1;
        pointer.y = - ( (this.canvasHeight / 2) / this.canvasHeight ) * 2 + 1;

        raycasterVector.set( pointer.x, pointer.y, -1 ); // z = -1 important!
        raycasterVector.unproject( this.graphCamera );
        raycasterDirection.set( 0, 0, -1 ).transformDirection( this.graphCamera.matrixWorld );
        raycaster.set( raycasterVector, raycasterDirection );

        const intersects = raycaster.intersectObject( this.ground );

        if(intersects.length > 0){
            const intersectionPoint = intersects[0].point;
            this.camControls.moveTo(intersectionPoint.x, intersectionPoint.y, intersectionPoint.z, false);
        }

        this.camMode = mode;
        if(mode === "3d"){
            this.camControls.rotateTo(Math.PI / 4, Math.atan(Math.sqrt(2)), animated);
            gsap.to(this.directionalLight1.position, {duration: animated ? this.camControls.azimuthRotateSpeed : 0, z: this.groundSize / 2, ease: "power2.out"}); // this easing is super fine tuned by trial an error so the light looks right, DO NOT TOUCH
            gsap.to(this.directionalLight1, {duration: animated ? this.camControls.azimuthRotateSpeed : 0, intensity: 3, ease: "power1.out"}); // this easing is super fine tuned by trial an error so the light looks right, DO NOT TOUCH
        }else if (mode === "2d"){
            this.camControls.rotateTo(0, 0, animated);
            gsap.to(this.directionalLight1.position, {duration: animated ? this.camControls.azimuthRotateSpeed : 0, z: 0, ease: "power2.out"}); // this easing is super fine tuned by trial an error so the light looks right, DO NOT TOUCH
            gsap.to(this.directionalLight1, {duration: animated ? this.camControls.azimuthRotateSpeed : 0, intensity: 1.32, ease: "power3.out"}); // this easing is super fine tuned by trial an error so the light looks right, DO NOT TOUCH
        }
    }

    setTheme(activeTheme: "cosmosLight" | "cosmosDark", animated = true){
        if(activeTheme === "cosmosLight"){
            gsap.to(this.clearColor, {duration: animated ? INTERACTION_ANIMATION_SPEED : 0, r: this.clearColorLight.r, g: this.clearColorLight.g, b: this.clearColorLight.b, ease: "none", onUpdate: ()=>{this.renderer.setClearColor(this.clearColor);}});
            gsap.to((this.ground.material as THREE.MeshBasicMaterial).color, {duration: animated ? INTERACTION_ANIMATION_SPEED : 0, r: this.groundColorLight.r, g: this.groundColorLight.g, b: this.groundColorLight.b, ease: "none"});
            gsap.to((this.grid.material as any).uniforms.uColor.value, {duration: animated ? INTERACTION_ANIMATION_SPEED : 0, r: this.gridColorLight.r, g: this.gridColorLight.g, b: this.gridColorLight.b, ease: "none"});
        }else if(activeTheme === "cosmosDark"){
            gsap.to(this.clearColor, {duration: animated ? INTERACTION_ANIMATION_SPEED : 0, r: this.clearColorDark.r, g: this.clearColorDark.g, b: this.clearColorDark.b, ease: "none", onUpdate: ()=>{this.renderer.setClearColor(this.clearColor);}});
            gsap.to((this.ground.material as THREE.MeshBasicMaterial).color, {duration: animated ? INTERACTION_ANIMATION_SPEED : 0, r: this.groundColorDark.r, g: this.groundColorDark.g, b: this.groundColorDark.b, ease: "none"});
            gsap.to((this.grid.material as any).uniforms.uColor.value, {duration: animated ? INTERACTION_ANIMATION_SPEED : 0, r: this.gridColorDark.r, g: this.gridColorDark.g, b: this.gridColorDark.b, ease: "none"});
        }
    }

    /**
     * Sync three proxy objects with their instances
     */
    syncInstances(){
        Repository.groupsShapesMesh?.sync();

        Repository.linksShapesMesh?.sync();
        Repository.linksIndicatorsMesh?.sync();

        Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) =>{
            symbolShapeMesh.sync();
        });

        Repository.virtualLinksShapesMesh?.sync();

        Repository.sharedLinksShapesMesh?.sync();

        Repository.symbolsIconsMesh?.sync();
        Repository.symbolsAppsMesh?.sync();
        Repository.symbolsAppsCountsMesh?.sync();
        Repository.symbolsLegendsMesh?.sync();

        Repository.mesh?.fullLegend.sync();
    }

    animate() {
        if(!this.isRendererPaused){

            // Apply camera controls to camera
            if(CosmosThree.globalDebug){
                this.camControls.getTarget( this.graphCameraTargetHelper.position );
            }

            if(CosmosThree.globalDebug && this.gui){ this.gui.refresh(); }

            this.camControls.update(this.clock.getDelta());

            this.syncInstances();

            ThreeMeshUI.update();

            this.renderer.clear();
            this.renderer.render(this.graphScene, this.graphCamera);

            this.renderer.clearDepth();
            this.renderer.render(this.guiScene, this.guiCamera);

            if(this.usePostProcessing){ this.composer?.render(); }

            if(this.showStats){
                this.stats!.update();
                if(CosmosThree.globalDebug){ this.rendererStats.update(this.renderer); }
            }

            if(CosmosThree.globalDebug) { this.renderer.info.reset(); }
        }

        this.requestedAnimationFrameId = requestAnimationFrame(() => { this.animate(); });
    }

    pauseRenderer(){
        this.isRendererPaused = true;
    }

    resumeRenderer(){
        this.isRendererPaused = false;
    }

    toogleRenderer(){
        this.isRendererPaused = !this.isRendererPaused;
    }

    destroy (){
        cancelAnimationFrame(this.requestedAnimationFrameId);

        this.camControls.dispose();
        this.inputManager.dispose();
        if(CosmosThree.globalDebug){ this.gui?.dispose(); }

        this.renderer.domElement.removeEventListener('resize', this.updateSize.bind(this), false);
        window.removeEventListener('resize', this.updateSize.bind(this), false);

        this.renderer.domElement.remove();

        if(CosmosThree.globalDebug){
            this.stats!.dom.remove();
            this.rendererStats.domElement.remove();
        }

        this.graphScene.children.forEach((child)=>{
            child.clear();
        });

        this.guiScene.children.forEach((child)=>{
            child.clear();
        });

        this.loadManager = null;

        CosmosThree.iconsAtlasTexture.dispose();
        CosmosThree.iconsAtlasMap?.clear();

        this.ground.geometry.dispose();
        (this.ground.material as THREE.Material).dispose();

        this.grid.geometry.dispose();
        (this.grid.material as THREE.Material).dispose();

        if(CosmosThree.globalDebug){
            this.graphCameraTargetHelper.geometry.dispose();
            (this.graphCameraTargetHelper.material as THREE.Material).dispose();

            this.light1Helper.dispose();
            this.light1CameraHelper.dispose();
            this.light2Helper.dispose();
            this.light2CameraHelper.dispose();

            this.meshBBoxHelper.dispose();

            this.meshSphereHelper.geometry.dispose();
            (this.meshSphereHelper.material as THREE.Material).dispose();

            this.meshTransformedBBoxHelper.dispose();

            this.dynamicSphereHelper.geometry.dispose();
            (this.dynamicSphereHelper.material as THREE.Material).dispose();

            this.dynamicBoundsHelper.dispose();
        }

        this.renderer.dispose();
    }
}