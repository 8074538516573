import type { StateCreator } from 'zustand'
import type { FormGroup, FormItem } from '../types/form.ts';
import type { FeaturesFromUrl } from '../utils/url-query-parser.ts';

import {OrganicLayoutGroupSubstructureScope} from '@celonis/yfiles';

export enum LINK_DISPLAY_TYPES {
    STRAIGHT = 'straight',
    ORTHOGONAL = 'orthogonal',
}

export enum LAYOUTING_LIBRARY {
    YFILES = 'yFiles',
}

export enum FEATURES {
    LAYOUT_COMPACTNESS_FACTOR = 'layoutCompactnessFactor',
    LAYOUT_GROUP_COMPACTNESS_FACTOR = 'layoutGroupCompactnessFactor',
    LAYOUT_QUALITY_TIME_RATIO = 'layoutQualityTimeRatio',
    LAYOUT_GROUP_SUBSTRUCTURE_SCOPE = 'layoutGroupSubstructureScope',
}

const featuresDefinition: FormGroup[] = [{
        id: 'layoutingYFiles',
        label: 'YFiles layout options',
        items: [
            {
                name: FEATURES.LAYOUT_COMPACTNESS_FACTOR,
                label: 'Layout compactness factor',
                type: 'slider',
                defaultValue: 0.0,
                attributes: {
                    min: 0,
                    max: 1,
                    step: 0.1
                },
                info: 'Used during layout algorithm. Smaller values result in less compact drawings, greater values result in more compact drawings. Recommended 0.1'
            },
            {
                name: FEATURES.LAYOUT_GROUP_COMPACTNESS_FACTOR,
                label: 'Layout group compactness factor',
                type: 'slider',
                defaultValue: 0.1,
                attributes: {
                    min: 0,
                    max: 1,
                    step: 0.1
                },
                info: 'Used during layout algorithm. Affects only "Folder clustering policy". The compactness ranges from 0 to 1 where 0 results in group nodes not affecting the overall layout too much while 1 forces nodes in the same group to be clustered tightly. Recommended 0.1'
            },
            {
                name: FEATURES.LAYOUT_GROUP_SUBSTRUCTURE_SCOPE,
                label: 'Layout group substructure scope',
                type: 'select',
                info: 'Only for organic layout. Sets the scope specifier for group substructures. Group substructures that lie in the specified scope are treated as substructures in the layout process, i.e., the child nodes are arranged on a disk that is contained in the group node.',
                options: [
                    {
                        label: 'No groups',
                        value: OrganicLayoutGroupSubstructureScope.NO_GROUPS,
                    },
                    {
                        label: 'All groups',
                        value: OrganicLayoutGroupSubstructureScope.ALL_GROUPS,
                    },
                    {
                        label: 'Groups without edges',
                        value: OrganicLayoutGroupSubstructureScope.GROUPS_WITHOUT_EDGES,
                    },
                    {
                        label: 'Groups without inter edges',
                        value: OrganicLayoutGroupSubstructureScope.GROUPS_WITHOUT_INTER_EDGES,
                    },
                ],
                defaultValue: OrganicLayoutGroupSubstructureScope.NO_GROUPS,
            },
            {
                name: FEATURES.LAYOUT_QUALITY_TIME_RATIO,
                label: 'Layout quality time ratio',
                type: 'slider',
                defaultValue: 0.6,
                attributes: {
                    min: 0,
                    max: 1,
                    step: 0.1
                },
                info: 'Affects the organic layout only. Sets the ratio of layout quality versus running time.'
            }
        ]
    }
];

export interface Features {
    [k: string]: string | boolean | number
}

const resolveDefaultFeatures = () => {
    const features: Features = {}
    let localStorageFeatures: Features = {};
    try {
        localStorageFeatures = JSON.parse(localStorage.getItem('global:features') || '{}')
    } catch (err) { /* ignore */ }

    featuresDefinition.forEach((group) => {
        group.items.forEach((item) => {
            if (typeof localStorageFeatures[item.name] !== 'undefined') {
                features[item.name] = localStorageFeatures[item.name];
                // @TODO hack for the form now
                item.defaultValue = localStorageFeatures[item.name];
                return;
            }
            if (typeof item.defaultValue === 'undefined') {
                return;
            }
            features[item.name] = item.defaultValue
        })
    })
    return features
}

export type GetFeatureValue = (name: string) => string | boolean | number;

export interface FeaturesSlice {
    featuresDefinition: FormGroup[];
    features: Features;
    setFeatureValue: (name: string, value: string | boolean | number) => void;
    getFeatureValue: GetFeatureValue;
    setFeaturesFromUrl: (featuresFromUrl: FeaturesFromUrl) => void;
}

export const createFeaturesSlice: StateCreator<FeaturesSlice> = (set, get) => ({
    featuresDefinition: featuresDefinition,
    features: resolveDefaultFeatures(),
    setFeatureValue: (name: string, value: string | boolean | number) => set((state) => {
        const features = {...state.features};
        features[name] = value;
        localStorage.setItem('global:features', JSON.stringify(features));
        return {features}
    }),
    getFeatureValue: (name: string) => get().features[name],
    setFeaturesFromUrl: (featuresFromUrl: FeaturesFromUrl) => set(() => {
        for (const [key, value] of Object.entries(featuresFromUrl)) {
            if (typeof value === 'undefined') {
                continue;
            }
            let definition: FormItem | undefined;
            get().featuresDefinition.forEach((group) => {
                for (const item of group.items) {
                    if (item.name === key) {
                        definition = item;
                        return;
                    }
                }
            })
            if (!definition) {
                continue;
            }
            let parsedValue: string | boolean = value;
            switch (definition.type) {
                case 'checkbox': {
                    parsedValue = value === 'true';
                    break;
                }
                default: {
                    break;
                }
            }
            get().setFeatureValue(key, parsedValue);
        }
        return {};
    }),
})
