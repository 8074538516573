type Props = {
    className?: string,
}

export const ArrowLeft = ({className}: Props) => {

    return (
        <svg className={'w-20 h-2 ' + (className || '')} viewBox="0 0 79 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.13965 4.00008L77.1396 4.00001M1.13965 4.00008L4.36143 1.33008M1.13965 4.00008L4.36143 6.67008"
                  stroke="currentColor" strokeWidth="1.9" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

