import { Repository } from '../../common/Repository';

import { BaseSymbol } from '../BaseSymbol';

export class BrokenSymbol extends BaseSymbol {
    constructor () {
        super();
    }
}

Repository.install('BrokenSymbol', BrokenSymbol);
