import {useNavigate, useRouteError} from "react-router-dom";
import MainHeader from "../components/MainHeader";
import supabase, { supabasePublic } from '../utils/supabase-client';
import {useEffect} from 'react';
import {Logger} from "../utils/Logger";


export default function ErrorPage() {
  const error = useRouteError() as {status: number, statusText: string, data: {message: string}};
	Logger.error(error);

	const navigate = useNavigate();

	useEffect(() => {
		const checkSession = async () => {
			const { data } = await supabasePublic.auth.getSession();
			if (!data.session) {
				await supabase.auth.signOut();
				navigate('/');
			}
		}
		checkSession();
	}, []);

	let title = 'An error occurred!';
	let message = 'Something went wrong!';

	if (error.status === 500) {
		message = error.data.message;
	}

	if (error.status === 404) {
		title = 'Not found';
		message = 'Could not find resource or page.';
	}

  return (
	<>
	<MainHeader />
    <div>
      <h1>{title}</h1>
      <p>
        <i>{message}</i>
      </p>
    </div>
	</>
  );
}