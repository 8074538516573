import { SupabaseClientOptions, createClient } from "@supabase/supabase-js";


const supabaseUrl = (import.meta as any).env.VITE_SUPABASE_API_URL;
const supabaseKey = (import.meta as any).env.VITE_SUPABASE_ANON_KEY;

const supabaseOptions: SupabaseClientOptions<'atelier'> = {
	db: {
		schema: 'atelier'
	}
};

const supabase = createClient(supabaseUrl, supabaseKey, supabaseOptions);
export const supabasePublic = createClient(supabaseUrl, supabaseKey);

export default supabase;
