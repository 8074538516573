import type { SerializedSymbol } from "../../BaseSymbol";
import type { SerializedWebhook } from '../webhook/WebhookSymbol.ts';

import { Repository } from "../../../common/Repository";
import { BaseSymbol } from "../../BaseSymbol";

export class MakeWebhookSymbol extends BaseSymbol {

	originalData: SerializedWebhook | null = null;

	constructor () {
        super();
    }

	override get type() {
		return super.type;
	}

	override set type(value) {
		super.type = value;

		this.iconSlug = "gateway";
	}

	override fromJSON(object: SerializedSymbol): void {
		object.typeLabel = 'Make Webhook';
		super.fromJSON(object)
	}

}

Repository.install('make:webhook', MakeWebhookSymbol);