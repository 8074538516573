import * as THREE from 'three';

import { gsap } from "gsap";

import { INTERACTION_ANIMATION_SPEED } from '../common/constants';
import { BaseObject } from '../common/BaseObject';
import { TBaseSymbol } from '../Mesh';
import { LinkVirtualShape } from './LinkVirtualShape';
import { Repository } from '../common/Repository';

export interface SerializedLink {
	id: string;
	type: string;
	source?: string;
	target?: string;
	attributes: unknown;
}

export abstract class BaseVirtualLink extends BaseObject{
	readonly isLink = true;
	readonly isShared = false;
	readonly isVirtual = true;

	interactionLayer = 1;

	private _type = "";

	protected _source?: TBaseSymbol;
	protected _target?: TBaseSymbol;

	private _shape: LinkVirtualShape;

	private _visible = false;

	private drawProgressTo: gsap.QuickToFunc;

    constructor () {
        super();

		this._shape = new LinkVirtualShape();
		this.three.add(this._shape.three);

		// Quick tween functions to boost performance even more
		this.drawProgressTo = gsap.quickTo(this, "drawProgress", { duration: INTERACTION_ANIMATION_SPEED, ease: "power2.Out" });
	}

	public get type() {
		return this._type;
	}

	public set type(value) {
		if(value === this._type){return}
		this._type = value;

		this._shape.instancedOrBatchedMesh = Repository.virtualLinksShapesMesh!;
	}

	override get matrixNeedsUpdate() {
		return this._matrixNeedsUpdate;
	}
	override set matrixNeedsUpdate(value) {
		this._matrixNeedsUpdate = value;

		this._shape.matrixNeedsUpdate = value;
	}

	get visible() {
		return this._visible;
	}
	set visible(value) {
		if(this._visible === value) return;

		this._visible = value;

		if(!this._visible){
			this.hideIn();
		}else{
			this.hideOut();
		}
	}

	override get color() {
		return this._color;
	}
	override set color(value: THREE.Color) {
		if (value === this._color) return;
		this._color = value;
		this.colorNeedsUpdate = true;

		this._shape.color = value;
	}

	override get opacity() {
		return this._opacity;
	}
	override set opacity(value) {
		if(this._opacity === value) return;
		this._opacity = value;
		this.opacityNeedsUpdate = true;

		this._shape.opacity = value;
	}

	get drawProgress() {
		return this._drawProgress;
	}
	set drawProgress(value) {
		if(this._drawProgress === value) return;
		this._drawProgress = value;
		this.drawProgressNeedsUpdate = true;

		this._shape.drawProgress = value;
	}

	get source() {
		return this._source;
	}
	set source(value) {
		if (this._source === value) return;
		if (this._source) {
			this._source.unlinked.dispatch('output', this);
		}
		this._source = value;
		if (this._source) {
			this._source.linked.dispatch('output', this);
		}
	}

	get target() {
		return this._target;
	}

	set target(value) {
		if (this._target === value) return;
		if (this._target) {
			this._target.unlinked.dispatch('input', this);
		}
		this._target = value;
		if (this._target) {
			this._target.linked.dispatch('input', this);
		}
	}

	update(){
		if(!this.source || !this.target){ return }

		this.three.position.x = this.source.three.position.x;
		this.three.position.z = this.source.three.position.z;
		this.matrixNeedsUpdate = true;

		this._shape.updateGeometry(this.source, this.target);

		const prevColor = this.target.color.getHSL({} as THREE.HSL);
		prevColor.s = this.source.active ? prevColor.s : 0;
		this.color = new THREE.Color().setHSL(prevColor.h, prevColor.s, prevColor.l).lerp(new THREE.Color(0xffffff), 0.1);
	}

	hide(){
		this.drawProgress = 0;
	}

	hideIn(){
		this.drawProgressTo(0);
	}

	hideOut(){
		this.drawProgressTo(1);
	}

	override dispose(){
		super.dispose();
	}
}