type Props = {
    className?: string,
}

export const Links = ({className}: Props) => {
    return (
        <svg className={'h-4 w-4 box-content ' + className || ''} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.46973 3.29952H7.10246M7.10246 3.29952L5.30294 1.5M7.10246 3.29952L5.30294 5.09903" stroke="currentColor"
                  strokeWidth="1.72487" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.46973 12.7004H9.89105M9.89105 12.7004L8.09153 10.9009M9.89105 12.7004L8.09153 14.4999"
                  stroke="currentColor" strokeWidth="1.72487" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.46973 8.00049L14.5307 8.0002M14.5307 8.0002L12.7312 6.20068M14.5307 8.0002L12.7312 9.79972"
                  stroke="currentColor" strokeOpacity="0.6" strokeWidth="1.72487" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}