export type AppEnvMode = 'development' | 'production';

const modes: AppEnvMode[] = ['development', 'production'];

export class AppEnv {

    private static appEnvLsKey = 'appEnv';
    private static mode: AppEnvMode = 'production';

    static resolve() {

        this.mode = import.meta.env.MODE as AppEnvMode || this.mode;

        let presetEnv: AppEnvMode | undefined = undefined;
        if (typeof window !== 'undefined') {
            const lsValue = localStorage.getItem(this.appEnvLsKey);
            if (lsValue && modes.includes(lsValue as AppEnvMode)) {
                presetEnv = lsValue as AppEnvMode;
            }
            if (!lsValue) {
                localStorage.setItem(this.appEnvLsKey, this.mode);
            }
        }
        if (presetEnv) {
            this.mode = presetEnv;
        }
    }

    static getMode() {
        return this.mode;
    }
}