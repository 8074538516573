export class LocalStorageManager {

    static getValues<T extends Record<string, any>>(group: string, defaultValues: T): T {
        const result: Record<string, any> = {};
        for (const key of Object.keys(defaultValues)) {
            const value = this.getValue(group, key);
            if (value !== null) {
                result[key] = value;
            } else {
                result[key] = defaultValues[key];
            }
        }
        return result as T;
    }

    static getValue<T>(group: string, key: string): T | null {
        const rawValue = localStorage.getItem(`${group}:${key}`);
        if (rawValue === null) {
            return null;
        }
        let result: T = rawValue as T;
        try {
            result = JSON.parse(rawValue) as T;
        } catch (e) {/* */}
        return result;
    }

    static setValue<T>(group: string, key: string, value: T | null): void {
        localStorage.setItem(`${group}:${key}`, typeof value === 'string' ? value : JSON.stringify(value));
    }

}