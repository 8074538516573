import * as THREE from 'three';

import { gsap } from "gsap";

import { Repository } from "../../../common/Repository";
import { BaseSymbol, SerializedSymbol } from "../../BaseSymbol";
import { FilterDefinition, FilterGroupDefinition, FilterMappingType } from '../../../../store/Filter';
import { INTERACTION_ANIMATION_SPEED, PREVENT_Z_FIGHTING, SYMBOL_APP_HEIGHT, SYMBOL_APP_POSITIONS, SYMBOL_HEIGHT, SYMBOL_MAKE_SCENARIO_SIZE, SYMBOL_ROUNDED_RECT_RADIUS } from '../../../common/constants';
import { SymbolApp } from '../../SymbolApp';
import { SymbolIcon } from '../../SymbolIcon';
import { SymbolAppsCount } from '../../SymbolAppsCount';

export interface SerializedApp {
	slug: string;
	theme: string;
}

export interface ScenarioFolder {
	id: string,
	name: string
}

export interface SerializedScenario extends SerializedSymbol {
	attributes: {
		instant: boolean;
		active: boolean;
		apps: SerializedApp[];
		folder?: ScenarioFolder,
		organization? : {
			id: string,
			name: string,
		},
		team?: {
			id: string,
			name: string,
		},
	}
}

export class MakeScenarioSymbol extends BaseSymbol {

	originalData: SerializedScenario | null = null;

	private _instant = false;

	private _appShapes: SymbolApp[] = [];
	private _appsIcons: SymbolIcon[] = [];
	private appsCount: SymbolAppsCount | null = null;
	private instantIcon: SymbolIcon | null = null;

	private _appShapesScalesTo: gsap.QuickToFunc[] = [];
	private _appIconsPositionsTo: gsap.QuickToFunc[] = [];
	private _appCountPositionTo: gsap.QuickToFunc | null = null;

	private _instantIconPositionTo: gsap.QuickToFunc | null = null;

	get instant() {
		return this._instant;
	}

    constructor () {
        super();

		this._icon.three.position.x = 1 * SYMBOL_MAKE_SCENARIO_SIZE / 4.5;
		this._icon.three.position.z = - 1 * SYMBOL_MAKE_SCENARIO_SIZE / 3.5;
    }

	get detailDisplayProps() {
		return [
			{
				key: 'attributes.organization.name',
				label: 'Organization'
			},
			{
				key: 'attributes.team.name',
				label: 'Team'
			},
			{
				key: 'attributes.folder.name',
				label: 'Folder'
			}, ...this.detailBaseDisplayProps];
	}

	override get type() {
		return super.type;
	}

	override set type(value) {
		super.type = value;

		this.shapeType = "rect";

		this.iconSlug = "make";
	}

	override get matrixNeedsUpdate() {
		return super.matrixNeedsUpdate;
	}
	override set matrixNeedsUpdate(value) {
		super.matrixNeedsUpdate = value;

		for(let i = 0; i < this._appShapes.length;i++){
			this._appShapes[i].matrixNeedsUpdate = true;
		}

		for(let i = 0; i < this._appsIcons.length;i++){
			this._appsIcons[i].matrixNeedsUpdate = true;
		}

		if(this.appsCount){
			this.appsCount.matrixNeedsUpdate = true;
		}

		if(this.instantIcon){
			this.instantIcon.matrixNeedsUpdate = true;
		}
	}

	override get opacity() {
		return super.opacity;
	}
	override set opacity(value) {
		super.opacity = value;

		for(let i = 0 ; i < this._appShapes.length; i++){
			this._appShapes[i].opacity = value;
		}

		for(let i = 0 ; i < this._appsIcons.length; i++){
			this._appsIcons[i].opacity = value;
		}

		if(this.appsCount){
			this.appsCount.opacityNeedsUpdate = true;
		}

		if(this.instantIcon){
			this.instantIcon.opacityNeedsUpdate = true;
		}
	}

	override get greyscale() {
		return super.greyscale;
	}
	override set greyscale(value) {
		super.greyscale = value;

		for(let i = 0 ; i < this._appShapes.length; i++){
			this._appShapes[i].greyscale = value;
		}

		for(let i = 0 ; i < this._appsIcons.length; i++){
			this._appsIcons[i].greyscale = value;
		}

		if(this.appsCount){
			this.appsCount.greyscaleNeedsUpdate = true;
		}

		if(this.instantIcon){
			this.instantIcon.greyscaleNeedsUpdate = true;
		}
	}

	override getGeometry() {
        const shape = new THREE.Shape();
		const angleStep = Math.PI * 0.5;
		const size = SYMBOL_MAKE_SCENARIO_SIZE;
		const radius = SYMBOL_ROUNDED_RECT_RADIUS;

		shape.absarc(size / 2 - radius, size / 2 - radius, radius, angleStep * 0, angleStep * 1);
		shape.absarc(-size / 2 + radius, size / 2 - radius, radius, angleStep * 1, angleStep * 2);
		shape.absarc(-size / 2 + radius, -size / 2 + radius, radius, angleStep * 2, angleStep * 3);
		shape.absarc(size / 2 - radius, -size / 2 + radius, radius, angleStep * 3, angleStep * 4);

		const geometry = new THREE.ExtrudeGeometry(shape, {
			depth: SYMBOL_HEIGHT,
			bevelEnabled: false,
			bevelThickness: 0,
			bevelSize: 0,
			bevelSegments: 0,
			curveSegments: 6
		}).rotateX(-Math.PI/2);

        return geometry;
    }

	override highlightIn(){
		const scale =  THREE.MathUtils.lerp(2, 1.5, this.spotlightLevel );

		for(let i = 0 ; i < this._appShapesScalesTo.length; i++){
			this._appShapesScalesTo[i](scale * 1.1);
		}

		for(let i = 0 ; i < this._appIconsPositionsTo.length; i++){
			this._appIconsPositionsTo[i](SYMBOL_APP_HEIGHT * scale * 1.1 + PREVENT_Z_FIGHTING);
		}

		if(this._appCountPositionTo){
			this._appCountPositionTo(SYMBOL_APP_HEIGHT * scale * 1.1 + PREVENT_Z_FIGHTING);
		}

		if(this._instantIconPositionTo){
			this._instantIconPositionTo(SYMBOL_HEIGHT * scale + PREVENT_Z_FIGHTING);
		}

		super.highlightIn();
	}

	override highlightOut(){
		super.highlightOut();

		for(let i = 0 ; i < this._appShapesScalesTo.length; i++){
			this._appShapesScalesTo[i](1);
		}

		for(let i = 0 ; i < this._appIconsPositionsTo.length; i++){
			this._appIconsPositionsTo[i](SYMBOL_APP_HEIGHT + PREVENT_Z_FIGHTING);
		}

		if(this._appCountPositionTo){
			this._appCountPositionTo(SYMBOL_APP_HEIGHT + PREVENT_Z_FIGHTING);
		}
		
		if(this._instantIconPositionTo){
			this._instantIconPositionTo(SYMBOL_HEIGHT + PREVENT_Z_FIGHTING);
		}
	}

	override fromJSON(object: SerializedScenario): void {
		object.typeLabel = 'Make Scenario';

		super.fromJSON(object);

		this.active = object.attributes.active;

		if(object.attributes.apps && object.attributes.apps.length !== 0){

			// for some reason apps are sometimes null
			const nonNullApps = object.attributes.apps.filter(app => app !== null);
			
			const filteredApps = nonNullApps.slice(0, 3);

			// If there are more than 4 apps we add another app to show the number
			const countApps = (nonNullApps.length >= 4) ? filteredApps.length + 1: filteredApps.length;

			for(let i = 0; i < countApps; i++){
				const appShape = new SymbolApp();
				appShape.three.position.x = SYMBOL_APP_POSITIONS[i].x;
				appShape.three.position.z = SYMBOL_APP_POSITIONS[i].z;
				appShape.three.rotation.y = SYMBOL_APP_POSITIONS[i].rotation;
				this.three.add(appShape.three);

				appShape.instancedOrBatchedMesh = Repository.symbolsAppsMesh!;
				this._appShapes.push(appShape);

				const appShapeScaleTo = gsap.quickTo(appShape.three.scale, "y", { duration: INTERACTION_ANIMATION_SPEED, ease: "power2.inOut", onUpdate: () => { appShape.matrixNeedsUpdate = true; } });
				this._appShapesScalesTo.push(appShapeScaleTo);

				if(i < 3){
					appShape.color = new THREE.Color(filteredApps[i].theme);

					const appIcon = new SymbolIcon();
					appIcon.slug = filteredApps[i].slug;
					appIcon.three.scale.set(0.5, 0.5 , 0.5);
					appIcon.three.position.x = appShape.three.position.x;
					appIcon.three.position.z = appShape.three.position.z;
					appIcon.three.position.y = SYMBOL_APP_HEIGHT + PREVENT_Z_FIGHTING;
					this.three.add(appIcon.three);

					appIcon.instancedOrBatchedMesh = Repository.symbolsIconsMesh!;
					this._appsIcons.push(appIcon);

					const appIconsPositionTo = gsap.quickTo(appIcon.three.position, "y", { duration: INTERACTION_ANIMATION_SPEED, ease: "power2.inOut", onUpdate: () => { appIcon.matrixNeedsUpdate = true; } });
					this._appIconsPositionsTo.push(appIconsPositionTo);
				}else{
					if(nonNullApps[i]){
						appShape.color = new THREE.Color(nonNullApps[i].theme);
					}
					appShape.three.scale.set(1.25, 1, 1.25);

					this.appsCount = new SymbolAppsCount();
					this.appsCount.three.position.x = appShape.three.position.x;
					this.appsCount.three.position.z = appShape.three.position.z;
					this.appsCount.three.position.y = SYMBOL_APP_HEIGHT + PREVENT_Z_FIGHTING;
					this.three.add(this.appsCount.three);

					this.appsCount.instancedOrBatchedMesh = Repository.symbolsAppsCountsMesh!;

					this.appsCount.count = `+${Math.min(nonNullApps.length - 3, 99)}`; // We set the count to max 99 to keep it contained at 3 chars 
					// this.appsCount.count = "+99";

					this._appCountPositionTo = gsap.quickTo(this.appsCount.three.position, "y", { duration: INTERACTION_ANIMATION_SPEED, ease: "power2.inOut", onUpdate: () => { this.appsCount!.matrixNeedsUpdate = true; } });
				}

				appShape.active = this.active;
			}
		}

		if(object.attributes?.instant !== undefined){
			this.instantIcon = new SymbolIcon();
			this.instantIcon.slug = object.attributes?.instant ? "thunder-ui" : "clock-ui";
			// this.instantIcon.color = new THREE.Color(0xab28d6); // I don't like how it looks, at least yet...
			this.instantIcon.three.scale.set(0.65, 0.65, 0.65);
			this.instantIcon.three.position.x = SYMBOL_MAKE_SCENARIO_SIZE / 3.25;
			this.instantIcon.three.position.z = 0;
			this.instantIcon.three.position.y = SYMBOL_APP_HEIGHT + PREVENT_Z_FIGHTING;
			this.three.add(this.instantIcon.three);

			this.instantIcon.instancedOrBatchedMesh = Repository.symbolsIconsMesh!;

			this._instantIconPositionTo = gsap.quickTo(this.instantIcon.three.position, "y", { duration: INTERACTION_ANIMATION_SPEED, ease: "power2.inOut", onUpdate: () => { this.instantIcon!.matrixNeedsUpdate = true; } });
		}
	}

	static override getFilterGroupDefinition(): FilterGroupDefinition[] {
		return [
			{
				id: 'scenarios',
				label: 'Scenario status',
				open: true,
				priority: 100
			},
			{
				id: 'scenarioFolder',
				label: 'Scenario folder',
				priority: 90
			},
			{
				id: 'scenarioApps',
				label: 'Scenario apps',
				priority: 80
			}
		]
	}

	static override getFilterDefinition(): FilterDefinition[] {
		return [
			{
				groupId: 'scenarios',
				label: 'Active scenarios',
				id: 'activeScenarios',
				inputType: 'checkbox',
				filterType: 'boolean',
				filterMapping: 'attributes.active',
				filterMappingType: 'boolean' as FilterMappingType,
				value: true
			}, {
				groupId: 'scenarios',
				label: 'Inactive scenarios',
				id: 'inactiveScenarios',
				inputType: 'checkbox',
				filterType: 'boolean',
				filterMapping: 'attributes.active',
				filterMappingType: 'boolean' as FilterMappingType,
				value: false
			}, {
				groupId: 'scenarioFolder',
				label: {
					mapping: 'attributes.folder.name',
				},
				id: {
					mapping: 'attributes.folder.id',
				},
				inputType: 'checkbox',
				filterType: 'string',
				filterMapping: 'attributes.folder.id',
				filterMappingType: 'string' as FilterMappingType,
				undefinedLabel: 'Uncategorized'
			}, {
				groupId: 'scenarioApps',
				label: {
					mapping: 'attributes.apps[].title',
				},
				id: {
					mapping: 'attributes.apps[].slug',
				},
				inputType: 'checkbox',
				filterType: 'string',
				filterMapping: 'attributes.apps[].slug',
				filterMappingType: 'string' as FilterMappingType
			}
		]
	}
}

Repository.install('make:scenario', MakeScenarioSymbol);