import type { StateCreator } from 'zustand'
import type { Nullable } from '../types/common.ts'
import type { Mesh } from '../three/Mesh.ts';
import { v4 as uuidv4 } from 'uuid';
import ThemeManager from './../utils/ThemeManager.ts';

type Cosmos = {
    ready: boolean;
    reloadId: Nullable<string>;
}

export interface CosmosSlice {
    cosmos: Cosmos;
    uiInteractive: boolean;
    setCosmosReady: (ready: boolean) => void;
    reloadCosmos: () => void;
    currentMeshInstance: Nullable<Mesh>;
    setCurrentMeshInstance: (mesh: Mesh | null) => void;
    enableUIInteractions: () => void;
    disableUIInteractions: () => void;
}

export const createCosmosSlice: StateCreator<CosmosSlice> = (set) => ({
    cosmos: {
        ready: false,
        reloadId: null
    },
    uiInteractive: true,
    setCosmosReady: (ready: boolean) => set((state) => (
      { cosmos: { ...state.cosmos, ...{ ready: ready } } })
    ),
    reloadCosmos: () => set((state) => (
      { cosmos: { ...state.cosmos, ...{ reloadId: uuidv4() } } })
    ),
    currentMeshInstance: null,
    setCurrentMeshInstance: (mesh: Mesh | null) => set(() => ({ currentMeshInstance: mesh })),
    enableUIInteractions: () => {
        ThemeManager.updateCssVariable('uiPointerEvents', 'auto');
        return set(() => (
            { uiInteractive: true }
        ))
    },
    disableUIInteractions: () => {
        ThemeManager.updateCssVariable('uiPointerEvents', 'none');
        return set(() => (
            { uiInteractive: false }
        ))
    }
})
