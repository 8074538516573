import * as THREE from 'three';

import { gsap } from "gsap";

import { PREVENT_Z_FIGHTING, SELECTION_INDICATOR_SCALE_ANIM, SELECTION_INDICATOR_SIZE_FACTOR, SYMBOL_CIRCLE_RADIUS, SYMBOL_MAKE_SCENARIO_SIZE, SYMBOL_ROUNDED_RECT_RADIUS, SYMBOL_ROUNDED_TRIANGLE_RADIUS, SYMBOL_SIZE } from './constants';
import { BaseSymbol } from '../symbols/BaseSymbol';

export class SelectionIndicator extends THREE.Group{

    private _shapeRound = new THREE.Mesh();
    private _shapeRect = new THREE.Mesh();
    private _shapeTri = new THREE.Mesh();

    private _tlAnim: gsap.core.Timeline;

    constructor(){
        super();

        this._shapeRound.geometry = this.createGeometry("round");
        this._shapeRound.material = this.createMaterial();
        this._shapeRound.visible = false;

        this._shapeRect.geometry = this.createGeometry("rect");
        this._shapeRect.material = this.createMaterial();
        this._shapeRect.visible = false;

        this._shapeTri.geometry = this.createGeometry("tri");
        this._shapeTri.material = this.createMaterial();
        this._shapeTri.visible = false;

        this.receiveShadow = true;

        this.add(this._shapeRound);
        this.add(this._shapeRect);
        this.add(this._shapeTri);

        this._tlAnim = new gsap.core.Timeline({paused: true, repeat: -1, repeatDelay: 1});
		this._tlAnim.add(gsap.fromTo([this._shapeRound.material], {opacity: 1}, {duration: 1.2, opacity: 0, ease: "none" }), 0);
        this._tlAnim.add(gsap.fromTo([this._shapeRect.material], {opacity: 1}, {duration: 1.2, opacity: 0, ease: "none" }), 0);
        this._tlAnim.add(gsap.fromTo([this._shapeTri.material], {opacity: 1}, {duration: 1.2, opacity: 0, ease: "none" }), 0);
        this._tlAnim.add(gsap.fromTo(this._shapeRound.scale, {x: 1, z: 1}, {duration: 1.2, x: SELECTION_INDICATOR_SCALE_ANIM, z: SELECTION_INDICATOR_SCALE_ANIM, ease: "none"}), 0);
        this._tlAnim.add(gsap.fromTo(this._shapeRect.scale, {x: 1, z: 1}, {duration: 1.2, x: SELECTION_INDICATOR_SCALE_ANIM, z: SELECTION_INDICATOR_SCALE_ANIM, ease: "none"}), 0);
        this._tlAnim.add(gsap.fromTo(this._shapeTri.scale, {x: 1, z: 1}, {duration: 1.2, x: SELECTION_INDICATOR_SCALE_ANIM, z: SELECTION_INDICATOR_SCALE_ANIM, ease: "none"}), 0);
    }

    setFrom(symbol: BaseSymbol){
        this.position.copy(symbol.three.position);
		this.position.y = this.position.y + PREVENT_Z_FIGHTING;

        if(symbol.shapeType === "round"){
            this._shapeRound.visible = true;
            this._shapeRect.visible = false;
            this._shapeTri.visible = false;
        }else if(symbol.shapeType === "rect"){
            this._shapeRound.visible = false;
            this._shapeRect.visible = true;
            this._shapeTri.visible = false;
        }else if(symbol.shapeType === "tri"){
            this._shapeRound.visible = false;
            this._shapeRect.visible = false;
            this._shapeTri.visible = true;
        }

        (this._shapeRound.material as THREE.MeshLambertMaterial).color.set(symbol.color);
        (this._shapeRect.material as THREE.MeshLambertMaterial).color.set(symbol.color);
        (this._shapeTri.material as THREE.MeshLambertMaterial).color.set(symbol.color);

        this._tlAnim.play(0);
    }

    hide(){
        this._tlAnim.pause();

        this._shapeRound.visible = false;
        this._shapeRect.visible = false;
        this._shapeTri.visible = false;
    }

    createGeometry(type: "round" | "rect" | "tri"){
        const shape = new THREE.Shape();
        const hole = new THREE.Path();

        switch (type){
            case "round": {
                const angleStep = Math.PI * 0.5;
                const size = SYMBOL_SIZE * SELECTION_INDICATOR_SIZE_FACTOR;
                const radius = SYMBOL_CIRCLE_RADIUS * SELECTION_INDICATOR_SIZE_FACTOR;

                shape.absarc(size / 2 - radius, size / 2 - radius, radius, angleStep * 0, angleStep * 1);
                shape.absarc(-size / 2 + radius, size / 2 - radius, radius, angleStep * 1, angleStep * 2);
                shape.absarc(-size / 2 + radius, -size / 2 + radius, radius, angleStep * 2, angleStep * 3);
                shape.absarc(size / 2 - radius, -size / 2 + radius, radius, angleStep * 3, angleStep * 4);

                const holeSize = SYMBOL_SIZE;
                const holeRadius = SYMBOL_CIRCLE_RADIUS;
                hole.absarc(holeSize / 2 - holeRadius, holeSize / 2 - holeRadius, holeRadius, angleStep * 0, angleStep * 1);
                hole.absarc(-holeSize / 2 + holeRadius, holeSize / 2 - holeRadius, holeRadius, angleStep * 1, angleStep * 2);
                hole.absarc(-holeSize / 2 + holeRadius, -holeSize / 2 + holeRadius, holeRadius, angleStep * 2, angleStep * 3);
                hole.absarc(holeSize / 2 - holeRadius, -holeSize / 2 + holeRadius, holeRadius, angleStep * 3, angleStep * 4);

                break;
            }
            case "rect": {
                const angleStep = Math.PI * 0.5;
                const size = SYMBOL_MAKE_SCENARIO_SIZE * SELECTION_INDICATOR_SIZE_FACTOR;
                const radius = SYMBOL_ROUNDED_RECT_RADIUS * SELECTION_INDICATOR_SIZE_FACTOR;

                shape.absarc(size / 2 - radius, size / 2 - radius, radius, angleStep * 0, angleStep * 1);
                shape.absarc(-size / 2 + radius, size / 2 - radius, radius, angleStep * 1, angleStep * 2);
                shape.absarc(-size / 2 + radius, -size / 2 + radius, radius, angleStep * 2, angleStep * 3);
                shape.absarc(size / 2 - radius, -size / 2 + radius, radius, angleStep * 3, angleStep * 4);

                const holeSize = SYMBOL_MAKE_SCENARIO_SIZE;
                const holeRadius = SYMBOL_ROUNDED_RECT_RADIUS;
                hole.absarc(holeSize / 2 - holeRadius, holeSize / 2 - holeRadius, holeRadius, angleStep * 0, angleStep * 1);
                hole.absarc(-holeSize / 2 + holeRadius, holeSize / 2 - holeRadius, holeRadius, angleStep * 1, angleStep * 2);
                hole.absarc(-holeSize / 2 + holeRadius, -holeSize / 2 + holeRadius, holeRadius, angleStep * 2, angleStep * 3);
                hole.absarc(holeSize / 2 - holeRadius, -holeSize / 2 + holeRadius, holeRadius, angleStep * 3, angleStep * 4);

                break;
            }
            case "tri": {
                const r = (SYMBOL_SIZE * SELECTION_INDICATOR_SIZE_FACTOR) / 1.25;
                const ha = THREE.MathUtils.degToRad(30);
                const h = SYMBOL_ROUNDED_TRIANGLE_RADIUS * SELECTION_INDICATOR_SIZE_FACTOR * (Math.sin(ha) + Math.cos(ha) * Math.sqrt(3));
                const center = r - Math.min(r, h);

                const base = new THREE.Vector2(0, center);
                const c = new THREE.Vector2();
                const v = new THREE.Vector2();

                const angleStep = Math.PI / 6;

                for (let i = 0; i < 3; i++) {
                    const a = angleStep * 4 * i;
                    v.copy(base).rotateAround(c, a);
                    shape.absarc(v.x, v.y, SYMBOL_ROUNDED_TRIANGLE_RADIUS * SELECTION_INDICATOR_SIZE_FACTOR, angleStep + a, angleStep * 5 + a);
                }

                const r_hole = SYMBOL_SIZE / 1.25;
                const ha_hole = THREE.MathUtils.degToRad(30);
                const h_hole = SYMBOL_ROUNDED_TRIANGLE_RADIUS * (Math.sin(ha_hole) + Math.cos(ha_hole) * Math.sqrt(3));
                const center_hole = r_hole - Math.min(r_hole, h_hole);

                const base_hole = new THREE.Vector2(0, center_hole);
                const c_hole = new THREE.Vector2();
                const v_hole = new THREE.Vector2();

                const angleStep_hole = Math.PI / 6;

                for (let i = 0; i < 3; i++) {
                    const a = angleStep_hole * 4 * i;
                    v_hole.copy(base_hole).rotateAround(c_hole, a);
                    hole.absarc(v_hole.x, v_hole.y, SYMBOL_ROUNDED_TRIANGLE_RADIUS, angleStep_hole + a, angleStep_hole * 5 + a);
                }

                break;
            }
        }

        shape.holes = [hole];

		const geometry = new THREE.ExtrudeGeometry(shape, {
			depth: 0,
			bevelEnabled: false,
			bevelThickness: 0,
			bevelSize: 0,
			bevelSegments: 0,
			curveSegments: 6
		}).rotateX(-Math.PI/2);

        return geometry;
    }

    createMaterial(){
        const material = new THREE.MeshLambertMaterial(
            {
                color: 0xffffff,
                transparent: true,
                // wireframe: false
            },
        );

        return material;
    }

    override clear(): this{
		return this.dispose();
	}

    dispose(){
        (this._shapeRound.material as THREE.Material).dispose();
        this._shapeRound.geometry.dispose();

        (this._shapeRect.material as THREE.Material).dispose();
        this._shapeRect.geometry.dispose();

        (this._shapeTri.material as THREE.Material).dispose();
        this._shapeTri.geometry.dispose();

        super.clear();

        return this;
    }

}