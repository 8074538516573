import type { FormItem } from '../../types/form.ts'
import type { FormGeneratorOnChange, FormGeneratorData } from './FormGenerator.tsx'
import type {ChangeEvent} from 'react';
import {useState} from 'react';
import InfoBlock from './utils/InfoBlock.tsx';

function Checkbox({parameters, data, onChange}: { parameters: FormItem, data?: FormGeneratorData, onChange?: FormGeneratorOnChange }) {

  const defaultValue = data?.[parameters.name] ?? (parameters.defaultValue as boolean ?? false);

  const [checked, setChecked] = useState(defaultValue);

  const changed = (e: ChangeEvent<HTMLInputElement>) => {
     setChecked(e.target.checked);
     if (onChange) {
         onChange(parameters.name, e.target.checked)
     }
  }

  return (
    <div>
        <div className="relative flex items-start">
            <div className="flex h-6 items-center">
                <input
                    onChange={changed}
                    id={parameters.name}
                    aria-describedby={parameters.name + '-description'}
                    name={parameters.name}
                    type="checkbox"
                    className=""
                    checked={checked as boolean}
                />
            </div>
            <div className="ml-3 leading-6 select-none">
                <label htmlFor={parameters.name} className="font-medium">
                    {parameters.label}
                </label>
                {parameters?.info && (
                  <InfoBlock text={parameters?.info} />
                )}
            </div>
        </div>
    </div>
  )
}

export default Checkbox;