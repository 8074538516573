import {ReactNode} from 'react'

import {MagnifyingGlassIcon} from '@heroicons/react/20/solid';
import {FunnelIcon} from '@heroicons/react/24/outline';
import {useAppStore} from '@/store/Store.ts';
import {classNames} from '@/utils/helpers.ts'
import {useMemo, useRef, ChangeEvent} from 'react';
import UseFiltersState from './FiltersState.ts';
import Filters from './Filters.tsx';
import Tooltip from './../Tooltip.tsx';
import {Close} from '@/assets/icons/Close.tsx';

type Props = {
    children?: ReactNode;
}

export default function FiltersPanel({children}: Props) {

    const settingsElem = useRef<HTMLDivElement>(null);

    const {activeFiltersCount} = UseFiltersState();

    const {
        showSettings,
        openSettings,
        closeSettings,
        filterSearch,
        setFiltersSearch,
        resetFilters,
        uiInteractive
    } = useAppStore((state) => {
        return {
            showSettings: state.showSettings,
            openSettings: state.openSettings,
            closeSettings: state.closeSettings,
            filterSearch: state.filtersSearch,
            setFiltersSearch: state.setFiltersSearch,
            resetFilters: state.resetFilters,
            uiInteractive: state.uiInteractive
        }
    });

    const showPanel = useMemo(() => {
        return showSettings;
    }, [showSettings])

    const onFilterSearch = (event: ChangeEvent<HTMLInputElement>): void => {
        setFiltersSearch(event.target.value.trim().toLowerCase())
    }

    return (
        <>
            <div className={'ui-panel left-0 ml-6 absolute ui-pointer-events'}>
                <div
                    className={classNames(showPanel ? 'w-60 h-auto' : 'w-11 h-11', 'max-h-[80vh] group flex flex-col transition-[width,height] duration-300 ease-in-out overflow-hidden')}
                    ref={settingsElem}
                >
                    <div className={classNames(showPanel ? '' : '', 'flex flex-row items-center gap-0.5 pt-1')}>
                        <Tooltip message={'Filters'} position={'top'} enabled={!showPanel}>
                            <button
                                type="button"
                                onClick={openSettings}
                                className={classNames(showPanel ? 'pointer-events-none' : 'ui-pointer-events pt-0.5', 'h-9 flex items-center gap-2 left-0 self-start rounded-md rounded-tr-none rounded-br-none px-1.5 pl-2.5')}
                            >
                                <FunnelIcon
                                    className={classNames(showPanel ? 'h-4 w-4' : 'h-6 w-6', 'transition-[width,height] duration-100')}
                                    aria-hidden="true"
                                />
                            </button>
                        </Tooltip>
                        <div
                            className={classNames(showPanel ? 'opacity-100 ui-pointer-events w-auto' : 'w-0 opacity-0 pointer-events-none', 'transition-opacity duration-300 ease-in-out')}
                        >
                            <h2 className="inline-block text-xl font-semibold leading-7">
                                Filters
                            </h2>
                            {activeFiltersCount > 0 && (
                                <div className="absolute top-0 right-0 mr-8 mt-2 ui-pointer-events">
                                    <a
                                        className="cursor-pointer text-base-content-300 hover:underline"
                                        onClick={() => {
                                            setFiltersSearch('');
                                            resetFilters()
                                        }}
                                    >
                                        Reset
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                    {showPanel && (
                        <div className="absolute right-1 top-1 hidden pr-1 pt-1.5 sm:block">
                            <button
                                tabIndex={-1}
                                type="button"
                                className="ui-btn-icon-close"
                                onClick={closeSettings}
                            >
                                <Close />
                            </button>
                        </div>
                    )}
                    <div
                        className={classNames(showPanel ? 'opacity-100 ui-pointer-events' : 'opacity-0 pointer-events-none', 'overflow-y-auto w-60 h-auto')}
                    >
                        <div className="mb-4 px-2">
                            <div className="relative rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <MagnifyingGlassIcon className="h-4 w-4 text-base-content-300 relative"
                                                         aria-hidden="true"/>
                                </div>
                                <input
                                    type="text"
                                    name="searchInFilters"
                                    id="searchInFilters"
                                    className="ui-search-input w-full py-0.5"
                                    placeholder="Search in filters"
                                    onChange={onFilterSearch}
                                    value={filterSearch}
                                    disabled={!uiInteractive}
                                />
                            </div>
                        </div>
                        <div className="min-h-60 px-2 overflow-auto">
                            <Filters/>
                        </div>
                        {children}
                    </div>
                    <div className={'w-full h-3'}>
                    </div>
                </div>
                {!showPanel && activeFiltersCount > 0 &&
                    <div
                        className={classNames('ui-active-count-indicator-circle')}
                    >{activeFiltersCount}</div>
                }
            </div>
        </>
    )
}