import { Repository } from "../../common/Repository";
import { TBaseLink, TBaseSymbol } from "../../Mesh";

export const getSymbolRelatives = (
    input: TBaseSymbol[],
    includeLinks = false,
    maxLevel = 1,
    currentLevel = 0,
    alreadyChecked: string[] = [],
    out: (TBaseSymbol | TBaseLink)[] = []
): (TBaseSymbol | TBaseLink)[] => {
  if (!Array.isArray(input)) {
    input = [input];
  }
  if (maxLevel === 0) {
    return input;
  }
  if (currentLevel >= maxLevel) {
    return out;
  }
  const toCheck: TBaseSymbol[] = [];
  for (const symbol of input) {
    if (alreadyChecked.includes(symbol.id)) {
      continue;
    }
    out.push(symbol);
    const links = symbol.links;
    for (const link of links) {
      const nodesToCheck = [link.target, link.source];
      for (const nodeToCheck of nodesToCheck) {
        if (
            nodeToCheck &&
            nodeToCheck.id !== symbol.id
        ) {
          out.push(nodeToCheck);
          toCheck.push(nodeToCheck);
          if (!alreadyChecked.includes(link.id) && includeLinks) {
            out.push(link);
            alreadyChecked.push(link.id);
          }
        }
      }
    }
    alreadyChecked.push(symbol.id);
  }
  return getSymbolRelatives(toCheck, includeLinks, maxLevel, currentLevel + 1, alreadyChecked, out);
}

export const getElementsToSpotlight = (startingSymbols: TBaseSymbol[], level: number) => {
    const elementsToHighlight: {level: number, symbols: TBaseSymbol[], links: TBaseLink[]}[] = [];
    const visitedSymbols = new Set();
    const visitedLinks = new Set();
    const queue = startingSymbols.map(symbol => ({ symbol, level: 0 }));

    while (queue.length > 0) {
        const item = queue.shift();

        if (!item || !item.symbol || visitedSymbols.has(item.symbol) || item.symbol.filtered || !item.symbol.visible) {
            continue; // Skip if item, symbol is undefined, or symbol has been visited
        }

        visitedSymbols.add(item.symbol);

        const { symbol, level: currentLevel } = item;

        if (currentLevel > level) {
            break; // Stop BFS if we exceed the desired level
        }

        if (!elementsToHighlight[currentLevel]) {
            elementsToHighlight[currentLevel] = { level: currentLevel, symbols: [], links: [] };
        }

        elementsToHighlight[currentLevel].symbols.push(symbol);

        if (currentLevel < level) {
            symbol.neighbors.forEach((neighborSymbol) => {
                if (!visitedSymbols.has(neighborSymbol)) {
                    queue.push({ symbol: neighborSymbol, level: currentLevel + 1 });
                }
            });
            symbol.links.forEach((link) => {
                if (!visitedLinks.has(link)) {
                    visitedLinks.add(link);
                    elementsToHighlight[currentLevel].links.push(link);
                }
            });
        }
    }

    return elementsToHighlight;
}

export const getUnmatchedSymbolsAndLinks = ( symbols: TBaseSymbol[], links: TBaseLink[]) => {
    const matchedSymbols = new Set();
    const matchedLinks = new Set();

    // Collect symbols and links
    for (let i = 0; i < symbols.length; i++){
        symbols.forEach(symbol => matchedSymbols.add(symbol));
    }

    for (let i = 0; i < links.length; i++){
        links.forEach(links => matchedLinks.add(links));
    }

    // Filter out symbols and links not present in the object
    const unmatchedSymbols: TBaseSymbol[] = Repository.mesh!.symbols.filter(symbol => !matchedSymbols.has(symbol));
    const unmatchedLinks: TBaseLink[] = Repository.mesh!.links.filter(link => !matchedLinks.has(link));

    const unmatchedSharedSymbols = Repository.mesh!.sharedSymbols.filter(symbol => !matchedSymbols.has(symbol));
    const unmatchedSharedLinks = Repository.mesh!.sharedLinks.filter(link => !matchedLinks.has(link));

    unmatchedSymbols.push(...unmatchedSharedSymbols);
    unmatchedLinks.push(...unmatchedSharedLinks);

    return { unmatchedSymbols, unmatchedLinks };
}