import type { FilterItem, FilterListGroup } from '../../../store/Filter.ts';

import {ChevronRightIcon} from '@heroicons/react/20/solid';
import {classNames} from '../../../utils/helpers.ts';
import {useAppStore} from '../../../store/Store.ts'
import {useMemo, useState, FormEvent} from 'react';
import UseFiltersState from './FiltersState.ts';
import {useLazyEffect} from '../../../utils/hooks.ts';

export default function Filters() {

    const [hoveredFilterItem, setHoveredFilterItem] = useState<FilterItem | null>(null);

    const {
        filterList,
        updateActiveFilters,
        filtersSearch,
        toggleFilterGroupOpen,
        currentMeshInstance,
        uiInteractive
    } = useAppStore((state) => {
        return {
            filterList: state.filterList,
            updateActiveFilters: state.updateActiveFilters,
            filtersSearch: state.filtersSearch,
            toggleFilterGroupOpen: state.toggleFilterGroupOpen,
            currentMeshInstance: state.currentMeshInstance,
            uiInteractive: state.uiInteractive
        }
    });

    useLazyEffect(() => {
        currentMeshInstance?.spotlightOnFilterItem(hoveredFilterItem);
    }, [hoveredFilterItem]);

    const onCheckboxChange = (e: FormEvent<HTMLInputElement>, group: string, item: FilterItem) => {
        const elem = e.target as HTMLInputElement;
        updateActiveFilters(group, item, elem.checked);
    }

    const { activeFiltersCountByGroup } = UseFiltersState();

    const filteredFilterList = useMemo(() => {
        const toDisplay: FilterListGroup[] = [];

        if (!filterList) {
            return [];
        }

        filterList.map((group) => {
            if (!filtersSearch.length || filtersSearch.length < 2) {
                group.itemsToShow = group.items;
                group.isOpenBySearch = false;
                toDisplay.push(group);
                return;
            }
            const itemsToShow: FilterItem[] = [];
            group.items.map((item) => {
                if (item.label.toLowerCase().includes(filtersSearch)) {
                    itemsToShow.push(item);
                }
            });
            if (itemsToShow.length) {
                group.isOpenBySearch = true;
                group.itemsToShow = itemsToShow;
                toDisplay.push(group);
            }
        });

        return toDisplay;
    }, [filterList, filtersSearch]);

    const onFilterItemHover = (filterItem: FilterItem) =>{
        setHoveredFilterItem(filterItem);
    }

    const onFiltersLeave = () => {
        setHoveredFilterItem(null);
    }

    return (
        <>
            {filterList && (
                <div
                    className="mb-4">
                    <ul
                        role="list"
                        className="flex flex-col gap-2"
                        onPointerLeave={() => {onFiltersLeave()}}
                    >
                        {filteredFilterList.map((group) => (
                            <li key={group.id}>
                                <button
                                    onClick={() => { toggleFilterGroupOpen(group.id) }}
                                    onPointerOver={() => {onFiltersLeave()}}
                                    className={classNames(
                                        group.isOpen ? 'bg-base-300 bg-opacity-80 rounded-t-md' : 'hover:bg-base-200 rounded-md',
                                        'theme-transition relative flex items-center justify-between w-full text-left p-2 py-1 gap-x-2 leading-6 font-semibold'
                                    )}
                                >
                                    <span className="flex items-center gap-x-2">
                                        <ChevronRightIcon
                                            className={classNames(
                                                group.isOpen ? 'rotate-90 text-base-content-500' : 'text-base-content-300',
                                                'h-5 w-5 shrink-0'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {group.label}
                                    </span>
                                    {!group.isOpen && activeFiltersCountByGroup(group.id) > 0 && (
                                        <div
                                            className={classNames('ui-active-count-indicator-circle right-1 top-0')}
                                        >
                                            {activeFiltersCountByGroup(group.id)}
                                        </div>
                                    )}
                                </button>
                                {(group.isOpen || group.isOpenBySearch) && (
                                    <div className="theme-transition py-1 px-2.5 bg-base-50 rounded-b-md">
                                        <ul>
                                            {group.itemsToShow && group.itemsToShow.map((item) => (
                                                <li
                                                    key={item.id}
                                                    className="relative flex items-start"
                                                    onPointerOver={() => {onFilterItemHover(item)}}
                                                >
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            disabled={!uiInteractive || ((item.disabled ?? false) && !item.selected)}
                                                            onChange={(e) => onCheckboxChange(e, group.id, item)}
                                                            id={item.id}
                                                            aria-describedby="comments-description"
                                                            name={item.id}
                                                            type="checkbox"
                                                            className=""
                                                            checked={item.selected as boolean}
                                                        />
                                                    </div>
                                                    <div className="ml-3 leading-6 w-full">
                                                        <label htmlFor={item.id} className="font-regular flex justify-between">
                                                            <span className={classNames(item.disabled ? 'text-base-content-100' : '', '[word-break:break-word]')}>{item.label}</span>
                                                            {!item.disabled && (
                                                              <span className="inline-block pl-1 text-right break-normal">{item.numberOfSymbols ?? 0}</span>
                                                            )}
                                                        </label>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}