import * as THREE from 'three';

import { gsap } from "gsap";

import { BaseObject } from '../common/BaseObject';
import { GroupShape } from './GroupShape';
import { cleanString, getBoundsRectBySymbols } from '../utils/utils';
import { GROUP_TITLE_HEIGHT, GROUP_TITLE_PADDING, INTERACTION_ANIMATION_SPEED, PREVENT_SHADOW_LEAK, PREVENT_Z_FIGHTING, SYMBOL_APP_HEIGHT, SYMBOL_MAKE_SCENARIO_SIZE } from '../common/constants';
import { Repository } from '../common/Repository';
import * as ThreeMeshUI from '../utils/three/three-mesh-ui/three-mesh-ui.js';
import { isNull } from 'lodash';
import { TBaseSymbol } from '../Mesh.js';

export interface SymbolDisplayableProp {
	key: string,
	label: string
}

export class BaseGroup extends BaseObject{

	private _symbols: TBaseSymbol[] = [];

	readonly isGroup = true;

	private _visible = false;
	private _filtered = false;

	private _titleString = "";

	private _shape: GroupShape;
    private _title: ThreeMeshUI.Block;
	private _titleText: ThreeMeshUI.Text;

	private opacityTo: gsap.QuickToFunc;
	private hideTo: gsap.QuickToFunc;
	private hideTitleTo: gsap.QuickToFunc;

    constructor () {
		super();

		this._shape = new GroupShape();
		this.three.add(this._shape.three);

		this._title = new ThreeMeshUI.Block({
			width: 1,
			height: GROUP_TITLE_HEIGHT,
			padding: GROUP_TITLE_PADDING,
			// whiteSpace: 'noWrap',
			bestFit: 'shrink',
			fontFamily: 'InterMedium',
			fontTexture: 'InterMedium'
		});

		// The 'Frame' child just bothers us and creates trasnparency problems, so we remove it
		this._title.remove(this._title.children[0]);
		
		this._title.castShadow = false;
		this._title.rotation.x = - Math.PI / 2;

		Repository.mesh!.groupsTitlesCont.add(this._title as any);

		this._titleText = new ThreeMeshUI.Text({
			content: "",
			fontSize: 2,
			fontColor: new THREE.Color(0x9505D9).convertLinearToSRGB()
		}),

		this._title.add( this._titleText );

		this._shape.instancedOrBatchedMesh = Repository.groupsShapesMesh!;

        // Quick tween functions to boost performance even more
		this.opacityTo = gsap.quickTo(this, "opacity", { duration: INTERACTION_ANIMATION_SPEED, ease: "none" });
		this.hideTo = gsap.quickTo(this.three.position, "y", { duration: INTERACTION_ANIMATION_SPEED, ease: "power2.inOut", onUpdate: () => { this.matrixNeedsUpdate = true; } });
		this.hideTitleTo = gsap.quickTo(this._title.position, "y", { duration: INTERACTION_ANIMATION_SPEED, ease: "power2.inOut", onUpdate: () => { this._title.updateMatrix(); } });
    }

    override get matrixNeedsUpdate() {
		return this._matrixNeedsUpdate;
	}

	override set matrixNeedsUpdate(value) {
		this._matrixNeedsUpdate = value;

		this._shape.matrixNeedsUpdate = value;
	}

	override get color() {
		return this._color;
	}

	override set color(value: THREE.Color) {
		if (value === this._color) return;
		this._color = value;
		this.colorNeedsUpdate = true;

		this._shape.color = value;
	}

	override get opacity() {
		return this._opacity;
	}

	override set opacity(value) {
		if(this._opacity === value) return;
		this._opacity = value;
		this.opacityNeedsUpdate = true;

		this._shape.opacity = value;
	}

	override get greyscale() {
		return this._greyscale;
	}

	override set greyscale(value) {
		if(this._greyscale === value) return;
		this._greyscale = value;
		this.greyscaleNeedsUpdate = true;

		this._shape.greyscale = value;
	}

	get visible() {
		return this._visible;
	}

	set visible(value) {
		if(this._visible === value) return;

		this._visible = value;

		if(!this._filtered){
			if(this._visible){
				this.hideOut();
			}else{
				this.hideIn();
			}
		}
	}

	get filtered() {
		return this._filtered;
	}

	set filtered(value) {
		if(this._filtered === value) return;

		this._filtered = value;

		if(this._visible){
			if(this._filtered){
				this.hideIn();
			}else{
				this.hideOut();
			}
		}
	}

	public get title() {
		return this._titleString;
	}

	public set title(value) {
		if(this._titleString === value) return;

		this._titleString = cleanString(value);

		// The types are wrong and therefore it thinks the set() method doesn't exist.
		this._titleText.set({content: "📁 " + this._titleString});
	}

	public get symbols(): TBaseSymbol[] {
		return this._symbols;
	}

	public set symbols(value: TBaseSymbol[]) {
		this._symbols = value;
	}

	hide(){
		this.opacity = 0;
		this.three.position.y = -(SYMBOL_APP_HEIGHT + PREVENT_Z_FIGHTING + PREVENT_SHADOW_LEAK);
		this.matrixNeedsUpdate = true;
		this._title.position.y = -(SYMBOL_APP_HEIGHT + PREVENT_Z_FIGHTING * 2 + PREVENT_SHADOW_LEAK);
		this._title.updateMatrix();
	}

	hideIn(){
		this.opacityTo(0);
		this.hideTo(-(SYMBOL_APP_HEIGHT + PREVENT_Z_FIGHTING + PREVENT_SHADOW_LEAK));
		this.hideTitleTo(-(SYMBOL_APP_HEIGHT + PREVENT_Z_FIGHTING * 2 + PREVENT_SHADOW_LEAK));
	}

	hideOut(){
		this.opacityTo(1);
		this.hideTo(0);
		this.hideTitleTo(PREVENT_Z_FIGHTING);
	}

    update(){
		const symbols = this._symbols.filter(symbol => !symbol.filtered);

        const bounds = getBoundsRectBySymbols(symbols, SYMBOL_MAKE_SCENARIO_SIZE);

		if(isNaN(bounds.x) || isNull(bounds.x)){
			bounds.x = 0;
			bounds.y = 0;
			bounds.z = 0.001;
			bounds.w = 0.001;
		}

		this.three.position.x = bounds.x + bounds.z / 2;
		this.three.position.z = bounds.y + bounds.w / 2;
		this.matrixNeedsUpdate = true;

		this._shape.width = bounds.z;
		this._shape.height = bounds.w;
		this._shape.updateGeometry();

		// The types are wrong and therefore it thinks the set() method doesn't exist.
		this._title.set({width : bounds.z});
		this._title.position.x = bounds.x + bounds.z / 2;
		this._title.position.z = bounds.y - GROUP_TITLE_HEIGHT / 2;
    }

	override dispose(){
		this.symbols = [];

		this._shape?.dispose();

		ThreeMeshUI.deepDelete(this._title);

		super.dispose();
	}

}