import * as THREE from 'three';
import { BaseObject } from '../common/BaseObject';
import { APPS_COUNT_HEIGHT, APPS_COUNT_WIDTH, SCALE_FACTOR } from '../common/constants';
import { SymbolsAppsCountIM } from './SymbolsAppsCountIM';

export class SymbolAppsCount extends BaseObject{

    private _count = "";

    private _countNeedsUpdate = false;

    constructor(){
        super();
    }

    get count (){
        return this._count || "";
    }

    set count (value: string){
        if(this._count === value) return;
        this._count = value || "";

        (this.instancedOrBatchedMesh as SymbolsAppsCountIM).textureGenerator.createAppsCountAt(this.instanceId, this._count);

        this.countNeedsUpdate = true;
    }

    get countNeedsUpdate() {
        return this._countNeedsUpdate;
    }
    set countNeedsUpdate(value) {
        if(this._countNeedsUpdate === value) return;
        this._countNeedsUpdate = value;
        if(this._instancedOrBatchedMesh){
			(this._instancedOrBatchedMesh as SymbolsAppsCountIM).textNeedsUpdate = value;
		}
    }

    override getGeometry(){
        return new THREE.PlaneGeometry(APPS_COUNT_WIDTH / SCALE_FACTOR, APPS_COUNT_HEIGHT / SCALE_FACTOR, 1, 1).rotateX(-Math.PI / 2);
    }
}