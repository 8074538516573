import { CubeIcon, Squares2X2Icon, MoonIcon, SunIcon } from '@heroicons/react/24/outline';
import { useAppStore } from '../store/Store.ts'
import { classNames } from '../utils/helpers.ts';
import Tooltip from './utils/Tooltip.tsx';

function CosmosToolbar() {
    const {
		cameraMode,
        setCameraMode,
        uiInteractive,
        currentMeshInstance,
        toggleDarkMode,
        darkModeActive
	} = useAppStore((state) => {
        return {
			cameraMode: state.userSettings.cameraMode,
            setCameraMode: state.setCameraMode,
            uiInteractive: state.uiInteractive,
            currentMeshInstance: state.currentMeshInstance,
            toggleDarkMode: state.toggleDarkMode,
            darkModeActive: state.darkModeActive,
		}
    });

    const layout = async () => {
        currentMeshInstance?.filterNodes();
    }

    return (
        <div
            className='ui-panel ui-pointer-events fixed bottom-12 inset-x-0 max-w-max mx-auto gap-3 items-center z-30 flex flex-wrap px-3 py-1.5'>
            <ul className='flex items-center gap-2'>
                <li className="relative">
                    <Tooltip message={"Layout"}>
                        <button
                            type="button"
                            className="ui-btn-icon"
                            onClick={layout}
                            disabled={!uiInteractive}
                        >
                            <div className='flex items-center justify-center w-5 h-5'>
                                <i className="fa fa-magic-wand-sparkles relative top-[0.08rem]" aria-hidden="true" />
                            </div>
                        </button>
                    </Tooltip>
                </li>
            </ul>
            <div className="h-5 w-px bg-base-content/30"></div>
            <ul className='flex items-center gap-2'>
                <li className="relative">
                    <Tooltip message={"3D View"}>
                        <button
                            type="button"
                            className={classNames(cameraMode === "3d" ? 'active' : '', 'ui-btn-icon')}
                            onClick={() => {
                                setCameraMode("3d")
                            }}
                            disabled={!uiInteractive}
                        >
                            <CubeIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </Tooltip>
                </li>
                <li className="relative">
                    <Tooltip message={"2D View"}>
                        <button
                            type="button"
                            className={classNames(cameraMode === "2d" ? 'active' : '', 'ui-btn-icon')}
                            onClick={() => {
                                setCameraMode("2d")
                            }}
                            disabled={!uiInteractive}
                        >
                            <Squares2X2Icon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                    </Tooltip>
                </li>
            </ul>
            <div className="h-5 w-px bg-base-content/30"></div>
            <ul className='flex items-center gap-2'>
                <li className="relative">
                    <Tooltip message={darkModeActive ? "Light Mode" : "Dark Mode"}>
                        <button
                            type="button"
                            className={classNames('ui-btn-icon')}
                            onClick={() => {
                                toggleDarkMode()
                            }}
                            disabled={!uiInteractive}
                        >
                            {darkModeActive ? (
                                <SunIcon className="h-5 w-5" aria-hidden="true"/>
                            ) : (
                                <MoonIcon className="h-5 w-5" aria-hidden="true"/>
                            )}
                        </button>
                    </Tooltip>
                </li>
            </ul>
        </div>
);
}

export default CosmosToolbar;