import type { StateCreator } from 'zustand';
import type {Theme} from './../utils/ThemeManager.ts';

import ThemeManager from './../utils/ThemeManager.ts';
import {Logger} from '../utils/Logger.ts';

export interface ThemeSlice {
    themes: Theme[];
    activeTheme: Theme,
    darkModeActive: boolean,
    setTheme: (themeId: string) => void;
    updateThemeVariable: (key: string, value: string) => void;
    toggleDarkMode: () => void;
}

const initialTheme = ThemeManager.getInitialTheme();

export const createThemeSlice: StateCreator<ThemeSlice> = (set, get) => ({
    themes: ThemeManager.getThemes(),
    activeTheme: initialTheme,
    darkModeActive: ThemeManager.getDarkModeTheme()?.id === initialTheme.id,
    setTheme: (themeId: string) => {
        const theme = ThemeManager.getThemeById(themeId);
        if (!theme) {
            Logger.error(`Theme not found: ${themeId}`);
            return;
        }
        set(() => (
            { activeTheme: theme, darkModeActive: theme.forDarkMode || false }
        ));
        ThemeManager.setTheme(theme);
        ThemeManager.persistTheme(theme);
    },
    updateThemeVariable: (key, value) => {
        ThemeManager.updateCssVariable(key, value);
        set((state) => (
            { activeTheme: {...state.activeTheme, variables: {...state.activeTheme.variables, [key]: value}} }
        ));
    },
    toggleDarkMode: () => {
        if (get().darkModeActive) {
            // @TODO chang to tet last light theme used
            const lightTheme = ThemeManager.getFirstLightTheme();
            if (lightTheme) {
                get().setTheme(lightTheme.id);
                set(() => (
                    { darkModeActive: false }
                ));
            }
        } else {
            const darkTheme = ThemeManager.getDarkModeTheme();
            if (darkTheme) {
                get().setTheme(darkTheme.id);
                set(() => (
                    { darkModeActive: true }
                ));
            }
        }
    }
})
