import { BaseInstancedMesh } from "../common/BaseInstancedMesh";
import { SymbolApp } from "./SymbolApp";

export class SymbolsAppsIM extends BaseInstancedMesh{

    constructor(size : number){
        const app = new SymbolApp();

        super(app.getGeometry(), app.getMaterial(), size);

        app.dispose();

        this.name = "appsInstancedMesh";
    }
}