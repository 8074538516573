import supabase from './supabase-client.ts'

export type StoreUpdateHandlers<StoreType> = {
  insert?: (data: StoreType) => void;
  update?: (data: StoreType) => void;
  delete?: (id: string) => void;
}

export class SupabaseRealtimeListener<StoreType> {

  private schema: string;
  private table: string;
  private handlers: StoreUpdateHandlers<StoreType>;

  constructor (table: string, handlers: StoreUpdateHandlers<StoreType>) {

  this.schema = 'atelier';
  this.table = table;
  this.handlers = handlers;

  }

  setSchema(schema: string) {
    this.schema = schema;
  }

  subscribe() {
    supabase
      .channel(`on-${this.schema}-${this.table}-change`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: this.schema,
          table: this.table
        },
        (payload) => {
          switch (payload.eventType) {
            case 'INSERT':
              if (this.handlers.insert) {
                this.handlers.insert(payload.new as StoreType);
              }
              break;
            case 'UPDATE':
              if (this.handlers.update) {
                this.handlers.update(payload.new as StoreType);
              }
              break;
            case 'DELETE':
              if (this.handlers.delete) {
                this.handlers.delete(payload.old.id);
              }
              break;
          }
        }
      )
      .subscribe()
  }
}