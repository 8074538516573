import * as THREE from 'three';
import { UAParser } from 'ua-parser-js';
import { getGPUTier } from 'detect-gpu';
import { Logger } from '@/utils/Logger';

export type SysInfo = {
    threeVersion: string | undefined;
    osName: string | undefined;
    browserName: string | undefined;
    osVersion: string | undefined;
    browserVersion: string | undefined;
    screenSizeVirtual: string;
    viewportSizeVirtual: string;
    pixelRatio: string;
    screenSizeNative: string;
    viewportSizeNative: string;
    cpuCores: number;
    gpuName: string | undefined;
    gpuTier: number;
    gpuFps: number | undefined;
};

export class SysInfoResolver {

    systemInfo: SysInfo = {
        threeVersion: '',
        osName: '',
        browserName: '',
        osVersion: '',
        browserVersion: '',
        screenSizeVirtual: '',
        viewportSizeVirtual: '',
        pixelRatio: '',
        screenSizeNative: '',
        viewportSizeNative: '',
        cpuCores: 0,
        gpuName: '',
        gpuTier: 0,
        gpuFps: 0,
    };

    constructor(){
        // Three.js Version
        this.systemInfo.threeVersion = THREE.REVISION;

        const { browser, os } = UAParser(window.navigator.userAgent);

        // OS Name
        this.systemInfo.osName = os.name;

        // Browser Name
        this.systemInfo.browserName = browser.name;

        // OS Version
        this.systemInfo.osVersion = os.version;

        // Browser Version
        this.systemInfo.browserVersion = browser.version;

        // Screen size (virtual)
        this.systemInfo.screenSizeVirtual = `${window.screen.availWidth}x${window.screen.availHeight}px`;

        // Viewport size (virtual)
        this.systemInfo.viewportSizeVirtual = `${window.innerWidth}x${window.innerHeight}px`;

        // Pixel Ratio
        this.systemInfo.pixelRatio = `${window.devicePixelRatio}`;

        // Screen size (native)
        this.systemInfo.screenSizeNative = `${window.screen.availWidth * window.devicePixelRatio}x${window.screen.availHeight * window.devicePixelRatio}px`;

        // Viewport size (native)
        this.systemInfo.viewportSizeNative = `${window.innerWidth * window.devicePixelRatio}x${window.innerHeight * window.devicePixelRatio}px`;

        // CPU cores
        this.systemInfo.cpuCores = window.navigator.hardwareConcurrency;
    }

    private async fetchWithTimeout<T>(promise: Promise<T>, timeout: number): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const timer = setTimeout(() => {
                reject(new Error("Operation timed out"));
            }, timeout);

            promise
                .then((result) => {
                    clearTimeout(timer); // Clear the timeout if the operation succeeds
                    resolve(result);
                })
                .catch((error) => {
                    clearTimeout(timer); // Clear the timeout if the operation fails
                    reject(error);
                });
        });
    }

    // Async method that accepts a callback and adds a timeout to getGPUTier
    async getSysInfo(callback?: () => void, timeout = 3000) {
        try {
            // Fetch GPU info with a timeout (e.g., 5 seconds)
            const gpuTier = await this.fetchWithTimeout(getGPUTier(), timeout);

            // Update the systemInfo properties
            this.systemInfo.gpuName = gpuTier.gpu;
            this.systemInfo.gpuTier = gpuTier.tier;
            this.systemInfo.gpuFps = gpuTier.fps;
        } catch (error) {
            // Narrowing the error type
            if (error instanceof Error) {
                if (error.message === "Operation timed out") {
                    Logger.warn("GPU info fetch operation timed out.");
                } else {
                    Logger.error("Error fetching GPU info:", error.message);
                }
            } else {
                Logger.error("An unknown error occurred fetching GPU info:", error);
            }
        }

        // If a callback is provided, execute it. Even if an error ocurred we want to always call it. 
        if (callback) {
            callback();
        }
    }
}