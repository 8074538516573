import { Repository } from "../../../common/Repository";
import { BaseSymbol, SerializedSymbol } from "../../BaseSymbol";

export class MakeDatastoreSymbol extends BaseSymbol {
    constructor () {
        super();
    }

	get detailDisplayProps() {
		return [
			{
				key: 'attributes.size',
				label: 'Size'
			},
			{
				key: 'attributes.maxSize',
				label: 'Max Size'
			}, ...this.detailBaseDisplayProps];
	}

	override get type() {
		return super.type;
	}

	override set type(value) {
		super.type = value;

		this.iconSlug = "datastore";
	}

	override fromJSON(object: SerializedSymbol): void {
		object.typeLabel = 'Make Datastore';
		super.fromJSON(object)
	}
}

Repository.install('make:datastore', MakeDatastoreSymbol);