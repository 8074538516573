import { BaseBatchedMesh } from '../common/BaseBatchedMesh';
import { LinkShape } from './LinkShape';

export class LinksShapesBM extends BaseBatchedMesh{
    constructor(size : number){
        const link = new LinkShape();

        super(size, size * 4, size * 8, link.getMaterial());

        this.layers.enable( link.interactionLayer );

        this.castShadow = false;
        this.receiveShadow = true;

        link.dispose();

        this.name = "linksShapesBM";
    }

    override sync(){
        if(this.matrixNeedsUpdate || this.colorNeedsUpdate || this.opacityNeedsUpdate || this.greyscaleNeedsUpdate){
            for(let i = 0 ; i < this.elems.length; i++){
                const elem = this.elems[i];
                if(elem.matrixNeedsUpdate){
                    this.syncMatrix(elem);
                }
                if(elem.colorNeedsUpdate){
                    this.syncColor(elem);
                }
                if(elem.opacityNeedsUpdate && this._opacitiesTexture){
                    this.syncOpacity(elem);
                }
            }

            this.computeBoundingSphere();
        }
    }
}