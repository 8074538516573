import * as THREE from 'three';
import { CosmosThree } from '../CosmosThree';
import { LEGEND_HEIGHT, LEGEND_POSITION_Y_OFFSET, SCALE_FACTOR } from '../common/constants';
import { BaseSymbol } from './BaseSymbol';

export class SymbolFullLegend extends THREE.Mesh{

    canvas: OffscreenCanvas;
    ctx: OffscreenCanvasRenderingContext2D | null;

    // Evrything will be double size to upscale the generated bitmap and avoid blurring of text as much as possible.
    legendWidth = 100;
    legendHeight = 100;

    private _title = "";

    texture: THREE.CanvasTexture;

    private _vector = new THREE.Vector3();
    private _mtx = new THREE.Matrix4();
    private _symbol: BaseSymbol |null = null;

    constructor(){
        super();

        this.canvas = new OffscreenCanvas(this.legendWidth, this.legendHeight);

        this.ctx = this.canvas.getContext("2d") as OffscreenCanvasRenderingContext2D;

        this.texture = new THREE.CanvasTexture(this.canvas);

        this.material = new THREE.MeshBasicMaterial(
            {
                color: 0xffffff,
                depthWrite: false,
                map: this.texture,
                transparent: true,
                //wireframe: true
            }
        );

        this.geometry = new THREE.PlaneGeometry(1, 1, 1, 1);

        this.visible = false;
    }

    get title (){
        return this._title || "";
    }

    set title (value: string){
        if(this._title === value) return;
        this._title = value || "";
    }

    setFrom(symbol: BaseSymbol | null){
        // if(this._symbol === symbol || (symbol && symbol.title.length < LEGEND_MAX_UNTRIMMED_CHARS)) return;
        if(this._symbol === symbol) return;   

        if(this._symbol){
            // We restore the scale of the previous symbol legend
            this._symbol.legend.three.scale.set(1 ,1 ,1);
            this._symbol.legend.matrixNeedsUpdate = true;
        }

        this._symbol = symbol;

        if(this._symbol){
            this.visible = true;

            this.title = this._symbol.title;

            // We restore set the scale of the current symbol legend to 0 to 'hide' it while we show the full legend
            this._symbol.legend.three.scale.set(0 ,0 ,0);
            this._symbol.legend.matrixNeedsUpdate = true;

            this.updateLegend();
        }else{
            this.visible = false;
        }
    }

    private getMeasuredWidth(text: string ): number {
        if (!this.ctx) return 0;
    
        this.ctx.font = 'normal 24px "Inter var", sans';
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.direction = "inherit";

        return this.ctx.measureText(text).width;
    }

    /* private getMeasuredLineHeight() {
        // Yes, historicaly 'em' unit stands for 'standard size of letter M'.
        // We multiply it by a 1.2 factor to account the height for font ligatures.
        return this.getMeasuredWidth("M") * 1.2;
    } */

    updateLegend(){
        if(this.ctx){
            // compute the necessary dimensions of the legend
            this.legendWidth = Math.ceil(this.getMeasuredWidth(this._title)) || 1;
            this.legendWidth = this.legendWidth + 10;
            this.legendHeight = LEGEND_HEIGHT * 2;
            // this.legendHeight = Math.ceil(this.getMeasuredLineHeight()) || 1;

            // Resize offscreen canvas
            this.canvas.width = this.legendWidth;
            this.canvas.height = this.legendHeight;

            this.ctx.clearRect(0, 0, this.legendWidth, this.legendHeight)

            // When the canvas is resized the context is reset, so we have to apply the font settings again
            this.ctx.font = 'normal 24px "Inter var", sans';
            this.ctx.textAlign = "center";
            this.ctx.textBaseline = "middle";
            this.ctx.direction = "inherit";

            /* this.ctx.fillStyle = "#0f0";
            this.ctx.fillRect(0, 0, this.legendWidth, this.legendHeight);

            // Debug red border
            this.ctx.fillStyle = "#f00";
            this.ctx.fillRect(0, 0, this.legendWidth, 2);
            this.ctx.fillRect(this.legendWidth - 2, 0, 2, this.legendHeight -2);
            this.ctx.fillRect(0, 0, 2, this.legendHeight -2);
            this.ctx.fillRect(0, this.legendHeight - 2, this.legendWidth , 2); */

            this.ctx.shadowColor="rgba(68, 68, 68, 0.75)";
            this.ctx.shadowBlur = 10;

            this.ctx.strokeStyle = "rgba(68, 68, 68, 0.75)";
            this.ctx.lineWidth = 4;
            this.ctx.strokeText(this._title, this.legendWidth / 2, this.legendHeight / 2);

            this.ctx.fillStyle = "#fff";
            this.ctx.fillText(this._title, this.legendWidth / 2, this.legendHeight / 2);
        }

        this.geometry.dispose();
        this.geometry = new THREE.PlaneGeometry(this.legendWidth / 2 / SCALE_FACTOR, this.legendHeight / 2 / SCALE_FACTOR , 1, 1);

        this.texture.dispose();
        this.texture = new THREE.CanvasTexture(this.canvas);
        this.texture.colorSpace = THREE.SRGBColorSpace;
        this.texture.generateMipmaps = false;
        this.texture.anisotropy = CosmosThree.globalAnisotropy;
        (this.material as THREE.MeshBasicMaterial).map = this.texture;
    }

    sync(){
        if(this._symbol && this.visible){
			this._vector.setFromMatrixPosition(this._symbol.three.matrix);

			this._vector.add(CosmosThree.globalMeshOffset);
			this._vector.project(CosmosThree.globalGraphCamera); // `camera` is a THREE.PerspectiveCamera

			this._vector.unproject(CosmosThree.globalGuiCamera);

			// Update the matrices of the objects in the scene
			this._vector.y -= CosmosThree.globalGraphCamera.zoom * LEGEND_POSITION_Y_OFFSET;
			this._mtx.makeTranslation(this._vector);

            this.position.set(this._vector.x, this._vector.y, this._vector.z);
		}
    }

    downloadTexture(): THREE.CanvasTexture | null{
        if(this.ctx){
            // Downloads the offscreen canvas
            this.downloadAsImage('canvas_image.png');
        }

        return this.texture;
    }

    downloadAsImage(filename: string) {
        // Convert OffscreenCanvas to Blob
        this.canvas.convertToBlob().then(blob => {
            // Create URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create anchor element
            const a = document.createElement('a');
            a.download = filename;
            a.href = url;

            // Click the anchor to initiate download
            document.body.appendChild(a);
            a.click();

            // Clean up
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    }

    override clear(): this{
		return this.dispose();
	}

    dispose(){
        (this.material as THREE.Material).dispose();
        this.geometry.dispose();
        this.texture.dispose();

        super.clear();

        return this;
    }
}