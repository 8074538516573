import { Repository } from '../../../common/Repository';

import { BaseSymbol, SerializedSymbol } from '../../BaseSymbol';

export class HubSpotContactsSymbol extends BaseSymbol {

    constructor () {
        super();
    }

	override get type() {
		return super.type;
	}

	override set type(value) {
		super.type = value;

		this.iconSlug = "hubspotcrm";
	}

	override fromJSON(object: SerializedSymbol): void {
		object.typeLabel = 'HubSpot Contacts';
		super.fromJSON(object)
	}

}

Repository.install('hubspot:contacts', HubSpotContactsSymbol);