import type { Connection } from '../../store/Connection.ts'

import { PencilSquareIcon, PlusIcon } from '@heroicons/react/24/outline'
import { Transition } from '@headlessui/react'
import { useAppStore } from '../../store/Store.ts'
import ConnectionTypeForm from './ConnectionTypeForm.tsx'
import ConnectionForm from './ConnectionForm.tsx'
import { useEffect } from 'react'

function ConnectionList({ display }: {  display?: boolean }) {

	const {
		connections,
		showConnectionList,
		toggleConnectionList,
		uiInteractive
	} = useAppStore((state) => {
		return {
			connections: state.connections,
			showConnectionList: state.showConnectionList,
			toggleConnectionList: state.toggleConnectionList,
			uiInteractive: state.uiInteractive
		}
	});

	useEffect(() => {
		if (display) {
			toggleConnectionList(true);
		}
	}, [])

	const openModal = useAppStore((state) => state.openModal);

	const editConnection = (connection: Connection) => {
		openModal(ConnectionForm, { connection: connection });
	}

	const newConnection = () => {
		openModal(ConnectionTypeForm);
	}

	const newConnectionButton = () => {
		return (
			<button
				type="button"
				disabled={!uiInteractive}
				className="flex items-center content-center srelative select-none w-full mb-0.5 rounded-btn border-[1px] border-dashed border-base-content-100 p-1 px-2 gap-2 hover:border-base-content-300 focus:outline-none"
				onClick={newConnection}
			>
				<PlusIcon className="h-6 w-6 text-base-content-500" />
				<span className="block m-auto relative top-[1px] pr-1 text-sm">New Connection</span>
			</button>
		);
	}

	return (
		<Transition
			show={showConnectionList}
			enter="transition-opacity duration-75"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition-opacity duration-150"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			<div className="relative z-10 flex flex-col w-full select-none">
				<nav className="flex flex-1 flex-col rounded-md ui-pointer-events gap-2" aria-label="Sidebar">
					{newConnectionButton()}
					<ul role="list" className="space-y-2 max-h-[30rem] overflow-auto">
						{connections.map((item) => (
							<li key={item.id} className="theme-transition rounded-md bg-base-50 hover:bg-base-200 flex-1 group gap-x-3 p-2 text-sm leading-6">
									<a
										className="cursor-pointer flex items-center justify-between"
										onClick={() => editConnection(item)}
									>
										{item.name}
										<PencilSquareIcon
											className="text-base-content-300 group-hover:text-primary h-6 w-6 shrink-0"
											aria-hidden="true"
										/>
									</a>
							</li>
						))}
					</ul>
				</nav>
			</div>
		</Transition>
	);
}

export default ConnectionList;