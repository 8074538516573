import * as THREE from 'three';

export const ExtensionBatchedGreyscale = {
    name: "extension-batched-greyscale",
    uniforms: {
      greyscale: 0,
      greyscalesTexture: new THREE.Texture()
    },
    vertexShader: (shader: any) => {
      shader = `
        uniform highp sampler2D greyscalesTexture; // Define greyscale texture
        varying float vGreyscale; // Define varying variable to pass to fragment shader
        ${
            shader.replace(
              `#include <batching_vertex>`,
              `
              #include <batching_vertex>

              #ifdef USE_BATCHING
                vGreyscale = texelFetch( greyscalesTexture, ivec2( 0, getIndirectIndex( gl_DrawID ) ), 0 ).r;
              #endif
				      `
            )
        }
      `;
      return shader;
    },
    fragmentShader: (shader: any) => {
      shader = `
        uniform float greyscale;
        varying float vGreyscale; // Receive greyscale value from vertex shader
        ${
            shader.replace(
              '#include <color_fragment>',
              `
              #include <color_fragment>
              
              vec3 color = diffuseColor.rgb;
              vec3 grey = vec3(dot(color, vec3(0.299, 0.587, 0.114)));
              vec3 white = vec3(0.75);
              vec3 greyColor = mix(color, grey, (vGreyscale != 0.0 ? vGreyscale : greyscale));
              float whitenessFactor = max(0.0, (vGreyscale != 0.0 ? vGreyscale : greyscale));
              vec3 finalColor = mix(greyColor, white, whitenessFactor);
              diffuseColor.rgb = finalColor;
              `
            )
        }
      `;
      return shader;
    },
};