import type {FormItem} from '../../types/form.ts'
import type {FormGeneratorData, FormGeneratorOnChange} from './FormGenerator.tsx'

import {type ChangeEvent, useState} from 'react';

function Number({parameters, data, onChange}: {
    parameters: FormItem,
    data?: FormGeneratorData,
    onChange?: FormGeneratorOnChange
}) {

    const defaultValue = data?.[parameters.name] ?? (parameters.defaultValue as number ?? 0);
    const [value, setValue] = useState(defaultValue);

    const changed = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        if (onChange) {
            onChange(parameters.name, parseFloat(e.target.value));
        }
    }

    return (
        <div>
            <label htmlFor={parameters.name} className="block font-medium leading-6">
                {parameters.label}
            </label>
            <div className="mt-2">
                <input
                    id={parameters.name}
                    type={parameters.type}
                    name={parameters.name}
                    required={parameters.required}
                    className="block w-full rounded-md border-0 py-1.5 px-2 shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:outline-none placeholder:text-base-content-300 sm:text-sm sm:leading-6"
                    value={value as string}
                    min={parameters.attributes?.min ?? 0}
                    max={parameters.attributes?.max ?? 10}
                    step={parameters.attributes?.step ?? 1}
                    onChange={changed}
                />
            </div>
        </div>
    )
}

export default Number;