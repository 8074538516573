import { Repository } from '../../../common/Repository';

import { BaseSymbol, SerializedSymbol } from '../../BaseSymbol';

export class GoogleSpreadsheetSymbol extends BaseSymbol {

    constructor () {
        super();
    }

	override get type() {
		return super.type;
	}

	override set type(value) {
		super.type = value;

		this.iconSlug = "google-calendar";
	}

	override fromJSON(object: SerializedSymbol): void {
		object.typeLabel = 'Google Calendar';
		super.fromJSON(object)
	}

}

Repository.install('google:calendar', GoogleSpreadsheetSymbol);