import type { SerializedLink } from "../links/BaseLink";
import{ BaseLink } from "../links/BaseLink";
import type { SerializedSymbol } from "../symbols/BaseSymbol";
import { BaseSymbol } from "../symbols/BaseSymbol";

import { SymbolsShapesIM } from "../symbols/SymbolsShapesIM";
import { LinksShapesBM } from "../links/LinksShapesBM";
import { SymbolsIconsIM } from "../symbols/SymbolsIconsIM";
import { SymbolsLegendsIM } from "../symbols/SymbolsLegendsIM";
import type { Mesh, TBaseLink, TBaseSymbol } from "../Mesh";
import { SymbolsAppsIM } from "../symbols/SymbolsAppsIM";
import { SymbolsAppsCountIM } from "../symbols/SymbolsAppsCountIM";
import { LinksIndicatorsIM } from "../links/LinksIndicatorsIM";
import { Logger } from "../../utils/Logger";
import { GroupsShapesBM } from "../groups/GroupsShapesBM";
import { BaseGroup } from "../groups/BaseGroup";
import { md5 } from 'js-md5';
import { BaseSharedLink } from "../links/BaseSharedLink";
import { BaseSharedSymbol } from "../symbols/BaseSharedSymbol";
import { LinksSharedShapesBM } from "../links/LinksSharedShapesBM";
import { LinksVirtualShapesBM } from "../links/LinksVirtualShapesBM";
import { BaseVirtualLink } from "../links/BaseVirtualLink";

export class Repository {
	private static symbolTypes: Map<string, TBaseSymbol> = new Map();
	private static linkTypes: Map<string, TBaseLink> = new Map();

	static symbols: Map<string, BaseSymbol> = new Map();
	static links: Map<string, BaseLink> = new Map();
	static groups: Map<string, BaseGroup> = new Map();

	static virtualLinks: Map<string, BaseVirtualLink> = new Map();

	static sharedSymbols: Map<string, BaseSharedSymbol> = new Map();
	static sharedLinks: Map<string, BaseSharedLink> = new Map();

	static mesh: Mesh | null = null;

	static symbolsAppsMesh: SymbolsAppsIM | null = null;
	static symbolsIconsMesh: SymbolsIconsIM | null = null;
	static symbolsAppsCountsMesh: SymbolsAppsCountIM | null = null;
	static symbolsLegendsMesh: SymbolsLegendsIM | null = null;

	static symbolsShapesMeshes: Map<string, SymbolsShapesIM> = new Map();

	static linksShapesMesh: LinksShapesBM | null = null;
	static virtualLinksShapesMesh: LinksVirtualShapesBM | null = null;
	static sharedLinksShapesMesh: LinksSharedShapesBM | null = null;
	static linksIndicatorsMesh: LinksIndicatorsIM | null = null;

	static groupsShapesMesh: GroupsShapesBM |null = null;

	static differentSymbolTypes: Map<string, SerializedSymbol[]> = new Map();
	static differentSharedSymbolTypes: Map<string, SerializedSymbol[]> = new Map();

	static assignSymbolTypes(symbols: SerializedSymbol[]){
		for(let i = 0; i < symbols.length; i++){
			let type = symbols[i].type;
			if (!Repository.symbolTypes.get(type)) {
				Logger.warn(`Symbol definition for '${type}' does not exist.`);
				type = 'BrokenSymbol';
			}

			if(!Repository.differentSymbolTypes.has(type)){
				const newSymbolsArray: SerializedSymbol[] = [];
				newSymbolsArray.push(symbols[i]);
				Repository.differentSymbolTypes.set(type, newSymbolsArray);
			}else{
				Repository.differentSymbolTypes.get(type)?.push(symbols[i]);
			}
		}

		return Repository.differentSymbolTypes;
	}

	static assignSharedSymbolTypes(symbols: SerializedSymbol[]){
		for(let i = 0; i < symbols.length; i++){
			let type = symbols[i].type;
			if (!Repository.symbolTypes.get(type)) {
				Logger.warn(`Symbol definition for shared '${type}' does not exist.`);
				type = 'shared';
			}

			if(!Repository.differentSharedSymbolTypes.has(type)){
				const newSymbolsArray: SerializedSymbol[] = [];
				newSymbolsArray.push(symbols[i]);
				Repository.differentSharedSymbolTypes.set(type, newSymbolsArray);
			}else{
				Repository.differentSharedSymbolTypes.get(type)?.push(symbols[i]);
			}
		}

		return Repository.differentSharedSymbolTypes;
	}

	static getTotalApps(symbols: SerializedSymbol[]): number {
		let totalApps = 0;
		for(let i = 0; i < symbols.length; i++){
			if((symbols[i].attributes as any).apps && (symbols[i].attributes as any).apps.length !== 0){
				// for some reason apps are sometimes null
				const nonNullApps = (symbols[i].attributes as any).apps.filter((app: any) => app !== null);
				
				const filteredApps = nonNullApps.slice(0, 3);

				// If there are more than 4 apps we add another app to show the number
				const countApps = (nonNullApps.length >= 4) ? filteredApps.length + 1: filteredApps.length;

				totalApps += countApps;
			}
		}

		return totalApps;
	}

	static getTotalAppsCounts(symbols: SerializedSymbol[]): number {
		let totalAppsCounts = 0;
		for(let i = 0; i < symbols.length; i++){
			if((symbols[i].attributes as any).apps && (symbols[i].attributes as any).apps.length !== 0){
				// for some reason apps are sometimes null
				const nonNullApps = (symbols[i].attributes as any).apps.filter((app: any) => app !== null);
				
				if(nonNullApps.length >= 4){
					totalAppsCounts++;
				}
			}
		}

		return totalAppsCounts;
	}

	static getTotalInstantIcons(symbols: SerializedSymbol[]): number {
		let totalInstantIcons = 0;
		for(let i = 0; i < symbols.length; i++){
			if((symbols[i].attributes as any).instant !== undefined){
				totalInstantIcons++;
			}
		}

		return totalInstantIcons;
	}

	static getTotalLinks(links: SerializedLink[]): number {
		let totalLinksCount = 0;
		const uniqueLinks = [];
		for (let i = 0; i < links.length; i++){
			if (!links[i].source || !links[i].target) continue;

			if(Repository.symbols.get(links[i].source!) === undefined) {
				// console.error(`Link source Symbol for '${serializedLinks[i].id}' does not exist.`);
				continue;
			}
			if(Repository.symbols.get(links[i].target!) === undefined) {
				// console.error(`Link target Symbol for '${serializedLinks[i].id}' does not exist.`);
				continue;
			}

			// Links can be duplicated so we have to check the 'source'/'target' key
			const key = `${links[i].source}:${links[i].target}`;
			if(uniqueLinks.indexOf(key) !== -1){
				// link is duplicated
				continue;
			}

			uniqueLinks.push(key);
			totalLinksCount++;
		}

		return totalLinksCount;
	}

	static getTotalVirtualLinks (symbols: BaseSymbol[]): number{
		let totalVirtualLinksCount = 0;

		for(let i = 0; i < symbols.length; i++){
			totalVirtualLinksCount += symbols[i].duplicates.length;
		}

		return totalVirtualLinksCount;
	}

	static getTotalGroups(symbols: SerializedSymbol[]): number {
		let totalGroupsCount = 0;
		const uniqueGroups: string[] = [];

		for(let i = 0; i < symbols.length; i++){
			const groupId = (symbols[i].attributes as any).folder.id;

			if(uniqueGroups.indexOf(groupId) !== -1){
				// group is not unique
				continue;
			}

			uniqueGroups.push(groupId);
			totalGroupsCount++;
		}

		return totalGroupsCount;
	}

	static buildSymbol(object: SerializedSymbol, type: string, globalInstanceId: number): BaseSymbol | BaseSharedSymbol {
		let klass: any = Repository.symbolTypes.get(object.type);
		if (!klass) {
			Logger.warn(`Symbol definition for '${object.type}' does not exist.`);
			klass = Repository.symbolTypes.get('BrokenSymbol');
		}

		if (!object.id) throw new Error(`Symbol does not have an ID specified.`);
		if (!object.attributes) object.attributes = {};

		const instance = new klass();
		instance.type = type;
		instance.globalInstanceId = globalInstanceId;
		instance.groupId = (object.attributes as any).folder.id;
		instance.fromJSON(object);

		return instance;
	}

	static buildSymbols(serializedSymbols: SerializedSymbol[]): {symbols: BaseSymbol[], sharedSymbols: BaseSharedSymbol[]} {
		// We create the necessary 'shared' instanced meshes
		Logger.time('[perf] mesh: init icons and legends');
		const totalApps = this.getTotalApps(serializedSymbols);
		const totalInstantIcons = this.getTotalInstantIcons(serializedSymbols);
		const totalAppsCounts = this.getTotalAppsCounts(serializedSymbols);

		Repository.symbolsAppsMesh = new SymbolsAppsIM(totalApps);
		Repository.symbolsIconsMesh = new SymbolsIconsIM(serializedSymbols.length + (totalApps - totalAppsCounts) + totalInstantIcons);
		Repository.symbolsAppsCountsMesh = new SymbolsAppsCountIM(totalAppsCounts);
		Repository.symbolsLegendsMesh = new SymbolsLegendsIM(serializedSymbols.length);
		Logger.timeEnd('[perf] mesh: init icons and legends');

		const symbols: BaseSymbol[] = [];

		Repository.differentSymbolTypes.forEach((symbolsGroup: SerializedSymbol[], type) => {
			const symbolsGroupKlass = Repository.symbolTypes.get(type);

			if(!Repository.symbolsShapesMeshes.has(type)){
				Repository.symbolsShapesMeshes.set(type, new SymbolsShapesIM(symbolsGroupKlass, symbolsGroup.length));
			}

			for (let i = 0; i < symbolsGroup.length;i++){
				const instance = Repository.buildSymbol(symbolsGroup[i], type, symbols.length) as BaseSymbol;
				this.symbols.set(symbolsGroup[i].id, instance);
				symbols.push(instance);
			}
		});

		const sharedSymbols: BaseSharedSymbol[] = [];

		Repository.differentSharedSymbolTypes.forEach((symbolsGroup: SerializedSymbol[], type) => {
			for (let i = 0; i < symbolsGroup.length;i++){
				const instance = Repository.buildSymbol(symbolsGroup[i], type, sharedSymbols.length) as BaseSharedSymbol;
				this.sharedSymbols.set(symbolsGroup[i].id, instance);
				sharedSymbols.push(instance);
			}
		});

		return {symbols, sharedSymbols};
	}

	static buildLink(object: SerializedLink): BaseLink | BaseSharedLink {
		let klass: any = Repository.linkTypes.get(object.type);
		if (!klass) {
			Logger.warn(`Link definition for '${object.type}' does not exist.`);
			klass = Repository.linkTypes.get('BrokenLink');
		}

		if (!object.id) throw new Error(`Link does not have an ID specified.`);
		if (!object.attributes) object.attributes = {};

		const instance = new klass();
		instance.type = object.type;
		instance.source = Repository.symbols.get(object.source!) || Repository.sharedSymbols.get(object.source!);
		instance.target = Repository.symbols.get(object.target!) || Repository.sharedSymbols.get(object.target!);
		instance.fromJSON(object);

		return instance;
	}

	static buildLinks(serializedLinks: SerializedLink[], serializedSharedLinks: SerializedLink[]): {links: BaseLink[], sharedLinks: BaseSharedLink[]} {
		if (!serializedLinks.length) {
			return {links: [], sharedLinks: []};
		}

		const totalLinks = Repository.getTotalLinks(serializedLinks);

		Repository.linksShapesMesh = new LinksShapesBM(totalLinks);
		Repository.sharedLinksShapesMesh = new LinksSharedShapesBM(serializedSharedLinks.length);
		Repository.linksIndicatorsMesh = new LinksIndicatorsIM(totalLinks);

		const links: BaseLink[] = [];
		const sharedLinks: BaseSharedLink[] = [];

		for (let i = 0; i < serializedLinks.length;i++){
			// Links sometimes don't have source AND target set, so we have to check that and skip the loop step in that case
			if (!serializedLinks[i].source || !serializedLinks[i].target) continue;

			if(Repository.symbols.get(serializedLinks[i].source!) === undefined) {
				// Logger.error(`Link source Symbol for '${serializedLinks[i].id}' does not exist.`);
				continue;
			}
			if(Repository.symbols.get(serializedLinks[i].target!) === undefined) {
				// Logger.error(`Link target Symbol for '${serializedLinks[i].id}' does not exist.`);
				continue;
			}

			// Links can be duplicated so we have to check the 'source'/'target' key
			const key = `${serializedLinks[i].source}:${serializedLinks[i].target}`;
			if(this.links.has(key)){
				// link is duplicated
				continue;
			}

			const instance = Repository.buildLink(serializedLinks[i]) as BaseLink;
			this.links.set(key, instance);
			links.push(instance);
		}

		for (let i = 0; i < serializedSharedLinks.length;i++){
			const key = `${serializedSharedLinks[i].source}:${serializedSharedLinks[i].target}`;
			const instance = Repository.buildLink(serializedSharedLinks[i]) as BaseSharedLink;
			this.sharedLinks.set(key, instance);
			sharedLinks.push(instance);
		}

		return {links, sharedLinks};
	}

	static buildGroups(serializedSymbols: SerializedSymbol[]): BaseGroup[] {
		const totalGroups = Repository.getTotalGroups(serializedSymbols);

		Repository.groupsShapesMesh = new GroupsShapesBM(totalGroups);

		const groupsMap = new Map<string, SerializedSymbol[]>();

		const groups: BaseGroup[] = [];

		serializedSymbols.forEach(symbol => {
			const folderId = (symbol.attributes as any).folder.id;
			const folderName = (symbol.attributes as any).folder.name;
			if (!groupsMap.has(folderId)) {
				groupsMap.set(folderId, []);

				const group = new BaseGroup();
				group.id = folderId;
				group.title = folderName;
				this.groups.set(folderId, group);
				groups.push(group);
			}
			groupsMap.get(folderId)!.push(symbol);
		});

		return groups;
	}

	static buildVirtualLinks(symbols: BaseSymbol[]){
		const totalVirtualLinks = Repository.getTotalVirtualLinks(symbols);

		Repository.virtualLinksShapesMesh = new LinksVirtualShapesBM(totalVirtualLinks);

		for(let i = 0; i < symbols.length; i++){
			symbols[i].createVirtualLinks();
		}
	}

	static install(name: string, klass: typeof BaseSymbol | typeof BaseLink | typeof BaseSharedSymbol | typeof BaseSharedLink) {
		if (klass.prototype instanceof BaseLink || klass.prototype instanceof BaseSharedLink) {
			Repository.linkTypes.set(name, klass as unknown as TBaseLink);
		} else if (klass.prototype instanceof BaseSymbol || klass.prototype instanceof BaseSharedSymbol) {
			Repository.symbolTypes.set(name, klass as unknown as TBaseSymbol);
		} else {
			throw new Error(`Unknow type of plugin.${klass}`);
		}
	}

	static typeOf(instance: TBaseSymbol | TBaseLink): string {
		if (instance instanceof BaseLink) {
			for (const [name, klass] of Repository.links) {
				if (instance.constructor === klass.constructor) return name;
			}
			throw new Error('Unknow type of link instance.');
		} else if (instance instanceof BaseSymbol) {
			for (const [name, klass] of Repository.symbols) {
				if (instance.constructor === klass.constructor) return name;
			}
			throw new Error('Unknow type of symbol instance.');
		} else if (instance instanceof BaseSharedLink) {
			for (const [name, klass] of Repository.sharedLinks) {
				if (instance.constructor === klass.constructor) return name;
			}
			throw new Error('Unknow type of shared link instance.');
		} else if (instance instanceof BaseSharedSymbol) {
			for (const [name, klass] of Repository.sharedSymbols) {
				if (instance.constructor === klass.constructor) return name;
			}
			throw new Error('Unknow type of shared symbol instance.');
		} else {
			throw new Error('Unknow type of instance.');
		}
	}

	static getSymbolsIndex(symbols?: TBaseSymbol[]) {
		const indexes = [];
		for (const symbol of (symbols ?? this.symbols.values())) {
			indexes.push(`${symbol.id}:${symbol.groupId}`);
		}
		return md5(indexes.sort().join(':'));
	}

	static getLinksIndex(links?: TBaseLink[]) {
		const indexes = [];
		for (const link of (links ?? this.links.values())) {
			indexes.push(`${link.id}:${link.source?.id || ''}:${link.target?.id || ''}`);
		}
		return md5(indexes.sort().join(':'));
	}

	static clear(){
		Repository.differentSymbolTypes.clear();
		Repository.differentSharedSymbolTypes.clear();

		Repository.symbolsShapesMeshes.clear();

		Repository.symbols.clear();
		Repository.links.clear();
		Repository.groups.clear();

		Repository.virtualLinks.clear();

		Repository.sharedSymbols.clear();
		Repository.sharedLinks.clear();

		Repository.mesh = null;
	}
}
