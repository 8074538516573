import * as THREE from 'three';
import { APPS_COUNT_HEIGHT, APPS_COUNT_WIDTH } from '../../common/constants';
import { Logger } from '../../../utils/Logger';
import { CosmosThree } from '../../CosmosThree';

export class AppsCountTextureGenerator{

    canvas: OffscreenCanvas;
    ctx: OffscreenCanvasRenderingContext2D | null;

    width = 1024;
    height = 512;

    // Evrything will be double size to upscale the generated bitmap and avoid blurring of text as much as possible.
    appsCountWidth = APPS_COUNT_WIDTH * 2;
    appsCountHeight = APPS_COUNT_HEIGHT * 2;

    private _paddingX = 2;
    private _paddingY = 2;

    private _accuX = 0 + this._paddingX;
    private _accuY = 0 + this._paddingY;

    private _maxDefaultChars = 3;

    appsCountsPositions: Map<string, {x: number, y: number}> = new Map();

    texture: THREE.CanvasTexture;

    constructor (){
        this.canvas = new OffscreenCanvas(1, 1);
        this.canvas.width = this.width;
        this.canvas.height = this.height;

        this.ctx = this.canvas.getContext("2d") as OffscreenCanvasRenderingContext2D;

        this.ctx.font = 'bold 28px "Inter var", sans';
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.direction = "inherit";

        this.texture = new THREE.CanvasTexture(this.canvas);
        this.texture.colorSpace = THREE.SRGBColorSpace;
        this.texture.anisotropy = CosmosThree.globalAnisotropy;
        // this.texture.generateMipmaps = false;
        //this.texture.minFilter = THREE.NearestMipmapNearestFilter;
        //this.texture.magFilter = THREE.NearestFilter;
    }

    createAppsCountAt(instanceId: number, count: string){
        if(this.ctx){
            this.ctx.save();

            this.ctx.translate(this._accuX, this._accuY);

            /* this.ctx.fillStyle = "#0f0";
            this.ctx.fillRect(0, 0, this.appsCountWidth, this.appsCountHeight); */

            /* // Debug red border
            this.ctx.fillStyle = "#f00";
            this.ctx.fillRect(0, 0, this.appsCountWidth, 2);
            this.ctx.fillRect(this.appsCountWidth - 2, 0, 2, this.appsCountHeight -2);
            this.ctx.fillRect(0, 0, 2, this.appsCountHeight -2);
            this.ctx.fillRect(0, this.appsCountHeight - 2, this.appsCountWidth , 2); */

            count = count.substring(0, this._maxDefaultChars);

            this.ctx.fillStyle = "#fff";
            this.ctx.fillText(count, this.appsCountWidth / 2, this.appsCountHeight / 2 + 2);

            this.ctx.restore();

            this.appsCountsPositions.set(instanceId+"", {x: this._accuX, y: this._accuY});

            this._accuX += this.appsCountWidth + this._paddingX;

            if(this._accuX + this.appsCountWidth + this._paddingX > this.width){
                this._accuX = 0 + this._paddingX;

                this._accuY += this.appsCountHeight + this._paddingY;

                if(this._accuY + this.appsCountHeight + this._paddingY > this.height){
                    Logger.error(`The Apps Count with id: ${instanceId} doesn't fit in the 1024x512 px canvas.`);
                }
            }
        }
    }

    downloadTexture(){
        if(this.ctx){
            // Downloads the offscreen canvas
            this.downloadAsImage('canvas_image.png');
        }
    }

    downloadAsImage(filename: string) {
        // Convert OffscreenCanvas to Blob
        this.canvas.convertToBlob().then(blob => {
            // Create URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create anchor element
            const a = document.createElement('a');
            a.download = filename;
            a.href = url;

            // Click the anchor to initiate download
            document.body.appendChild(a);
            a.click();

            // Clean up
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    }

    dispose(){
        this.texture?.dispose();
    }
}