import * as THREE from 'three';
import { LEGEND_HEIGHT, LEGEND_MAX_UNTRIMMED_CHARS, LEGEND_WIDTH } from '../../common/constants';
import { Logger } from '../../../utils/Logger';
import { CosmosThree } from '../../CosmosThree';

export class LegendsTextureGenerator{

    canvas: OffscreenCanvas;
    ctx: OffscreenCanvasRenderingContext2D | null;

    width = 8192;
    height = 8192;

    // Evrything will be double size to upscale the generated bitmap and avoid blurring of text as much as possible.
    legendWidth = LEGEND_WIDTH * 2;
    legendHeight = LEGEND_HEIGHT * 2;

    private _paddingX = 2;
    private _paddingY = 2;

    private _accuX = 0 + this._paddingX;
    private _accuY = 0 + this._paddingY;

    legendsPositions: Map<string, {x: number, y: number}> = new Map();

    texture: THREE.CanvasTexture;

    constructor (){
        this.canvas = new OffscreenCanvas(this.width, this.height);

        this.ctx = this.canvas.getContext("2d") as OffscreenCanvasRenderingContext2D;

        this.ctx.font = 'normal 24px "Inter var", sans';
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.direction = "inherit";

        this.texture = new THREE.CanvasTexture(this.canvas);
        this.texture.colorSpace = THREE.SRGBColorSpace;
        this.texture.generateMipmaps = false;
        this.texture.anisotropy = CosmosThree.globalAnisotropy;

        //this.texture.minFilter = THREE.NearestMipmapNearestFilter;
        //this.texture.magFilter = THREE.NearestFilter;
    }

    createLegendAt(instanceId: number, title: string){
        if(this.ctx){
            this.ctx.save();

            this.ctx.translate(this._accuX, this._accuY);

            /* this.ctx.fillStyle = "#0f0";
            this.ctx.fillRect(0, 0, this.legendWidth, this.legendHeight); */

            /* // Debug red border
            this.ctx.fillStyle = "#f00";
            this.ctx.fillRect(0, 0, this.legendWidth, 2);
            this.ctx.fillRect(this.legendWidth - 2, 0, 2, this.legendHeight -2);
            this.ctx.fillRect(0, 0, 2, this.legendHeight -2);
            this.ctx.fillRect(0, this.legendHeight - 2, this.legendWidth , 2); */

            let ellipsis = "";
            if (title.length > LEGEND_MAX_UNTRIMMED_CHARS){
                ellipsis = "...";
            }else{
                ellipsis = "";
            }
            title = title.substring(0, LEGEND_MAX_UNTRIMMED_CHARS) + ellipsis;

            this.ctx.shadowColor="rgba(68, 68, 68, 0.75)";
            this.ctx.shadowBlur = 10;

            this.ctx.strokeStyle = "rgba(68, 68, 68, 0.75)";
            this.ctx.lineWidth = 4;
            this.ctx.strokeText(title, this.legendWidth / 2, this.legendHeight / 2);

            this.ctx.fillStyle = "#fff";
            this.ctx.fillText(title, this.legendWidth / 2, this.legendHeight / 2);

            this.ctx.restore();

            this.legendsPositions.set(instanceId+"", {x: this._accuX, y: this._accuY});

            this._accuX += this.legendWidth + this._paddingX;

            if(this._accuX + this.legendWidth + this._paddingX > this.width){
                this._accuX = 0 + this._paddingX;

                this._accuY += this.legendHeight + this._paddingY;

                if(this._accuY + this.legendHeight + this._paddingY > this.height){
                    Logger.error(`The Legend with id: ${instanceId} doesn't fit in the 8192x8192 px canvas.`);
                }
            }
        }
    }

    downloadTexture(){
        if(this.ctx){
            // Downloads the offscreen canvas
            this.downloadAsImage('canvas_image.png');
        }
    }

    downloadAsImage(filename: string) {
        // Convert OffscreenCanvas to Blob
        this.canvas.convertToBlob().then(blob => {
            // Create URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create anchor element
            const a = document.createElement('a');
            a.download = filename;
            a.href = url;

            // Click the anchor to initiate download
            document.body.appendChild(a);
            a.click();

            // Clean up
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    }

    dispose(){
        this.texture?.dispose();
    }
}